import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Headphones, Play, Heart, MoreHorizontal } from "react-feather";

const Content = styled.div`
`;

const Maintenance = () => {
  return (
      <Content className="page-content">
        <div className="maintenance_container">
          <div className="box">
            <div className="animation">
              <div className="one spin-one"></div>
              <div className="two spin-two"></div>
              <div className="three spin-one"></div>
            </div>
            <h1>Under maintenance</h1>
            <p>We will be back on 29 Apr 07:00 UTC</p>
          </div>
        </div>
      </Content>
  );
};

export default Maintenance;
