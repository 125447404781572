import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  AlertTriangle,
  ChevronsUp,
  Check as CheckIcon,
  CheckCircle,
  X as XMarkIcon,
} from "react-feather";
import Web3 from "web3";

import Layout from "../../../layout/dashboard";
import Section from "../../../layout/global/Section";
import Container from "../../../layout/global/Container";

import { Breadcrumbs, Button, Card } from "../../../components";
import { sendEvent } from "../../../components/Analytics";

import { formatUsdPrice, formatBigNumber } from "../../../utils/currency";
import { calculateGasMargin } from "../../../utils/calculateGasMargin";

import { useToggleWalletModal } from "../../../state/application/hooks";
import { useTransactionAdder } from "../../../state/transactions/hooks";
import { TransactionType } from "../../../state/transactions/types";
import { useCustomWeb3React } from "../../../providers/CustomWeb3ReactProvider";

import useSelectChain from "../../../hooks/useSelectChain";
import useTransactionDeadline from "../../../hooks/useTransactionDeadline";
import { usePayaiPresaleContract } from "../../../hooks/useContract";

import { SupportedChainId } from "../../../constants/chains";
import { PAYAI_PRESALE_ADDRESS } from "../../../constants/addresses";
import { getChainInfo } from "../../../constants/chainInfo";
import { fetchEthPrice } from "../../../lib/blockchain";

import { ReactComponent as BaseLogo } from "../../../assets/svg/base-logo.svg";
import PresaleBanner from "../../../assets/images/presale-banner.jpg";
import PresaleBannerPortrait from "../../../assets/images/presale-banner-portrait.jpg";

// RotatingText Component
const RotatingText = ({ messages, interval = 2000 }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, interval);

    return () => clearInterval(intervalId);
  }, [messages, interval]);

  return <div className="mt-4 text-center">{messages[index]}</div>;
};

// LoadingSpinner Component
const LoadingSpinner = () => (
  <div className="flex flex-col items-center justify-center h-[200px]">
    <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
    <RotatingText
      messages={["Loading..."]}
      interval={2000} // Adjust the interval as needed
    />
  </div>
);

function formatPresaleTime(unixTimestamp) {
  const date = new Date(unixTimestamp * 1000); // Convert to milliseconds

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");

  return `${year}.${month}.${day} ${hours}:${minutes} (UTC)`;
}

function Home() {
  const selectChain = useSelectChain();
  const { account, chainId, provider } = useCustomWeb3React();
  const toggleWalletModal = useToggleWalletModal();

  const inputRef = useRef(null);

  const presaleContract = usePayaiPresaleContract();
  const [userAddress, setUserAddress] = useState("");
  const [contribution, setContribution] = useState("0");
  const [claimedTokens, setClaimedTokens] = useState(0);
  const [tokenAmount, setTokenAmount] = useState(0);
  const [hasClaimed, setHasClaimed] = useState(false);
  const [timeLeft, setTimeLeft] = useState({});
  const [status, setStatus] = useState(null);
  const [presaleStartTime, setPresaleStartTime] = useState(null);
  const [presaleEndTime, setPresaleEndTime] = useState(null);
  const [totalRaised, setTotalRaised] = useState(null);
  const [totalRaisedUsdAmount, setTotalRaisedUsdAmount] = useState("0.00");
  const [percentageRaised, setPercentageRaised] = useState("0.00");
  const [totalPresaleToken, setTotalPresaleToken] = useState(null);
  const [totalLiquidityToken, setTotalLiquidityToken] = useState(null);
  const [softCap, setSoftCap] = useState(null);
  const [softCapUsdAmount, setSoftCapUsdAmount] = useState("0.00");
  const [minBuy, setMinBuy] = useState(null);
  const [maxBuy, setMaxBuy] = useState(null);
  const [ethPrice, setEthPrice] = useState(null);
  const [percentToLP, setPercentToLP] = useState(60);
  const [currentRate, setCurrentRate] = useState(null);
  const [currentTokenRate, setCurrentTokenRate] = useState(null);
  const [dexRate, setDexRate] = useState(null);
  const [dexTokenRate, setDexTokenRate] = useState(null);
  const [uniqueContributors, setUniqueContributors] = useState(null);
  const [appreciatePercentage, setAppreciatePercentage] = useState(null);

  const [amountWarning, setAmountWarning] = useState("");

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [contributionLoading, setContributionLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [attemptingTxn, setAttemptingTxn] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const addTransaction = useTransactionAdder();
  const [txnHash, setTxnHash] = useState();
  const deadline = useTransactionDeadline(); // custom from users settings
  const [sufficientFund, setSufficientFund] = useState(true);

  const chainInfo = chainId ? getChainInfo(chainId) : undefined;

  const BN_ETH_0_2 = Web3.utils.toBN(Web3.utils.toWei("0.2", "ether"));
  const BN_ETH_0_5 = Web3.utils.toBN(Web3.utils.toWei("0.5", "ether"));
  const BN_ETH_1_0 = Web3.utils.toBN(Web3.utils.toWei("1", "ether"));
  const BN_ETH_2_0 = Web3.utils.toBN(Web3.utils.toWei("2", "ether"));
  const contributionBN = Web3.utils.toBN(contribution); // contribution "205000000000000000"

  /*
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  function calculateTimeLeft() {
    const difference = +new Date("2024-05-26T00:35:14") - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }

  const renderTimer = () => {
    return (
      <>
        {timeLeft.hours < 10 ? `0${timeLeft.hours}` : timeLeft.hours}:
        {timeLeft.minutes < 10 ? `0${timeLeft.minutes}` : timeLeft.minutes}:
        {timeLeft.seconds < 10 ? `0${timeLeft.seconds}` : timeLeft.seconds}
      </>
    );
  };
  */
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  function calculateTimeLeft() {
    if (!presaleStartTime || !presaleEndTime || !status)
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };

    //enum PoolStatus { UPCOMING, ONGOING, ENDED, FINALIZED, CANCELED}
    let targetTime = status === "0" ? presaleStartTime : presaleEndTime;
    const difference = +new Date(targetTime * 1000) - +new Date(); // Convert to milliseconds
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }

  const renderTimer = () => {
    if (
      timeLeft.days === undefined &&
      timeLeft.hours === undefined &&
      timeLeft.minutes === undefined &&
      timeLeft.seconds === undefined
    ) {
      return <div>Loading...</div>;
    }

    return (
      <>
        {timeLeft.days > 0
          ? timeLeft.days < 10
            ? `0${timeLeft.days}:`
            : `${timeLeft.days}:`
          : ""}
        {timeLeft.hours < 10 ? `0${timeLeft.hours}` : timeLeft.hours}:
        {timeLeft.minutes < 10 ? `0${timeLeft.minutes}` : timeLeft.minutes}:
        {timeLeft.seconds < 10 ? `0${timeLeft.seconds}` : timeLeft.seconds}
      </>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch("https://api.paybolt.com/presale/status", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        // console.log("data.status", data.status)

        setStatus(data.status);
        setPresaleStartTime(data.presale_start_time);
        setPresaleEndTime(data.presale_end_time);
        setTotalRaised(data.total_raised); // "1000000000000000000"); //
        setTotalPresaleToken(data.total_token_for_presale);
        setTotalLiquidityToken(data.total_token_for_liquidity);
        setSoftCap(data.soft_cap);
        setMinBuy(data.min_buy);
        setMaxBuy(data.max_buy);
        setUniqueContributors(data.unique_contributors);
        setPercentToLP(data.percent_to_lp);

        const price = await fetchEthPrice();
        setEthPrice(price);
      } catch (error) {
        console.log(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      //setLoading(true);
      setError("");
      try {
        console.log("Presale Event")
        const [
          _userContribution,
        ] = await Promise.all([
          presaleContract.getContribution(account),
        ]);

        setContribution(_userContribution.toString());
      } catch (error) {
        console.log(error);
        setError(error);
      } finally {
        //setLoading(false);
      }
    };


    if (presaleContract && account && chainId == SupportedChainId.BASE) {
      //console.log(presaleContract)
      fetchData();
    }

  }, [presaleContract, account, chainId]);

  useEffect(() => {
    if (ethPrice && softCap && totalPresaleToken && totalLiquidityToken) {
      const _softCap = parseFloat(Web3.utils.fromWei(softCap, "ether"));
      const _totalRaised = parseFloat(Web3.utils.fromWei(totalRaised, "ether"));
      const _totalTokenForPresale = parseFloat(
        Web3.utils.fromWei(totalPresaleToken, "ether"),
      );
      const _totalLiquidityToken = parseFloat(
        Web3.utils.fromWei(totalLiquidityToken, "ether"),
      );

      const percentageRaised = (_totalRaised / _softCap) * 100;
      setPercentageRaised(percentageRaised.toFixed(2));

      const __totalRaised = Web3.utils.fromWei(totalRaised, "ether");
      const __totalRaisedUsd = (__totalRaised * ethPrice).toFixed(2);
      setTotalRaisedUsdAmount(__totalRaisedUsd);

      const softCapEth = Web3.utils.fromWei(softCap, "ether");
      const softCapUsd = (softCapEth * ethPrice).toFixed(2);
      setSoftCapUsdAmount(softCapUsd);

      // Calculate the current rate
      // the rate will only be effective when soft cap is met
      const _totalRaisedForCalc = Math.max(_totalRaised, _softCap);
      const _currentRate = (
        (_totalRaisedForCalc * ethPrice) /
        _totalTokenForPresale
      ).toFixed(6);
      setCurrentRate(_currentRate);

      // calc current token rate
      if (_totalRaisedForCalc > 0) {
        const _currentTokenRate = (
          _totalTokenForPresale / _totalRaisedForCalc
        ).toFixed(0);
        setCurrentTokenRate(_currentTokenRate);
      }

      const LPFromPAY = ethPrice * 80000 / 3780;
      // Calculate the dex rate
      // the rate will only be effective when soft cap is met
      const _dexRate = (
        ((_totalRaisedForCalc * ethPrice * percentToLP) / 100 + LPFromPAY) /
        _totalLiquidityToken
      ).toFixed(6);
      setDexRate(_dexRate);

      // calc dex token rate
      if (_totalLiquidityToken > 0) {
        // Calculate the amount of ETH in liquidity pool with additional LPFromPAY USD value
        const liquidityValueInEth =
          (_totalRaisedForCalc * percentToLP) / 100 + LPFromPAY / ethPrice;
        const _dexTokenRate = (
          _totalLiquidityToken / liquidityValueInEth
        ).toFixed(0);
        setDexTokenRate(_dexTokenRate);
      }

      if (_dexRate > 0 && _currentRate > 0) {
        const _percent = ((_dexRate / _currentRate) * 100).toFixed(0) - 100;
        setAppreciatePercentage(_percent);
      }
    }
  }, [
    totalRaised,
    ethPrice,
    softCap,
    totalPresaleToken,
    totalLiquidityToken,
    percentToLP,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const balance = await provider.getBalance(account);
        //console.log("ETH balance:", balance.toString());
        //console.log("ETH balance:", formatBigNumber(balance, 0));
        //console.log("total      :", formatBigNumber(Web3.utils.toWei(amount.toString(), "ether"), 0));
        //console.log("balance >= amount ? ", balance.gte(Web3.utils.toWei(amount.toString(), "ether")));

        const balanceinBN = Web3.utils.toBN(balance);
        const amountInBN = Web3.utils.toBN(Web3.utils.toWei(amount, "ether"));

        if (balanceinBN.lt(amountInBN)) {
          setAmountWarning("Insufficient fund");
        }

        setSufficientFund(balanceinBN.gte(amountInBN));
      } catch (error) {
        //console.log(error)
        setError(error);
      }
    };

    fetchData();
  }, [account, amount]);

  const handleContribute = async () => {
    if (!presaleContract || !account) return;

    setError(""); // Reset error message
    try {
      const amount = 1; // Example amount in ETH
      const receipt = await presaleContract.methods.contribute().send({
        from: account,
        value: Web3.utils.toWei(amount.toString(), "ether"),
      });
      //console.log("Contribution Transaction:", receipt);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleClaim = async () => {
    if (!presaleContract || !account) return;

    setError(""); // Reset error message
    try {
      const receipt = await presaleContract.methods
        .claim()
        .send({ from: account });
      //console.log("Claim Transaction:", receipt);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleAmountChange = (e) => {
    const { value } = e.target;

    setAmountWarning("");

    // Regular expression to match valid decimal numbers
    const regex = /^\d*\.?\d*$/;

    // Check if the input matches the regex and parse it as a float
    if (regex.test(value)) {
      const numericValue = parseFloat(value);

      // Ensure the value is not NaN and is within the valid range
      if (!isNaN(numericValue)) {
        if (
          numericValue >= 0 &&
          (isNaN(maxBuy) || numericValue <= Web3.utils.fromWei(maxBuy, "ether"))
        ) {
          setAmount(value);
        } else if (numericValue > Web3.utils.fromWei(maxBuy, "ether")) {
          setAmount(Web3.utils.fromWei(maxBuy, "ether"));
        }
      } else {
        setAmount("");
      }
    }
  };

  const setMaxAmount = () => {
    // Assuming 100 as the max amount for demonstration. You can replace it with your logic.
    //setAmount(Web3.utils.fromWei(maxBuy, "ether"));

    if (maxBuy) {
      setAmountWarning("");

      const maxBuyInEther = Web3.utils.fromWei(maxBuy, "ether");

      if (contribution) {
        const contributedInEther = Web3.utils.fromWei(contribution, "ether");

        const maxBuyBN = Web3.utils.toBN(
          Web3.utils.toWei(maxBuyInEther, "ether"),
        );
        const contributedBN = Web3.utils.toBN(
          Web3.utils.toWei(contributedInEther, "ether"),
        );

        const remainingAmountBN = maxBuyBN.sub(contributedBN);
        const remainingAmountInEther = Web3.utils.fromWei(
          remainingAmountBN,
          "ether",
        );

        setAmount(remainingAmountInEther);
      } else {
        setAmount(maxBuyInEther);
      }
    }

    inputRef.current.focus();
  };

  const buyTierAmount = (targetBuy) => {
    setAmountWarning("");

    const targetBuyInEther = Web3.utils.fromWei(targetBuy, "ether");

    if (contribution) {
      const contributedInEther = Web3.utils.fromWei(contribution, "ether");

      const targetBuyBN = Web3.utils.toBN(
        Web3.utils.toWei(targetBuyInEther, "ether"),
      );
      const contributedBN = Web3.utils.toBN(
        Web3.utils.toWei(contributedInEther, "ether"),
      );

      const remainingAmountBN = targetBuyBN.sub(contributedBN);
      const remainingAmountInEther = Web3.utils.fromWei(
        remainingAmountBN,
        "ether",
      );

      //console.log("targetBuyInEther", targetBuyInEther);
      //console.log("contributedInEther", contributedInEther);
      //console.log("remainingAmount", remainingAmountInEther);

      setAmount(remainingAmountInEther);
    } else {
      setAmount(targetBuyInEther);
    }

    inputRef.current.focus();
  };

  const contributeNow = useCallback(async () => {
    setAttemptingTxn(true);
    if (
      !deadline ||
      !account ||
      !chainId ||
      !provider ||
      amount <= 0 ||
      chainId != SupportedChainId.BASE
    ) {
      //console.log("Unsupported Network", chainId, deadline, account, provider, amount)
      setAmountWarning("Please check your amount and network");
      return;
    }

    const amountInBN = Web3.utils.toBN(Web3.utils.toWei(amount, "ether"));
    const contributedInBN = Web3.utils.toBN(contribution);
    const newTotal = amountInBN.add(contributedInBN);
    const minBuyInBN = Web3.utils.toBN(minBuy);
    const maxBuyInBN = Web3.utils.toBN(maxBuy);

    if (newTotal.lt(minBuyInBN)) {
      setAmountWarning("Minimum buy not met");
      return;
    }
    if (newTotal.gt(maxBuyInBN)) {
      setAmountWarning("Maximum buy exceeded");
      return;
    }

    const balance = await provider.getBalance(account);
    const balanceinBN = Web3.utils.toBN(balance);
    if (balanceinBN.lt(amountInBN)) {
      setAmountWarning("Insufficient fund");
      return;
    }

    setContributionLoading(true);

    const amountInWei = Web3.utils.toWei(amount, "ether");
    const calldata = presaleContract.interface.encodeFunctionData("contribute");

    const txn = {
      to: PAYAI_PRESALE_ADDRESS,
      data: calldata,
      value: amountInWei,
    };

    try {
      // log initiating minting
      sendEvent({
        category: "Presale",
        action: "contributing",
        label: amountInWei,
      });

      const estimate = await provider.getSigner().estimateGas(txn);
      const newTxn = {
        ...txn,
        gasLimit: calculateGasMargin(estimate),
      };

      const response = await provider.getSigner().sendTransaction(newTxn);
      setTxnHash(response.hash);
      setAttemptingTxn(false);
      addTransaction(response, {
        type: TransactionType.PRESALE_CONTRIBUTE,
        totalAmount: formatBigNumber(amountInWei),
        symbol: chainInfo?.nativeCurrency?.symbol,
        network: chainInfo?.label,
      });

      setModalIsOpen(true);
    } catch (error) {
      setAttemptingTxn(false);
      //console.error(error);
    } finally {
      setContributionLoading(false);
    }
  }, [
    deadline,
    account,
    chainId,
    provider,
    contribution,
    minBuy,
    maxBuy,
    amount,
    addTransaction,
  ]);

  const handleShareClick = () => {
    const url = account
      ? encodeURIComponent(`https://paybolt.com/presale?ref=${account}`)
      : encodeURIComponent("https://paybolt.com/presale");
    const text = encodeURIComponent(
      "Checkout PayBolt AI Presale on Base Chain from 7-9 June 2024. #PayBoltAI",
    );
    const twitterShareUrl = `https://x.com/intent/post?url=${url}&text=${text}&related=PayBoltAI`;
    window.open(twitterShareUrl, "_blank", "width=560,height=400");
  };

  //console.log("contributionBN", contributionBN.toString())
  return (
    <Layout title="Don't Miss Out! PayBolt AI $PAYAI Presale">
      <Section className="py-10 px-3">
        <Container>
          <div className="mb-7 flex justify-between items-center -mx-3">
            <div className="px-3">
              <h1 className="text-2xl font-bold text-slate-700 dark:text-white mb-2">
                PayBolt AI Membership
              </h1>
              <Breadcrumbs
                items={[
                  { text: "Home", link: "/app" },
                  { text: "Membership", link: "" },
                ]}
              />
            </div>
            <div className="px-3"></div>
          </div>
          <h3>Connect your Base wallet to view your membership.</h3>

          <div className="flex flex-wrap -m-3">
            <div className="w-full p-3">
              <div className="bg-white dark:bg-slate-900 p-6 rounded-lg shadow-lg">
                <h2 className="text-2xl font-bold text-center dark:text-white mb-4">
                  Membership Tiers & Benefits
                </h2>
                <div className="space-y-6">
                  <div className="flex flex-wrap justify-center -m-3">
                    <div className="w-full xs:w-4/5 sm:w-1/2 xl:w-1/4 p-3">
                      <Card className="p-5 h-full flex flex-col justify-between">
                        <div>
                          <h2 className="text-xl font-bold text-blue-700 dark:text-blue-500">
                            Essential
                          </h2>
                          <p className="flex flex-col items-baseline gap-x-2 mt-2">
                            <span className="text-xl font-bold tracking-tight text-slate-700 dark:text-white">
                              10,000,000 <span className="text-slate-500 dark:text-slate-400">PAYAI</span>
                            </span>
                            <span className="text-sm font-semibold leading-6 tracking-wide text-slate-500 dark:text-slate-400">
                              min. staked
                            </span>
                          </p>
                          <ul className="mt-4 text-slate-500 dark:text-slate-400 text-sm font-medium flex flex-col gap-y-3">
                            <li className="flex gap-x-3">
                              <span className="bg-emerald-100 dark:bg-emerald-950 p-1 h-5 w-5 flex-shrink-0 rounded-full flex items-center justify-center">
                                <CheckIcon className="h-3 text-emerald-600" />
                              </span>
                              <span className="text-bold text-blue-700 dark:text-blue-500">APR 10%</span>
                            </li>
                            <li className="flex gap-x-3">
                              <span className="bg-emerald-100 dark:bg-emerald-950 p-1 h-5 w-5 flex-shrink-0 rounded-full flex items-center justify-center">
                              </span>
                              <span>3 months</span>
                            </li>
                            <li className="flex gap-x-3">
                              <span className="bg-emerald-100 dark:bg-emerald-950 p-1 h-5 w-5 flex-shrink-0 rounded-full flex items-center justify-center">
                                <CheckIcon className="h-3 text-emerald-600" />
                              </span>
                              <span>Core Features</span>
                            </li>
                            <li className="flex gap-x-3">
                              <span className="bg-emerald-100 dark:bg-emerald-950 p-1 h-5 w-5 flex-shrink-0 rounded-full flex items-center justify-center">
                                <CheckIcon className="h-3 text-emerald-600" />
                              </span>
                              <span>Community Events</span>
                            </li>
                            <li className="flex gap-x-3">
                              <span className="bg-slate-200 dark:bg-slate-800 p-1 h-5 w-5 flex-shrink-0 rounded-full flex items-center justify-center">
                                <XMarkIcon className="h-3 text-slate-600 dark:text-slate-200" />
                              </span>
                              <span>Early Access</span>
                            </li>
                            <li className="flex gap-x-3">
                              <span className="bg-slate-200 dark:bg-slate-800 p-1 h-5 w-5 flex-shrink-0 rounded-full flex items-center justify-center">
                                <XMarkIcon className="h-3 text-slate-600 dark:text-slate-200" />
                              </span>
                              <span>Customer Support</span>
                            </li>
                          </ul>
                        </div>
                        <div className="mt-6 flex justify-center">
                          {contributionBN.gte(BN_ETH_0_2) &&
                            contributionBN.lt(BN_ETH_0_5) && (
                              <div className="current-tier-div flex flex-col items-center rounded-lg transform transition-all duration-500 hover:scale-105">
                                <div className="flex justify-center">
                                  <CheckCircle className="text-blue-700 dark:text-blue-500 w-16 h-16 pr-4" />
                                  <h2 className="text-2xl font-extrabold text-gray-800 dark:text-gray-100 mt-4">
                                    Current Tier
                                  </h2>
                                </div>
                                <p className="text-gray-700 dark:text-gray-300 mt-2 text-md font-medium text-center">
                                  You are currently in the exclusive PayBolt AI{" "}
                                  <span className="text-blue-700 dark:text-blue-500 font-bold">
                                    Essential
                                  </span>{" "}
                                  membership tier.
                                </p>
                              </div>
                            )}
                          {contributionBN.lt(BN_ETH_0_2) && (
                            <button
                              onClick={() => buyTierAmount(BN_ETH_0_2)}
                              className="inline-flex font-medium text-sm bg-blue-700 dark:bg-blue-500 text-white hover:bg-blue-800 transition-all px-6 py-3 rounded-lg shadow-lg transform transition-all duration-500 hover:scale-105"
                            >
                              <span className="relative flex items-center">
                                <span className="absolute inset-0 bg-gradient-to-r from-blue-500 to-indigo-500 opacity-50 rounded-lg blur-md"></span>
                                <span className="relative z-10">Coming Soon</span>
                              </span>
                            </button>
                          )}
                        </div>
                      </Card>
                    </div>
                    <div className="w-full xs:w-4/5 sm:w-1/2 xl:w-1/4 p-3">
                      <Card className="p-5 h-full flex flex-col justify-between">
                        {/*<h2 className="text-xl font-bold w-max bg-gradient-to-r from-blue-600 to-pink-500 text-transparent bg-clip-text">
                                    Premier
                                </h2>*/}
                        <div>
                          <h2 className="text-xl font-bold text-red-600 dark:text-red-400">
                            Premier
                          </h2>
                          <p className="flex flex-col items-baseline gap-x-2 mt-2">
                            <span className="text-xl font-bold tracking-tight text-slate-700 dark:text-white">
                              25,000,000 <span className="text-slate-500 dark:text-slate-400">PAYAI</span>
                            </span>
                            <span className="text-sm font-semibold leading-6 tracking-wide text-slate-500 dark:text-slate-400">
                              min. staked
                            </span>
                          </p>
                          <ul className="mt-4 text-slate-500 dark:text-slate-400 text-sm font-medium flex flex-col gap-y-3">
                            <li className="flex gap-x-3">
                              <span className="bg-emerald-100 dark:bg-emerald-950 p-1 h-5 w-5 flex-shrink-0 rounded-full flex items-center justify-center">
                                <CheckIcon className="h-3 text-emerald-600" />
                              </span>
                              <span className="text-bold text-red-600 dark:text-red-400">APR 12%</span>
                            </li>
                            <li className="flex gap-x-3">
                              <span className="bg-emerald-100 dark:bg-emerald-950 p-1 h-5 w-5 flex-shrink-0 rounded-full flex items-center justify-center">
                              </span>
                              <span>3 months</span>
                            </li>
                            <li className="flex gap-x-3">
                              <span className="bg-emerald-100 dark:bg-emerald-950 p-1 h-5 w-5 flex-shrink-0 rounded-full flex items-center justify-center">
                                <CheckIcon className="h-3 text-emerald-600" />
                              </span>
                              <span>Early Access</span>
                            </li>
                            <li className="flex gap-x-3">
                              <span className="bg-emerald-100 dark:bg-emerald-950 p-1 h-5 w-5 flex-shrink-0 rounded-full flex items-center justify-center">
                                <CheckIcon className="h-3 text-emerald-600" />
                              </span>
                              <span>Priority Support</span>
                            </li>
                            <li className="flex gap-x-3">
                              <span className="bg-emerald-100 dark:bg-emerald-950 p-1 h-5 w-5 flex-shrink-0 rounded-full flex items-center justify-center">
                                <CheckIcon className="h-3 text-emerald-600" />
                              </span>
                              <span>Premier Events</span>
                            </li>
                            <li className="flex gap-x-3">
                              <span className="bg-emerald-100 dark:bg-emerald-950 p-1 h-5 w-5 flex-shrink-0 rounded-full flex items-center justify-center">
                                <CheckIcon className="h-3 text-emerald-600" />
                              </span>
                              <span>
                                <strong className="text-slate-600 dark:text-slate-200">
                                  0.1%
                                </strong>
                                &nbsp;Tax Bonus
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="mt-6 flex justify-center">
                          {contributionBN.gte(BN_ETH_0_5) &&
                            contributionBN.lt(BN_ETH_1_0) && (
                              <div className="current-tier-div flex flex-col items-center rounded-lg transform transition-all duration-500 hover:scale-105">
                                <div className="flex justify-center">
                                  <CheckCircle className="text-red-600 dark:text-red-400 w-16 h-16 pr-4" />
                                  <h2 className="text-2xl font-extrabold text-gray-800 dark:text-gray-100 mt-4">
                                    Current Tier
                                  </h2>
                                </div>
                                <p className="text-gray-700 dark:text-gray-300 mt-2 text-md font-medium text-center">
                                  You are currently in the exclusive PayBolt AI{" "}
                                  <span className="text-red-600 dark:text-red-400 font-bold">
                                    Premier
                                  </span>{" "}
                                  membership tier.
                                </p>
                              </div>
                            )}
                          {contributionBN.lt(BN_ETH_0_5) && (
                            <button
                              //onClick={() => buyTierAmount(BN_ETH_0_5)}
                              className="inline-flex font-medium text-sm bg-red-600 dark:bg-red-400 text-white hover:bg-red-800 transition-all px-6 py-3 rounded-lg shadow-lg transform transition-all duration-500 hover:scale-105"
                            >
                              <span className="relative flex items-center">
                                <span className="absolute inset-0 bg-gradient-to-r from-blue-500 to-indigo-500 opacity-50 rounded-lg blur-md"></span>
                                <span className="relative z-10">Coming Soon</span>
                              </span>
                            </button>
                          )}
                        </div>
                      </Card>
                    </div>
                    <div className="w-full xs:w-4/5 sm:w-1/2 xl:w-1/4 p-3">
                      <Card className="p-5 h-full flex flex-col justify-between">
                        <div>
                          <h2 className="text-xl font-bold text-yellow-500 dark:text-yellow-300">
                            Elite
                          </h2>
                          <p className="flex flex-col items-baseline gap-x-2 mt-2">
                            <span className="text-xl font-bold tracking-tight text-slate-700 dark:text-white">
                              50,000,000 <span className="text-slate-500 dark:text-slate-400">PAYAI</span>
                            </span>
                            <span className="text-sm font-semibold leading-6 tracking-wide text-slate-500 dark:text-slate-400">
                              min. staked
                            </span>
                          </p>
                          <ul className="mt-4 text-slate-500 dark:text-slate-400 text-sm font-medium flex flex-col gap-y-3">
                            <li className="flex gap-x-3">
                              <span className="bg-emerald-100 dark:bg-emerald-950 p-1 h-5 w-5 flex-shrink-0 rounded-full flex items-center justify-center">
                                <CheckIcon className="h-3 text-emerald-600" />
                              </span>
                              <span className="text-bold text-yellow-500 dark:text-yellow-300">APR 16%</span>
                            </li>
                            <li className="flex gap-x-3">
                              <span className="bg-emerald-100 dark:bg-emerald-950 p-1 h-5 w-5 flex-shrink-0 rounded-full flex items-center justify-center">
                              </span>
                              <span>3 months</span>
                            </li>
                            <li className="flex gap-x-3">
                              <span className="bg-emerald-100 dark:bg-emerald-950 p-1 h-5 w-5 flex-shrink-0 rounded-full flex items-center justify-center">
                                <CheckIcon className="h-3 text-emerald-600" />
                              </span>
                              <span>Early Access</span>
                            </li>
                            <li className="flex gap-x-3">
                              <span className="bg-emerald-100 dark:bg-emerald-950 p-1 h-5 w-5 flex-shrink-0 rounded-full flex items-center justify-center">
                                <CheckIcon className="h-3 text-emerald-600" />
                              </span>
                              <span>Priority Support</span>
                            </li>
                            <li className="flex gap-x-3">
                              <span className="bg-emerald-100 dark:bg-emerald-950 p-1 h-5 w-5 flex-shrink-0 rounded-full flex items-center justify-center">
                                <CheckIcon className="h-3 text-emerald-600" />
                              </span>
                              <span>Elite Events</span>
                            </li>
                            <li className="flex gap-x-3">
                              <span className="bg-emerald-100 dark:bg-emerald-950 p-1 h-5 w-5 flex-shrink-0 rounded-full flex items-center justify-center">
                                <CheckIcon className="h-3 text-emerald-600" />
                              </span>
                              <span>
                                <strong className="text-slate-600 dark:text-slate-200">
                                  0.3%
                                </strong>
                                &nbsp;Tax Bonus
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="mt-6 flex justify-center">
                          {contributionBN.gte(BN_ETH_1_0) &&
                            contributionBN.lt(BN_ETH_2_0) && (
                              <div className="current-tier-div flex flex-col items-center rounded-lg transform transition-all duration-500 hover:scale-105">
                                <div className="flex justify-center">
                                  <CheckCircle className="text-yellow-500 dark:text-yellow-300 w-16 h-16 pr-4" />
                                  <h2 className="text-2xl font-extrabold text-gray-800 dark:text-gray-100 mt-4">
                                    Current Tier
                                  </h2>
                                </div>
                                <p className="text-gray-700 dark:text-gray-300 mt-2 text-md font-medium text-center">
                                  You are currently in the exclusive PayBolt AI{" "}
                                  <span className="text-yellow-500 dark:text-yellow-300 font-bold">
                                    Elite
                                  </span>{" "}
                                  membership tier.
                                </p>
                              </div>
                            )}
                          {contributionBN.lt(BN_ETH_1_0) && (
                            <button
                              //onClick={() => buyTierAmount(BN_ETH_1_0)}
                              className="inline-flex font-medium text-sm bg-yellow-500 dark:bg-yellow-300 text-white hover:bg-yellow-800 transition-all px-6 py-3 rounded-lg shadow-lg transform transition-all duration-500 hover:scale-105"
                            >
                              <span className="relative flex items-center">
                                <span className="absolute inset-0 bg-gradient-to-r from-blue-500 to-indigo-500 opacity-50 rounded-lg blur-md"></span>
                                <span className="relative z-10">Coming Soon</span>
                              </span>
                            </button>
                          )}
                        </div>
                      </Card>
                    </div>
                    <div className="w-full xs:w-4/5 sm:w-1/2 xl:w-1/4 p-3">
                      <Card className="p-5 h-full flex flex-col justify-between">
                        <div>
                          <div className="relative inline-block">
                            <h2 className="text-xl font-bold metallic-text">
                              Platinum
                            </h2>
                          </div>
                          <p className="flex flex-col items-baseline gap-x-2 mt-2">
                            <span className="text-xl font-bold tracking-tight text-slate-700 dark:text-white">
                              100,000,000 <span className="text-slate-500 dark:text-slate-400">PAYAI</span>
                            </span>
                            <span className="text-sm font-semibold leading-6 tracking-wide text-slate-500 dark:text-slate-400">
                              min. staked
                            </span>
                          </p>
                          <ul className="mt-4 text-slate-500 dark:text-slate-400 text-sm font-medium flex flex-col gap-y-3">
                            <li className="flex gap-x-3">
                              <span className="bg-emerald-100 dark:bg-emerald-950 p-1 h-5 w-5 flex-shrink-0 rounded-full flex items-center justify-center">
                                <CheckIcon className="h-3 text-emerald-600" />
                              </span>
                              <span className="text-bold metallic-text">APR 20%</span>
                            </li>
                            <li className="flex gap-x-3">
                              <span className="bg-emerald-100 dark:bg-emerald-950 p-1 h-5 w-5 flex-shrink-0 rounded-full flex items-center justify-center">
                              </span>
                              <span>3 months</span>
                            </li>
                            <li className="flex gap-x-3">
                              <span className="bg-emerald-100 dark:bg-emerald-950 p-1 h-5 w-5 flex-shrink-0 rounded-full flex items-center justify-center">
                                <CheckIcon className="h-3 text-emerald-600" />
                              </span>
                              <span>Priority Access</span>
                            </li>
                            <li className="flex gap-x-3">
                              <span className="bg-emerald-100 dark:bg-emerald-950 p-1 h-5 w-5 flex-shrink-0 rounded-full flex items-center justify-center">
                                <CheckIcon className="h-3 text-emerald-600" />
                              </span>
                              <span>VIP Support</span>
                            </li>
                            <li className="flex gap-x-3">
                              <span className="bg-emerald-100 dark:bg-emerald-950 p-1 h-5 w-5 flex-shrink-0 rounded-full flex items-center justify-center">
                                <CheckIcon className="h-3 text-emerald-600" />
                              </span>
                              <span>Exclusive Events</span>
                            </li>
                            <li className="flex gap-x-3">
                              <span className="bg-emerald-100 dark:bg-emerald-950 p-1 h-5 w-5 flex-shrink-0 rounded-full flex items-center justify-center">
                                <CheckIcon className="h-3 text-emerald-600" />
                              </span>
                              <span>Private Consultations</span>
                            </li>
                            <li className="flex gap-x-3">
                              <span className="bg-emerald-100 dark:bg-emerald-950 p-1 h-5 w-5 flex-shrink-0 rounded-full flex items-center justify-center">
                                <CheckIcon className="h-3 text-emerald-600" />
                              </span>
                              <span>
                                <strong className="text-slate-600 dark:text-slate-200">
                                  1%
                                </strong>
                                &nbsp;Tax Bonus
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="mt-6 flex justify-center">
                          {contributionBN.gte(BN_ETH_2_0) && (
                            <div className="current-tier-div flex flex-col items-center rounded-lg transform transition-all duration-500 hover:scale-105">
                              <div className="flex justify-center">
                                <CheckCircle className="metallic-text w-16 h-16 pr-4" />
                                <h2 className="text-2xl font-extrabold text-gray-800 dark:text-gray-100 mt-4">
                                  Current Tier
                                </h2>
                              </div>
                              <p className="text-gray-700 dark:text-gray-300 mt-2 text-md font-medium text-center">
                                You are currently in the exclusive PayBolt AI{" "}
                                <span className="font-bold metallic-text">
                                  Platinum
                                </span>{" "}
                                membership tier.
                              </p>
                            </div>
                          )}
                          {contributionBN.lt(BN_ETH_2_0) && (
                            <button
                              //onClick={() => buyTierAmount(BN_ETH_2_0)}
                              className="inline-flex font-medium text-sm metallic-button text-black transition-all px-6 py-3 rounded-lg shadow-lg transform transition-all duration-500 hover:scale-105"
                            >
                              <span className="relative flex items-center">
                                <span className="relative z-10">Coming Soon</span>
                              </span>
                            </button>
                          )}
                        </div>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full p-3">
              <div className="bg-gradient-to-r from-blue-500 to-purple-600 p-6 rounded-lg shadow-lg transition duration-500 hover:shadow-2xl">
                <h2 className="text-2xl font-bold text-center text-white mb-4">
                  The Perks of PayBolt AI Membership
                </h2>
                <div className="flex flex-wrap justify-center">
                  <div className="w-full md:w-1/2 p-4">
                    <div className="p-4 rounded-lg bg-white dark:bg-slate-800 shadow-md hover:bg-blue-100 dark:hover:bg-slate-700 transition duration-300 transform hover:scale-105">
                      <h3 className="text-xl font-semibold dark:text-white">
                        Reflection Rewards:
                      </h3>
                      <br />
                      <ul className="list-inside dark:text-gray-300">
                        <li className="flex gap-x-3">
                          <span className="bg-emerald-100 dark:bg-emerald-950 p-1 h-5 w-5 flex-shrink-0 rounded-full flex items-center justify-center">
                            <CheckIcon className="h-3 text-emerald-600" />
                          </span>
                          For every PAYAI token sell, a 1.4% transaction tax
                          will be incurred and distributed to members:
                        </li>
                        <li className="flex gap-x-3">
                          <span className="bg-emerald-100 dark:bg-emerald-950 p-1 h-5 w-5 flex-shrink-0 rounded-full flex items-center justify-center">
                            <CheckIcon className="h-3 text-emerald-600" />
                          </span>
                          1% shared exclusively by Platinum members.
                        </li>
                        <li className="flex gap-x-3">
                          <span className="bg-emerald-100 dark:bg-emerald-950 p-1 h-5 w-5 flex-shrink-0 rounded-full flex items-center justify-center">
                            <CheckIcon className="h-3 text-emerald-600" />
                          </span>
                          0.4% shared by both Elite and Premier members.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 p-4">
                    <div className="p-4 rounded-lg bg-white dark:bg-slate-800 shadow-md hover:bg-blue-100 dark:hover:bg-slate-700 transition duration-300 transform hover:scale-105">
                      <h3 className="text-xl font-semibold dark:text-white">
                        Additional Perks:
                      </h3>
                      <br />
                      <ul className="list-disc list-inside dark:text-gray-300">
                        <li className="flex gap-x-3">
                          <span className="bg-emerald-100 dark:bg-emerald-950 p-1 h-5 w-5 flex-shrink-0 rounded-full flex items-center justify-center">
                            <CheckIcon className="h-3 text-emerald-600" />
                          </span>
                          Community Voting Rights: Influence the direction of
                          the PayBolt AI ecosystem.
                        </li>
                        <li className="flex gap-x-3">
                          <span className="bg-emerald-100 dark:bg-emerald-950 p-1 h-5 w-5 flex-shrink-0 rounded-full flex items-center justify-center">
                            <CheckIcon className="h-3 text-emerald-600" />
                          </span>
                          Access to Special Events: Participate in members-only
                          webinars, AMA sessions, and meetups.
                        </li>
                        <li className="flex gap-x-3">
                          <span className="bg-emerald-100 dark:bg-emerald-950 p-1 h-5 w-5 flex-shrink-0 rounded-full flex items-center justify-center">
                            <CheckIcon className="h-3 text-emerald-600" />
                          </span>
                          Special Discounts: Enjoy discounts on transaction fees
                          and partner services.
                        </li>
                        <li className="flex gap-x-3">
                          <span className="bg-emerald-100 dark:bg-emerald-950 p-1 h-5 w-5 flex-shrink-0 rounded-full flex items-center justify-center">
                            <CheckIcon className="h-3 text-emerald-600" />
                          </span>
                          Loyalty Rewards: Earn loyalty points that can be
                          redeemed for exclusive benefits.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </Layout>
  );
}

export default Home;
