import { SupportedChainId } from './chains'

const INFURA_KEY = process.env.REACT_APP_INFURA_KEY
if (typeof INFURA_KEY === 'undefined') {
  throw new Error(`REACT_APP_INFURA_KEY must be a defined environment variable`)
}
const ALCHEMY_KEY = process.env.REACT_APP_ALCHEMY_KEY

/**
 * Fallback JSON-RPC endpoints.
 * These are used if the integrator does not provide an endpoint, or if the endpoint does not work.
 *
 * MetaMask allows switching to any URL, but displays a warning if it is not on the "Safe" list:
 * https://github.com/MetaMask/metamask-mobile/blob/bdb7f37c90e4fc923881a07fca38d4e77c73a579/app/core/RPCMethods/wallet_addEthereumChain.js#L228-L235
 * https://chainid.network/chains.json
 *
 * These "Safe" URLs are listed first, followed by other fallback URLs, which are taken from chainlist.org.
 */
export const FALLBACK_URLS = {
  [SupportedChainId.MAINNET]: [
    // "Safe" URLs
    'https://api.mycryptoapi.com/eth',
    'https://cloudflare-eth.com',
    // "Fallback" URLs
    'https://rpc.ankr.com/eth',
    'https://eth-mainnet.public.blastapi.io',
  ],
  [SupportedChainId.GOERLI]: [
    // "Safe" URLs
    'https://rpc.goerli.mudit.blog/',
    // "Fallback" URLs
    'https://rpc.ankr.com/eth_goerli',
  ],
  [SupportedChainId.BNBCHAIN]: [
    // "Safe" URLs
    'https://bsc-dataseed.binance.org',
    'https://bsc-dataseed4.binance.org',
    'https://bsc-dataseed2.ninicoin.io',
    'https://bsc-dataseed1.defibit.io',
    // "Fallback" URLs
    'https://rpc.ankr.com/bsc',
  ],
  [SupportedChainId.POLYGON]: [
    // "Safe" URLs
    'https://polygon-rpc.com/',
    'https://rpc-mainnet.matic.network',
    'https://matic-mainnet.chainstacklabs.com',
    'https://rpc-mainnet.maticvigil.com',
    'https://rpc-mainnet.matic.quiknode.pro',
    'https://matic-mainnet-full-rpc.bwarelabs.com',
  ],
  [SupportedChainId.POLYGON_MUMBAI]: [
    // "Safe" URLs
    'https://matic-mumbai.chainstacklabs.com',
    'https://rpc-mumbai.maticvigil.com',
    'https://matic-testnet-archive-rpc.bwarelabs.com',
  ],
  [SupportedChainId.SEPOLIA]: [
    // "Safe" URLs
    'https://rpc.sepolia.org',
    'https://rpc2.sepolia.org',
    'https://rpc.sepolia.online',
  ],
  [SupportedChainId.ARBITRUM_ONE]: [
    // "Safe" URLs
    'https://arb1.arbitrum.io/rpc',
    // "Fallback" URLs
    'https://arbitrum.public-rpc.com',
  ],
  [SupportedChainId.SOLANA]: [
  ],
  [SupportedChainId.BASE]: [
    // "Safe" URLs
    'https://mainnet.base.org',
    // "Fallback" URLs
    'https://base.drpc.org',
    'https://base-rpc.publicnode.com',
  ],
  [SupportedChainId.AVAX]: [
    // "Safe" URLs
    'https://api.avax.network/ext/bc/C/rpc',
    // "Fallback" URLs
    'https://avalanche.drpc.org',
    'https://avalanche-c-chain-rpc.publicnode.com',
  ],
  /*
  [SupportedChainId.KLAYTN]: [
    // "Safe" URLs
    'https://public-node-api.klaytnapi.com/v1/cypress',
    'https://klaytn.blockpi.network/v1/rpc/public',
    // "Fallback" URLs
    'https://cypress.fautor.app/archive',
  ],
  [SupportedChainId.ARBITRUM_GOERLI]: [
    // "Safe" URLs
    'https://goerli-rollup.arbitrum.io/rpc',
  ],
  [SupportedChainId.OPTIMISM]: [
    // "Safe" URLs
    'https://mainnet.optimism.io/',
    // "Fallback" URLs
    'https://rpc.ankr.com/optimism',
  ],
  [SupportedChainId.OPTIMISM_GOERLI]: [
    // "Safe" URLs
    'https://goerli.optimism.io',
  ],
  [SupportedChainId.CELO]: [
    // "Safe" URLs
    `https://forno.celo.org`,
  ],
  [SupportedChainId.CELO_ALFAJORES]: [
    // "Safe" URLs
    `https://alfajores-forno.celo-testnet.org`,
  ],
  */
}

const BSC_PROVIDER = process.env.REACT_APP_BSC_PROVIDER
if (typeof BSC_PROVIDER === 'undefined') {
  throw new Error(`REACT_APP_BSC_PROVIDER must be a defined environment variable`)
}

const SOLANA_PROVIDER = process.env.REACT_APP_SOLANA_PROVIDER
if (typeof SOLANA_PROVIDER === 'undefined') {
  throw new Error(`REACT_APP_SOLANA_PROVIDER must be a defined environment variable`)
}

const BASE_PROVIDER = process.env.REACT_APP_BASE_PROVIDER
if (typeof BASE_PROVIDER === 'undefined') {
  throw new Error(`REACT_APP_BASE_PROVIDER must be a defined environment variable`)
}

const AVAX_PROVIDER = process.env.REACT_APP_AVAX_PROVIDER
if (typeof BASE_PROVIDER === 'undefined') {
  throw new Error(`REACT_APP_AVAX_PROVIDER must be a defined environment variable`)
}


/**
 * Known JSON-RPC endpoints.
 * These are the URLs used by the interface when there is not another available source of chain data.
 */
export const RPC_URLS = {
  [SupportedChainId.MAINNET]: [
    `https://mainnet.infura.io/v3/${INFURA_KEY}`,
    ...FALLBACK_URLS[SupportedChainId.MAINNET],
  ],
  [SupportedChainId.SOLANA]: [
    SOLANA_PROVIDER,
    ...FALLBACK_URLS[SupportedChainId.SOLANA],
  ],
  [SupportedChainId.BNBCHAIN]: [
    BSC_PROVIDER,
    ...FALLBACK_URLS[SupportedChainId.BNBCHAIN],
  ],
  [SupportedChainId.POLYGON]: [
    `https://polygon-mainnet.infura.io/v3/${INFURA_KEY}`,
    `https://empty-misty-theorem.matic.quiknode.pro/49b0357d4518c4d78f36216e8e4ec8a472221389/`,
    ...FALLBACK_URLS[SupportedChainId.POLYGON],
  ],
  [SupportedChainId.POLYGON_MUMBAI]: [
    `https://polygon-mumbai.infura.io/v3/${INFURA_KEY}`,
    ...FALLBACK_URLS[SupportedChainId.POLYGON_MUMBAI],
  ],
  [SupportedChainId.ARBITRUM_ONE]: [
    `https://arbitrum-mainnet.infura.io/v3/${INFURA_KEY}`,
    ...FALLBACK_URLS[SupportedChainId.ARBITRUM_ONE],
  ],
  [SupportedChainId.GOERLI]: [`https://goerli.infura.io/v3/${INFURA_KEY}`, ...FALLBACK_URLS[SupportedChainId.GOERLI]],
  [SupportedChainId.SEPOLIA]: [`https://eth-sepolia.g.alchemy.com/v2/${ALCHEMY_KEY}`, ...FALLBACK_URLS[SupportedChainId.SEPOLIA]],
  [SupportedChainId.BASE]: [
    `https://base-mainnet.infura.io/v3/${INFURA_KEY}`,
    //BASE_PROVIDER,
    ...FALLBACK_URLS[SupportedChainId.BASE],
  ],
  [SupportedChainId.AVAX]: [
    AVAX_PROVIDER,
    ...FALLBACK_URLS[SupportedChainId.AVAX],
  ],
  /*
  [SupportedChainId.KLAYTN]: [
    ...FALLBACK_URLS[SupportedChainId.KLAYTN],
  ],
  [SupportedChainId.OPTIMISM]: [
    `https://optimism-mainnet.infura.io/v3/${INFURA_KEY}`,
    ...FALLBACK_URLS[SupportedChainId.OPTIMISM],
  ],
  [SupportedChainId.OPTIMISM_GOERLI]: [
    `https://optimism-goerli.infura.io/v3/${INFURA_KEY}`,
    ...FALLBACK_URLS[SupportedChainId.OPTIMISM_GOERLI],
  ],
  [SupportedChainId.ARBITRUM_GOERLI]: [
    `https://arbitrum-goerli.infura.io/v3/${INFURA_KEY}`,
    ...FALLBACK_URLS[SupportedChainId.ARBITRUM_GOERLI],
  ],
  [SupportedChainId.CELO]: FALLBACK_URLS[SupportedChainId.CELO],
  [SupportedChainId.CELO_ALFAJORES]: FALLBACK_URLS[SupportedChainId.CELO_ALFAJORES],
  */
}
