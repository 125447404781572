import styled from "styled-components/macro";

import { shortenAddress } from "../../utils";
import { TransactionState } from "./index";

import { TransactionType } from "../../state/transactions/types";

const HighlightText = styled.span`
  color: ${({ theme }) => theme.textPrimary};
  font-weight: 600;
`;

const BodyWrap = styled.div`
  line-height: 20px;
`;

const Action = ({ pending, success, failed, transactionState }) => {
  switch (transactionState) {
    case TransactionState.Failed:
      return failed;
    case TransactionState.Success:
      return success;
    default:
      return pending;
  }
};

const FailedText = ({ transactionState }) =>
  transactionState === TransactionState.Failed ? <span>failed</span> : <span />;

const PresaleContributeSummary = ({ info, transactionState }) => {
  const actionProps = {
    transactionState,
    pending: <span>Contributing</span>,
    success: <span>Contributed</span>,
    failed: <span>Contribute</span>,
  };

  return (
    <BodyWrap>
      <Action {...actionProps} />{" "}
      {info.totalAmount && <span>{info.totalAmount} </span>}
      <HighlightText>ETH</HighlightText>{" "}
      for PAYAI presale{" "}
      in {info.network && <>{info.network}</>} 
      <FailedText transactionState={transactionState} />
    </BodyWrap>
  );
};

const TransactionBody = ({ info, transactionState }) => {
  switch (info.type) {
    case TransactionType.PRESALE_CONTRIBUTE:
      return <PresaleContributeSummary info={info} transactionState={transactionState} />;
    default:
      return <span />;
  }
};

export default TransactionBody;
