import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../layout/website";
import Section from "../../layout/global/Section";
import Container from "../../layout/global/Container";

import { useIsDarkMode } from '../../state/user/hooks'

function TermsOfUse() {
    const darkMode = useIsDarkMode()
    return (
        <Layout title="Terms of Use">
            <Section className="py-10 my-auto">
          <Container>
          <h1 className="text-4xl font-bold text-center">PayBolt AI Terms of Use</h1>
          <p className="text-center mt-4">
            By accessing or using our Interface and Network, you agree to comply with and be bound by the following terms and conditions.
          </p>

          <section className="mt-8 max-w-3xl mx-auto">
            <h2 className="text-2xl font-bold mb-4">1. Introduction</h2>
            <p className="mb-4">
              Welcome to PayBolt AI. By accessing or using our Interface and Network, you agree to comply with and be bound by the following terms and conditions.
            </p>
          </section>

          <section className="mt-8 max-w-3xl mx-auto">
            <h2 className="text-2xl font-bold mb-4">2. Acceptance of Terms</h2>
            <p className="mb-4">
              By using this Interface, you acknowledge that you have read, understood, and agree to be bound by these Terms of Use.
            </p>
          </section>

          <section className="mt-8 max-w-3xl mx-auto">
            <h2 className="text-2xl font-bold mb-4">3. Modification of Terms</h2>
            <p className="mb-4">
              We reserve the right to modify these Terms at any time. Your continued use of the Interface and Network after any changes indicates your acceptance of the new Terms.
            </p>
          </section>

          <section className="mt-8 max-w-3xl mx-auto">
            <h2 className="text-2xl font-bold mb-4">4. User Obligations</h2>
            <p className="mb-4">
              You agree to use the Interface and Network in accordance with all applicable laws and regulations.
            </p>
          </section>

          <section className="mt-8 max-w-3xl mx-auto">
            <h2 className="text-2xl font-bold mb-4">5. Prohibited Activities</h2>
            <p className="mb-4">
              You agree not to engage in any activity that could harm the PayBolt AI Network or other users.
            </p>
          </section>

          <section className="mt-8 max-w-3xl mx-auto">
            <h2 className="text-2xl font-bold mb-4">6. Intellectual Property Rights</h2>
            <p className="mb-4">
              All content, trademarks, and data on this Interface are the property of PayBolt AI or its licensors.
            </p>
          </section>

          <section className="mt-8 max-w-3xl mx-auto">
            <h2 className="text-2xl font-bold mb-4">7. Privacy Policy</h2>
            <p className="mb-4">
              Please refer to our Privacy Policy for information on how we collect, use, and disclose your personal information.
            </p>
          </section>

          <section className="mt-8 max-w-3xl mx-auto">
            <h2 className="text-2xl font-bold mb-4">8. Disclaimer of Warranties</h2>
            <p className="mb-4">
              This Interface is an open source software portal to PayBolt AI. THIS INTERFACE AND THE PAYBOLT AI NETWORK ARE PROVIDED "AS IS", AT YOUR OWN RISK, AND WITHOUT WARRANTIES OF ANY KIND.
            </p>
          </section>

          <section className="mt-8 max-w-3xl mx-auto">
            <h2 className="text-2xl font-bold mb-4">9. Limitation of Liability</h2>
            <p className="mb-4">
              By using or accessing this Interface or the PayBolt AI Network, you agree that no developer or entity involved in creating, deploying, maintaining, operating this Interface or the PayBolt AI Network, or causing or supporting any of the foregoing, will be liable in any manner for any claims or damages whatsoever associated with your use, inability to use, or your interaction with other users of, this Interface or the PayBolt AI Network, or this Interface or the PayBolt AI Foundation themselves, including any direct, indirect, incidental, special, exemplary, punitive, or consequential damages, or loss of profits, cryptocurrencies, tokens, or anything else of value.
            </p>
          </section>

          <section className="mt-8 max-w-3xl mx-auto">
            <h2 className="text-2xl font-bold mb-4">10. User Representation</h2>
            <p className="mb-4">
              By using or accessing this Interface, you represent that you are not subject to sanctions or otherwise designated on any list of prohibited or restricted parties or excluded or denied persons, including but not limited to the lists maintained by the United States' Department of Treasury's Office of Foreign Assets Control, the United Nations Security Council, the European Union or its Member States, or any other government authority.
            </p>
          </section>

          <section className="mt-8 max-w-3xl mx-auto">
            <h2 className="text-2xl font-bold mb-4">11. Governing Law</h2>
            <p className="mb-4">
              These Terms are governed by and construed in accordance with the laws of the jurisdiction in which PayBolt AI operates.
            </p>
          </section>

          <section className="mt-8 max-w-3xl mx-auto">
            <h2 className="text-2xl font-bold mb-4">12. Contact Information</h2>
            <p className="mb-4">
              For any questions about these Terms, please contact us at <a href="mailto:support@paybolt.com" className="text-blue-600 hover:underline">support@paybolt.com</a>.
            </p>
          </section>
          </Container>
          </Section>
        </Layout>
    );
}


export default TermsOfUse;
