import { SupportedChainId } from './chains'

export const MULTICALL_ADDRESSES = {
  [SupportedChainId.MAINNET]: '0x1F98415757620B543A52E61c46B32eB19261F984',
  [SupportedChainId.GOERLI]: '0x39d7e721c88b505d004819992bc27b364d74c8e9',
  [SupportedChainId.POLYGON]: '0x1F98415757620B543A52E61c46B32eB19261F984',
  [SupportedChainId.POLYGON_MUMBAI]: '0xbc8948A9764F75810879D40e0f4f7c4209588A81',
  [SupportedChainId.BNBCHAIN]: '0x963Df249eD09c358A4819E39d9Cd5736c3087184',
  //[SupportedChainId.KLAYTN]: '0x000',
  [SupportedChainId.SEPOLIA]: '0x39d7e721c88b505d004819992bc27b364d74c8e9',
  [SupportedChainId.ARBITRUM_ONE]: '0x1F98415757620B543A52E61c46B32eB19261F984',
  [SupportedChainId.BASE]: '0x348A78EDbffBDf8a9e3d6ec3cb82233c1deE8af1',
  [SupportedChainId.AVAX]: '0x1F98415757620B543A52E61c46B32eB19261F984',
}

// 0xDc441F575CE21C5257422D219Fe1923E1867e164
// 0xF437a1D0556abE35BA0CACD61d233D6468d03a98
// 0x753a5e6dac8113Ca15Ad34C37AbE26d6736EF290
// 0xac0194b277dD58DfA0E2c2d6a0C2492C1e7b2c1f
// 0x9aA1795E7056aa2B7F2Fd5f4E835963a43Fe2f13
export const PAYAI_PRESALE_ADDRESS = '0x9aA1795E7056aa2B7F2Fd5f4E835963a43Fe2f13';
export const PAYAI_ADDRESS = '0x3b745514ae7a9b0e8dba516e90b4deafbd6d3512';

