import "@reach/dialog/styles.css";
import "./components/Analytics";

import React from "react";
import ReactDOM from "react-dom/client";
import { Web3ReactProvider } from "@web3-react/core";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import ThemeProvider, { ThemedGlobalStyle } from "./theme";
import Web3Provider from "./components/Web3Provider";
import CookieConsent from "./components/CookieConsent";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import ApplicationUpdater from "./state/application/updater";
import TransactionUpdater from "./state/transactions/updater";
import BlockUpdater from "./lib/hooks/useBlockNumber";
import { MulticallUpdater } from "./lib/state/multicall";
import { BlockNumberProvider } from "./lib/hooks/useBlockNumber";

import store from "./state";

window.Buffer = window.Buffer || require("buffer").Buffer;

if (window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false;
}

function Updaters() {
  return (
    <>
      <ApplicationUpdater />
      <TransactionUpdater />
      <MulticallUpdater />
    </>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Web3Provider>
          <BlockNumberProvider>
            <Updaters />
            <ThemeProvider>
              <ThemedGlobalStyle />
              <App />
            </ThemeProvider>
          </BlockNumberProvider>
      </Web3Provider>
      <ToastContainer />
      <CookieConsent />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
