import React, { useState, useEffect, useRef, Suspense, lazy } from "react";
import { Link } from "react-router-dom";
import { AlertTriangle } from "react-feather";
import Web3 from "web3";

import Layout from "../../../layout/dashboard";
import Section from "../../../layout/global/Section";
import Container from "../../../layout/global/Container";
import { Breadcrumbs, Button, Card } from "../../../components";
import { formatBigNumber } from "../../../utils/currency";
import { isAddress } from "../../../utils";

import useSelectChain from "../../../hooks/useSelectChain";
import { SupportedChainId } from "../../../constants/chains";

import { useToggleWalletModal } from "../../../state/application/hooks";
import { useCustomWeb3React } from "../../../providers/CustomWeb3ReactProvider";

import { ReactComponent as BaseLogo } from "../../../assets/svg/base-logo.svg";

const ReCAPTCHA = lazy(() => import('react-google-recaptcha'));

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY
if (typeof RECAPTCHA_SITE_KEY === 'undefined') {
  throw new Error(`REACT_APP_RECAPTCHA_SITE_KEY must be a defined environment variable`)
}

// RotatingText Component
const RotatingText = ({ messages, interval = 2000 }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, interval);

    return () => clearInterval(intervalId);
  }, [messages, interval]);

  return <div className="mt-4 text-center">{messages[index]}</div>;
};

// LoadingSpinner Component
const LoadingSpinner = () => (
  <div className="flex flex-col items-center justify-center h-[200px]">
    <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
    <RotatingText
      messages={[
        "Scanning PAY token...",
        "Scanning BNBPay token...",
        "Scanning Staking Profile...",
      ]}
      interval={2000} // Adjust the interval as needed
    />
  </div>
);

function Home() {
  const selectChain = useSelectChain();
  const { account, chainId } = useCustomWeb3React();
  const toggleWalletModal = useToggleWalletModal();

  const recaptchaRef = useRef();
  const [eligibleAirdrop, setEligibleAirdrop] = useState(Web3.utils.toBN(0));
  const [bnbpayStatus, setBnbpayStatus] = useState("-");
  const [payStatus, setPayStatus] = useState("-");
  const [inputLink, setInputLink] = useState("");
  const [linkWarning, setLinkWarning] = useState("");
  const [addressWarning, setAddressWarning] = useState("");
  const [walletAddress, setWalletAddress] = useState("");

  const [migrationData, setMigrationData] = useState(null);
  const [airdropData, setAirdropData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [linkIsValid, setLinkIsValid] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://api.paybolt.com/airdrop/xmessage", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        setMessage(data.message);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.message);
      } finally {
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (account) {
      setWalletAddress(account);
    } 
  }, [account]);

  const fetchAirdropData = async () => {
    setAirdropData(null);
    setLoading(true);
    try {
      const response = await fetch(
        `https://api.paybolt.com/airdrop/entry?address=${walletAddress}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        },
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      setEligibleAirdrop(data.airdrop || 0)
      setAirdropData(data)

      if (data && data.status != "not_found") {
        setAddressWarning("This address has already been submitted for the airdrop.")
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setMigrationData(null);
      setLoading(true);
      try {
        const response = await fetch(
          "https://api.paybolt.com/migration/eligibility",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({ address: walletAddress }),
          },
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setMigrationData(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };


    if (isAddress(walletAddress)) {
      fetchAirdropData();
      fetchData();
    } else {
      setMigrationData(null);
      setAirdropData(null);

      if (walletAddress.length > 0) {
        setAddressWarning("Invalid ERC20 address format");
      }
    }
  }, [walletAddress]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setLinkWarning("");

    // Remove all spaces from the input value
    const valueWithoutSpaces = value.replace(/\s+/g, "");

    setInputLink(valueWithoutSpaces);

    // Regular expression to validate HTTP/HTTPS URLs
    const urlPattern =
      /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}|\[?[a-f\d]*:[a-f\d:]+\]?)?(:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[\-a-z\d_]*)?$/i;

    if (urlPattern.test(valueWithoutSpaces)) {
      setLinkIsValid(true);
    } else {
      setLinkWarning("Invalid link format");
    }
  };

  const handleAddressChange = (e) => {
    const { value } = e.target;
    setAddressWarning("");

    // Remove all spaces from the input value
    const valueWithoutSpaces = value.replace(/\s+/g, "");

    setWalletAddress(valueWithoutSpaces);
  };

  const handleShareClick = () => {
    const url = encodeURIComponent("https://paybolt.com");
    const text = encodeURIComponent(message);
    const twitterShareUrl = `https://x.com/intent/post?url=${url}&text=${text}&related=PayBoltAI`;
    window.open(twitterShareUrl, "_blank", "width=560,height=400");
  };

  const submitNow = async () => {
    if (!recaptchaToken) {
      alert('Please complete the reCAPTCHA');
      return;
    }

    if (inputLink.length < 14) {
      setLinkWarning("Please provide a valid link");
    } else if (!isAddress(walletAddress)) {
      setLinkWarning("Please provide your wallet address in Step 1");
    } else {
      setSubmitLoading(true);

      const formData = {
        address: walletAddress,
        share_link: inputLink,
        recaptchaToken,
      };

      try {
        const response = await fetch('https://api.paybolt.com/airdrop/entry', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        const result = await response.json();
        if (response.ok) {
          // Handle success
          alert('Submission successful!');
          setInputLink("")
          fetchAirdropData();
        } else {
          // Handle errors
          alert(result.error || 'An error occurred');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('An error occurred');
      } finally {
        setSubmitLoading(false);
      }
    }
  };

  return (
    <Layout title="Claim Your Free $PAYAI Airdrop">
      <Section className="py-10 px-3">
        <Container>
          <div className="mb-7 flex justify-between items-center -mx-3">
            <div className="px-3">
              <h1 className="text-2xl font-bold text-slate-700 dark:text-white mb-2">
                Claim Your Free $PAYAI Airdrop
              </h1>
              <Breadcrumbs
                items={[
                  { text: "Home", link: "/app" },
                  { text: "Airdrop", link: "" },
                ]}
              />
            </div>
            <div className="px-3"></div>
          </div>
          <h3 className="max-w-3xl">
            The PAYAI airdrop is open to all users! Participate in this
            exclusive event and get rewarded with PAYAI tokens. Follow the steps
            below to become eligible for the airdrop and join us in the future
            of decentralized finance.
          </h3>
          <div className="flex flex-wrap -m-3 mb-4 mt-2">
            <div className="w-full lg:w-1/2 p-3">
              <div className="flex items-center px-4 py-3 bg-yellow-50 rounded-md">
                <div className="bg-yellow-400 p-2 rounded-full text-white">
                  <AlertTriangle className="h-4 w-4" />
                </div>
                <div className="ps-3">
                  <h6 className="text-sm font-bold text-slate-900">
                    Security Confirmation
                  </h6>
                  <p className="text-sm text-slate-900">
                    Please ensure you are on <b>paybolt.com/airdrop</b> by
                    typing the address into your browser and verifying the
                    validity of the SSL certificate.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <h2 className="flex text-xl font-bold text-slate-700 dark:text-white mb-2">
            Step 1. Provide Your Wallet Address
          </h2>
          <div className="flex flex-wrap -m-3">
            <div className="w-full lg:w-1/2 p-3">
              {account ? (
                <div className="bg-slate-800 rounded-md border border-slate-800 p-5 h-full">
                  <div className="flex flex-col isolate relative h-full">
                    <div className=" absolute end-0 top-0"></div>
                    <div className="mb-auto">
                      <h6 className="font-bold text-xl w-max bg-gradient-to-r from-blue-300 to-pink-500 text-transparent bg-clip-text">
                        Welcome
                      </h6>
                      <div className="font-bold text-sm text-white mt-2 break-all">
                        {account}
                      </div>
                    </div>
                    <div className="flex gap-x-6 mt-4">
                      <div className="">
                        <div className="text-xs text-slate-300 mt-1"></div>
                        <div className="text-base font-bold text-slate-100 mt-1"></div>
                      </div>
                      <div className="">
                        <div className="text-xs text-slate-300 mt-1"></div>
                        <div className="text-base font-bold text-slate-100 mt-1"></div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <Card className="h-full pt-5 ">
                  <div className="flex flex-col isolate relative mb-5 mx-5 ">
                    <input
                      type="text"
                      placeholder="0x"
                      value={walletAddress}
                      onChange={handleAddressChange}
                      className="p-2 text-black border border-gray-300 rounded h-10 no-spinner"
                    />
                    <div className="text-pink-500 dark:text-red-300 text-sm mt-1">
                      {addressWarning}
                    </div>

                    <div className="my-2 text-center relative">
                      <div className="absolute inset-0 flex items-center">
                        <div className="w-full border-t border-gray-300"></div>
                      </div>
                      <div className="relative inline-block px-4 bg-white dark:bg-slate-950">
                        <span className="text-lg">OR</span>
                      </div>
                    </div>

                    <div className="flex items-center justify-center">
                      Connect&nbsp;<BaseLogo width={24} height={24} className="pt-1" />&nbsp;Base Wallet&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <button
                        className="w-[160px] border-none rounded-[8px] cursor-pointer text-base px-2 py-2 transition duration-fast ease-in bg-blue-600 text-white hover:bg-blue-800"
                        onClick={toggleWalletModal}
                      >
                        Connect Wallet
                      </button>
                    </div>
                  </div>
                </Card>
              )}
            </div>
          </div>

          <h2 className="text-xl font-bold text-slate-700 dark:text-white mb-2 mt-8">
            Step 2. Share on Social Media
          </h2>

          <div className="flex flex-wrap -m-3">
            <div className="w-full lg:w-1/2 p-3">
              <Card className="h-full">
                <div className="flex flex-col isolate relative mb-5">
                  <div className="px-5 pt-5 text-md text-slate-700 dark:text-slate-300">
                    Each participant will receive{" "}
                    <b className="text-blue-600">5,000 PAYAI</b> tokens for
                    completing this step.
                    <br />
                    <br />
                    Share something about PayBolt AI on X/Twitter.
                    <br />
                    <br />
                    Not sure what to write? Use our pre-compiled messages:
                  </div>

                  <button
                    className="w-[210px] mx-5 flex items-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={handleShareClick}
                  >
                    <svg
                      className="inline fill-current text-black dark:text-white mr-2"
                      width="16"
                      height="16"
                      viewBox="0 0 300 271"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="m236 0h46l-101 115 118 156h-92.6l-72.5-94.8-83 94.8h-46l107-123-113-148h94.9l65.5 86.6zm-16.1 244h25.5l-165-218h-27.4z"></path>
                    </svg>
                    Share on X/Twitter
                  </button>

                  {(!airdropData || (airdropData && airdropData.status == "not_found")) && <>
                    <div className="px-5 pt-5 text-md text-slate-700 dark:text-slate-300">
                      Provide the link to your social media post:
                    </div>
                    <input
                      type="text"
                      placeholder="https://"
                      value={inputLink}
                      onChange={handleInputChange}
                      className="mx-5 p-2 text-black border border-gray-300 rounded h-10 no-spinner"
                    />
                    <div className="mx-5 text-pink-500 dark:text-red-300 text-sm mt-1">
                      {linkWarning}
                    </div>
                    <Suspense fallback={<div>Loading reCAPTCHA...</div>}>
                      <div className="mx-5 mt-1">
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          sitekey={RECAPTCHA_SITE_KEY}
                          //size="invisible"
                          onChange={setRecaptchaToken}
                        />
                      </div>
                    </Suspense>
                    <div className="mx-5 w-[180px] mt-1">
                      <button
                        className={`border-none rounded-[8px] cursor-pointer text-base px-4 py-2 transition duration-fast ease-in bg-blue-600 text-white hover:bg-blue-800 ${
                          submitLoading ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                        onClick={() => submitNow()}
                        disabled={submitLoading}
                      >
                        {submitLoading ? (
                          <div className="flex items-center">
                            <svg
                              className="inline-block animate-spin h-4 w-4 mr-2 border-t-2 border-b-2 border-white rounded-full"
                              viewBox="0 0 24 24"
                            ></svg>
                            Submitting...
                          </div>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </>
                }
                </div>
              </Card>
            </div>
          </div>

          <h2 className="text-xl font-bold text-slate-700 dark:text-white mb-2 mt-8">
            Step 3. Review Your Total Eligible Airdrop Amount
          </h2>
          <div className="flex flex-wrap -m-3">
            <div className="w-full lg:w-1/2 p-3">
              <Card className="h-full">
                <div className="flex flex-col isolate relative">
                  <div className="px-5 pt-5 text-md text-slate-700 dark:text-slate-300">
                    Additional <b className="text-blue-600">5,000 PAYAI</b>{" "}
                    tokens for holder of BNBPay and PayBolt tokens!
                    <br />
                  </div>

                  <div className="px-5 py-2 flex justify-between items-center gap-x-4">
                    <h6 className="text-md font-bold text-slate-700 dark:text-white">
                      Eligibility
                    </h6>
                    {loading ? (
                      <LoadingSpinner />
                    ) : (
                      <span className="text-md font-bold text-slate-700 dark:text-white">
                        {migrationData ? (
                          Web3.utils
                            .toBN(migrationData.totals.payai)
                            .gt(Web3.utils.toBN(0)) ? (
                            <span className="text-green-600">YES</span>
                          ) : (
                            "NO"
                          )
                        ) : (
                          "NO"
                        )}
                      </span>
                    )}
                  </div>
                </div>

                <div className="m-4 flex flex-wrap justify-between items-center bg-white dark:bg-slate-600 border border-slate-200 dark:border-slate-800 px-5 py-4 rounded-md gap-3">
                  <div className="">
                    <p className="text-sm text-slate-500 dark:text-slate-400">
                      Airdrop Amount
                    </p>
                    <h4 className="text-lg font-bold text-slate-600 dark:text-slate-200">
                      {eligibleAirdrop ? formatBigNumber(eligibleAirdrop.toString(), 0, 0, true) : 0}
                      <span className="text-xs font-bold text-slate-400 ms-2"></span>
                    </h4>
                    <h3 className="text-xl font-bold text-blue-600 dark:text-blue-400 mb-1">
                      PAYAI
                    </h3>
                    {
                      airdropData ? (
                          airdropData.status == "pending_verification" ? 
                          <div className="p-2 bg-slate-200 text-slate-600 text-xs rounded-lg">
                            Pending Verification
                          </div>
                          : airdropData.status == "verified" ? 
                          <div className="p-2 bg-blue-200 text-slate-600 text-xs rounded-lg">
                            Verified
                          </div>
                          : airdropData.status == "airdropped" ? 
                          <div className="p-2 bg-blue-200 text-slate-600 text-xs rounded-lg">
                            AirDropped
                          </div>
                          : 
                          <></>
                        )
                        : 
                        <></>
                    }
                  </div>
                  <ul className="flex flex-col gap-3">
                    <li>
                      <Button className="bg-slate-200 text-slate-600 hover:cursor-default">
                        Claiming begins soon
                      </Button>
                    </li>
                    <li>
                      <Button className="hidden bg-blue-600 text-white hover:bg-blue-800">
                        Claim
                      </Button>
                    </li>
                  </ul>
                </div>
              </Card>
            </div>
          </div>

          <div className="flex flex-wrap -m-3 mb-24">
            <div className="w-full lg:w-1/2 p-3"></div>
          </div>
        </Container>
      </Section>
    </Layout>
  );
}

export default Home;
