import React from "react";
import Layout from "../../../layout/dashboard";
import Section from "../../../layout/global/Section";
import Container from "../../../layout/global/Container";
import {
	Accordion,
	Breadcrumbs,
	Label,
	Textarea,
	Input,
	Card,
} from "../../../components";
function FaqPage() {
	return (
		<Layout title="Migration FAQ">
			<Section className="py-10 px-3">
				<Container>
					<div className="mb-7 flex flex-col items-center">
						<h2 className="text-xl font-bold text-slate-700 dark:text-white mb-2">
							BNBPay $BPAY / PayBolt $PAY Migration FAQ
						</h2>
						<Breadcrumbs
							items={[
								{ text: "Home", link: "/" },
								{ text: "Migration", link: "/migration" },
								{ text: "FAQ" },
							]}
						/>
					</div>
					<div className="flex flex-wrap items-start -mx-3">
						<div className="w-full md:w-3/5 p-3">
							<Accordion className="flex flex-col gap-3">
								<Accordion.Item
									className="bg-white dark:bg-slate-950 rounded-md border border-slate-200 dark:border-slate-800"
									header={"When is the $PAY migration?"}
									initialEntered
								>
									<p>
										After the presale of $PAYAI and before
										$PAYAI starts trading.
									</p>
								</Accordion.Item>
								<Accordion.Item
									className="bg-white dark:bg-slate-950 rounded-md border border-slate-200 dark:border-slate-800"
									header={
										"How many tokens will I get for your $PAY?"
									}
				                  	initialEntered
								>
									<p>1 $PAY : 1 $PAYAI</p>
								</Accordion.Item>
								<Accordion.Item
									className="bg-white dark:bg-slate-950 rounded-md border border-slate-200 dark:border-slate-800"
									header={"What is the $PAYAI trading tax?"}
				                  	initialEntered
								>
									<p>
										Buy tax 3% and sell tax 7%.
										<br />
										Additionally, PayBolt Army will enjoy a
										share of the tax with weekly payouts:
										<br />
										<ul>
											<li>0.1% Premier</li>
											<li>0.3% Elite</li>
											<li>1% Platinum</li>
										</ul>
										Huge rewards for our loyal army!
									</p>
								</Accordion.Item>
								<Accordion.Item
									className="bg-white dark:bg-slate-950 rounded-md border border-slate-200 dark:border-slate-800"
									header={
										"Can we expect burn in PayBolt also in the future?"
									}
				                  	initialEntered
								>
									<p>
										We will monitor over time and burn
										supply if required.
									</p>
								</Accordion.Item>
								<Accordion.Item
									className="bg-white dark:bg-slate-950 rounded-md border border-slate-200 dark:border-slate-800"
									header={"How do I convert $PAY to $PAYAI?"}
				                  	initialEntered
								>
									<p>
										This will be done for you automatically.
									</p>
								</Accordion.Item>
								<Accordion.Item
									className="bg-white dark:bg-slate-950 rounded-md border border-slate-200 dark:border-slate-800"
									header={
										"Does it cost money to convert $PAY to $PAYAI?"
									}
				                  	initialEntered
								>
									<p>None.</p>
								</Accordion.Item>
								<Accordion.Item
									className="bg-white dark:bg-slate-950 rounded-md border border-slate-200 dark:border-slate-800"
									header={
										"When will the PayBolt AI smart contract address be published?"
									}
				                  	initialEntered
								>
									<p>
										As soon as the presale ends.
										<br />
										For more details please visit our
										website:{" "}
										<a
											href="https://paybolt.com"
											target="_blank"
											rel="noopener noreferrer"
										>
											paybolt.com
										</a>
									</p>
								</Accordion.Item>
							</Accordion>
						</div>
					</div>
				</Container>
			</Section>
		</Layout>
	);
}

export default FaqPage;
