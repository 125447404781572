import { BigNumber } from '@ethersproject/bignumber';
import { useMemo } from 'react';

import { useSingleCallResult } from '../lib/hooks/multicall';
import { useInterfaceMulticall } from './useContract';

export default function useCurrentBlockTimestamp() {
  const multicall = useInterfaceMulticall();
  const resultStr = useSingleCallResult(
    multicall,
    'getCurrentBlockTimestamp'
  )?.result?.[0]?.toString();

  return useMemo(() => (typeof resultStr === 'string' ? BigNumber.from(resultStr) : undefined), [resultStr]);
}
