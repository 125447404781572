import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Sun, Moon, Menu as MenuIcon } from "react-feather";

import Menu from "./Menu";
import Container from "../../global/Container";
import Logo from "../../../assets/images/logo.png";
import WhiteLogo from "../../../assets/images/logo-white.png";
import { useDarkModeManager } from "../../../state/user/hooks";

function Header({ mobile, visibility, setVisibility, className }) {
    const [darkMode, updateIsDarkMode] = useDarkModeManager();

    const menuClass = classNames({
        "flex flex-col py-4 xl:py-0 w-64 xl:w-auto fixed xl:transition-none xl:static end-0 top-0 border-s dark:border-slate-800 xl:border-s-0 bg-white dark:bg-slate-950 z-[1020] h-screen xl:h-auto flex-shrink-0 xl:translate-x-0": true,
        "transition-all": mobile,
        "translate-x-full": !visibility,
        [`${className}`]: className,
    });

    return (
        <div className="isolate fixed top-0 start-0 w-full py-4 xl:py-3 border-b border-slate-200 dark:border-slate-800 bg-white dark:bg-slate-950 z-[1020] px-3">
            <Container>
                <div className="flex items-center w-100 justify-between">
                    <div className="flex items-center gap-x-2">
                        <Link
                            className="flex-shrink-0 w-[120px] xs:w-[160px]"
                            to="/"
                        >
                            {darkMode ? (
                                <img src={WhiteLogo} />
                            ) : (
                                <img src={Logo} />
                            )}
                        </Link>
                    </div>
                    {visibility && (
                        <div
                            onClick={() => {
                                setVisibility(false);
                            }}
                            className="fixed inset-0 bg-slate-950 bg-opacity-50 z-[1019]"
                        ></div>
                    )}
                    <div className={menuClass}>
                        <div className="flex">
                            <Link
                                className="flex-1 xl:hidden -ms-1.5 ml-4 mb-4"
                                to="/"
                            >
                                {darkMode ? (
                                    <img src={WhiteLogo} />
                                ) : (
                                    <img src={Logo} />
                                )}
                            </Link>
                        </div>

                        <Menu mobile={mobile} />

                        <div className="flex">
                            <ul className="flex flex-row flex-1 xl:hidden -ms-1.5 mt-14 ml-4 mb-4">
                                <li className="mr-6">
                                    <a
                                        href="https://x.com/PayBoltAI"
                                        target="_blank"
                                        className="inline-flex text-sm/7 text-slate-500 dark:text-slate-400 hover:text-blue-600 hover:dark:text-blue-600 transition-all"
                                    >
                                        <span>
                                            <svg
                                                className="inline fill-current text-black dark:text-white"
                                                width="19"
                                                height="19"
                                                viewBox="0 0 300 271"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="m236 0h46l-101 115 118 156h-92.6l-72.5-94.8-83 94.8h-46l107-123-113-148h94.9l65.5 86.6zm-16.1 244h25.5l-165-218h-27.4z"></path>
                                            </svg>
                                        </span>
                                    </a>
                                </li>
                                <li className="mr-6">
                                    <a
                                        href="https://t.me/PayBoltAI"
                                        target="_blank"
                                        className="inline-flex text-sm/7 text-slate-500 dark:text-slate-400 hover:text-blue-600 hover:dark:text-blue-600 transition-all"
                                    >
                                        <span>
                                            <svg
                                                className="inline"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="19"
                                                height="20"
                                                viewBox="0 0 16 15"
                                            >
                                                <path
                                                    className="fill-current text-black dark:text-white"
                                                    d="M15.9542857,0.914285714 L13.44,13.4857143 C13.44,13.4857143 13.0742857,14.3542857 12.1142857,13.9428571 L6.4,9.55428571 L4.29714286,8.54857143 L0.822857143,7.31428571 C0.822857143,7.31428571 0.274285714,7.13142857 0.228571429,6.72 C0.182857143,6.30857143 0.822857143,6.08 0.822857143,6.08 L14.8114286,0.594285714 C14.8114286,0.594285714 15.9542857,0.0914285714 15.9542857,0.914285714 Z"
                                                ></path>
                                                <path
                                                    className="paper fill-current text-gray-400 dark:text-gray-600"
                                                    d="M5.98857143,13.3485714 C5.98857143,13.3485714 5.80571429,13.3485714 5.62285714,12.6628571 C5.39428571,12.0228571 4.34285714,8.50285714 4.34285714,8.50285714 L12.8,3.15428571 C12.8,3.15428571 13.3028571,2.88 13.2571429,3.15428571 C13.2571429,3.15428571 13.3485714,3.2 13.0742857,3.42857143 C12.8,3.65714286 6.44571429,9.50857143 6.44571429,9.50857143"
                                                ></path>
                                                <path
                                                    className="fill-current text-black dark:text-white"
                                                    d="M8.64,11.2457143 L6.35428571,13.3028571 C6.35428571,13.3028571 6.17142857,13.44 5.98857143,13.3485714 L6.44571429,9.50857143"
                                                ></path>
                                            </svg>
                                        </span>
                                    </a>
                                </li>
                                <li className="mr-6">
                                    <a
                                        href="https://paybolt.medium.com/"
                                        target="_blank"
                                        className="inline-flex text-sm/7 text-slate-500 dark:text-slate-400 hover:text-blue-600 hover:dark:text-blue-600 transition-all"
                                    >
                                        <span>
                                            <svg
                                                className="inline"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="18"
                                                viewBox="0 1 16 13"
                                            >
                                                <path
                                                    className="fill-current text-black dark:text-white"
                                                    d="M156.897292,4.65196943 C156.917171,4.45095715 156.842272,4.25227888 156.695612,4.11699 L155.201681,2.27513227 L155.201681,2 L159.840336,2 L163.42577,10.047619 L166.577965,2 L171,2 L171,2.27513227 L169.722689,3.52851264 C169.612571,3.61441846 169.557947,3.75561078 169.580766,3.89535568 L169.580766,13.1046444 C169.557947,13.2443893 169.612571,13.3855815 169.722689,13.4714874 L170.970121,14.7248678 L170.970121,15 L164.695612,15 L164.695612,14.7248678 L165.987862,13.4409171 C166.114846,13.3109935 166.114846,13.2727807 166.114846,13.0740741 L166.114846,5.63021753 L162.521942,14.9694297 L162.036415,14.9694297 L157.853408,5.63021753 L157.853408,11.8894768 C157.818532,12.1526336 157.903929,12.417575 158.084967,12.6078778 L159.76564,14.6942975 L159.76564,14.9694297 L155,14.9694297 L155,14.6942975 L156.680672,12.6078778 C156.860392,12.4172595 156.94081,12.1505606 156.897292,11.8894768 L156.897292,4.65196943 Z"
                                                    transform="translate(-155 -2)"
                                                ></path>
                                            </svg>
                                        </span>
                                    </a>
                                </li>
                            </ul>

                            <button
                                onClick={() => {
                                    updateIsDarkMode(!darkMode);
                                }}
                                className={`mr-4 mt-14 xl:hidden inline-flex items-center justify-center h-8 w-8 rounded-full overflow-hidden transition-all text-slate-400 hover:text-slate-600 hover:bg-slate-200 dark:text-slate-300 dark:bg-slate-800`}
                            >
                                {darkMode ? (
                                    <Moon className="h-4" />
                                ) : (
                                    <Sun className="h-5" />
                                )}
                            </button>
                        </div>
                    </div>
                    <ul className="flex items-center gap-x-3 lg:gap-x-5">
                        <li className="inline-flex relative hidden xl:block">
                            <button
                                onClick={() => {
                                    updateIsDarkMode(!darkMode);
                                }}
                                className={`inline-flex items-center justify-center h-8 w-8 rounded-full overflow-hidden transition-all text-slate-400 hover:text-slate-600 hover:bg-slate-200 dark:text-slate-300 dark:bg-slate-800`}
                            >
                                {darkMode ? (
                                    <Moon className="h-4" />
                                ) : (
                                    <Sun className="h-5" />
                                )}
                            </button>
                        </li>
                        <li>
                            <a
                                href="/app"
                                className="inline-flex font-medium text-sm bg-blue-600 text-white hover:bg-blue-600 transition-all px-5 py-2 rounded-full"
                            >
                                <span className="xs:inline">APP</span>
                            </a>
                        </li>
                        <li className="xl:hidden -ms-1.5 ml-1 xs:ml-2">
                            <button
                                onClick={() => {
                                    setVisibility(true);
                                }}
                                className="inline-flex items-center justify-center h-8 w-8 rounded-full overflow-hidden transition-all text-slate-400 hover:bg-slate-200 hover:dark:bg-slate-800 hover:text-slate-600 hover:dark:text-slate-200 ui-open:bg-slate-200 ui-open:dark:bg-slate-800 ui-open:text-slate-600 ui-open:dark:text-slate-200"
                            >
                                <MenuIcon className="h-5" />
                            </button>
                        </li>
                    </ul>
                </div>
            </Container>
        </div>
    );
}

export default Header;
