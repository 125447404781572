import { useMemo } from 'react';
import { Contract } from '@ethersproject/contracts';
import { useCustomWeb3React } from '../providers/CustomWeb3ReactProvider';

import MULTICALL_ABI from '../abis/multicall.json';
import PAYAI_PRESALE_ABI from '../abis/payai-presale.json';
import PAYAI_ABI from '../abis/payai.json';

import {
  MULTICALL_ADDRESSES,
  PAYAI_ADDRESS,
  PAYAI_PRESALE_ADDRESS,
} from '../constants/addresses';

import { getContract } from '../utils';

// returns null on errors
export function useContract(
  addressOrAddressMap,
  ABI,
  withSignerIfPossible = true
) {
  const { provider, account, chainId } = useCustomWeb3React();

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !provider || !chainId) return null;
    let address;
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap;
    else address = addressOrAddressMap[chainId];
    if (!address) return null;
    try {
      return getContract(address, ABI, provider, withSignerIfPossible && account ? account : undefined);
    } catch (error) {
      console.error('Failed to get contract', error);
      return null;
    }
  }, [addressOrAddressMap, ABI, provider, chainId, withSignerIfPossible, account]);
}

export function useInterfaceMulticall() {
  return useContract(MULTICALL_ADDRESSES, MULTICALL_ABI, false);
}

export function usePayaiContract() {
  return useContract(PAYAI_ADDRESS, PAYAI_ABI, false);
}

export function usePayaiPresaleContract() {
  return useContract(PAYAI_PRESALE_ADDRESS, PAYAI_PRESALE_ABI, false);
}