import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  Landing,
  Maintenance, 
  NotFound, 
  PrivacyPolicy,
  Terms,
  PAYAI,
  AppDashboard,
  AppMigration,
  AppMigrationFaq,
  AppPresale,
  AppPresaleFaq,
  AppAirdrop,
  AppMembership,
} from "./pages";

import { Buttons } from "./components";
import Popups from './components/Popups'

import "./styles/index.scss";

function App() {
  return (
    <>
      <Popups />
      <BrowserRouter>
          <Routes>
            <Route path="app">
              <Route index element={<AppDashboard />} />
              <Route path="migration" element={<AppMigration />} />
              <Route path="airdrop" element={<AppAirdrop />} />
              {/* <Route path="presale" element={<AppPresale />} /> */}
            </Route>
            <Route path="/migration" element={<AppMigration />} />
            <Route path="/migration/faq" element={<AppMigrationFaq />} />
            <Route path="/airdrop" element={<AppAirdrop />} />
            <Route path="/membership" element={<AppMembership />} />
            {/* <Route path="/presale" element={<AppPresale />} /> */}
            {/* <Route path="/presale/faq" element={<AppPresaleFaq />} /> */}
            
            <Route path="/PAYAI" element={<PAYAI />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<Terms />} />

            <Route path="/" element={<Landing />} />

            <Route path="*" element={<NotFound />} /> {/* 404 Route */}
          </Routes>
        <Buttons.BackToTop />
      </BrowserRouter>
    </>
  );
}

export default App;
