import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../layout/website";
import Section from "../../layout/global/Section";
import Container from "../../layout/global/Container";

import { useIsDarkMode } from '../../state/user/hooks'

function PrivacyPolicy() {
    const darkMode = useIsDarkMode()
    return (
        <Layout title="Privacy Policy">
            <Section className="py-10 my-auto">
          <Container>
            <h1 className="text-4xl font-bold text-center">PayBolt AI Privacy Policy</h1>
          <p className="text-center mt-4">
            This Privacy Policy includes important information about your personal data and we
            encourage you to read it carefully.
          </p>
          
          <section className="mt-8 max-w-3xl mx-auto">
            <h2 className="text-2xl font-bold mb-4">Welcome</h2>
            <p className="mb-4">
              We provide payment infrastructure based on the blockchain. Businesses of all sizes use
              our gateway and services to accept payments and manage their businesses online.
              PayBolt cares about the security and privacy of the personal data that is entrusted to
              us.
            </p>
            <p className="mb-4">
              This Privacy Policy (“Policy”) describes the “Personal Data” that we collect about
              you, how we use it, how we share it, your rights and choices. This Policy also
              outlines your data subject rights, including the right to object to some uses of your
              Personal Data by us.
            </p>
            <p className="mb-4">
              “PayBolt”, “we”, “our” or “us” means the PayBolt entity responsible for the collection
              and use of personal data under this Privacy Policy. It differs depending on your
              region.
            </p>
            <p className="mb-4">
              “Personal Data” means any information that relates to an identified or identifiable
              individual, and can include information about how you engage with our Services (e.g.
              device information, IP address).
            </p>
            <p className="mb-4">
              “Services” means the products and services that PayBolt indicates are covered by this
              Policy, which may include PayBolt-provided devices and apps. Our “Business Services”
              are Services provided by PayBolt to entities (“Business Users”) who directly and
              indirectly provide us with “End Customer” Personal Data in connection with those
              Business Users' own business and activities. Our “End User Services” are those
              Services which PayBolt directs to individuals (rather than entities) so that those
              individuals do business directly with PayBolt. “Sites” means paybolt.com and the other
              websites that Paybolt indicates are covered by this Policy. Collectively, we refer to
              Sites, Business Services and End User Services as “Services”.
            </p>
            <p className="mb-4">
              Depending on the context, “you” means End Customer, End User, Representative or
              Visitor:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li className="mb-2">
                <p className="mb-0">
                  When you directly use an End User Service (such as when you make payments using our
                  channels in your personal capacity), for your personal use, we refer to you as an
                  “End User.”
                </p>
              </li>
              <li className="mb-2">
                <p className="mb-0">
                  When you do business with, or otherwise transact with, a Business User (typically a
                  merchant using PayBolt Checkout, e.g. when you buy a pair of shoes from a merchant
                  that uses PayBolt for payment processing) but are not directly doing business with
                  Stripe, we refer to you as an “End Customer.”
                </p>
              </li>
              <li className="mb-2">
                <p className="mb-0">
                  When you are acting on behalf of an existing or potential Business User (e.g. you are
                  a founder of a company, or administering an account for a merchant who is a Business
                  User), we refer to you as "Representative".
                </p>
              </li>
              <li className="mb-2">
                <p className="mb-0">
                  When you visit a Site without being logged into a PayBolt account or otherwise
                  communicate with PayBolt, we refer to you as a “Visitor.” (e.g. you send PayBolt a
                  message asking for more information because you are considering being a user of our
                  products). Depending on the activity, PayBolt acts as a “data controller” or “data
                  processor”.
                </p>
              </li>
            </ul>
          </section>

          <section className="mt-8 max-w-3xl mx-auto">
            <h2 className="text-2xl font-bold mb-4">1. Personal Data That We Collect And How We Use and Share it</h2>
            <h3 className="text-xl font-semibold mb-2">1.1 End Users</h3>
            <p className="mb-4">
              We provide End User Services where we do not act as a service provider to
              Businesses but instead provide the Services directly to you for your personal use
              (e.g. marketplace). See here for further information on the legal bases which we
              rely on for using (processing) your Personal Data.
            </p>
            <h3 className="text-xl font-semibold mb-2">Personal Data That We Collect About End Users</h3>
            <h4 className="text-lg font-semibold mb-2">Paying PayBolt</h4>
            <p className="mb-4">
              If you are buying goods or services directly from PayBolt, we receive Transaction
              Data. For example, when you make a payment, we will collect payment method
              information, information about that transaction and in some cases, shipping
              information.
            </p>
            <h4 className="text-lg font-semibold mb-2">Other Services</h4>
            <p className="mb-4">
              We may offer other End User Services directly to you from time to time, including
              certain financial Services. Learn More for information about our collection and
              use of Personal Data.
            </p>
            <h4 className="text-lg font-semibold mb-2">Merchant Privacy Policy</h4>
            <p className="mb-4">
              PayBolt, (“PayBolt”, “Company”, “we”, “us” or “our”) offers an online payment
              platform that makes it easy for Merchants to accept electronic payments from
              Customers. PayBolt values the privacy of Merchants who use our website and all
              related sites, applications, services and tools (collectively, our “Services”).This
              Privacy Policy describes how we collect, use, store, share, and protect personal
              data from Merchants who engage with our Services.
            </p>
            <p className="mb-4">
              This section relates to services that are primarily intended for and provided to
              businesses and other organisations (“Merchants”), and not individual consumers.
              Thus, we generally process personal data at the direction of and on behalf of
              Merchants. When we do, we do so as a service provider or a “data processor” to
              those Merchants, but we do not control and are not responsible for the privacy
              practices of those Merchants. If you are a Customer of a PayBolt Merchant, you
              should read that Merchant's privacy policy and direct any privacy inquiries to
              that Merchant.
            </p>
            <p className="mb-4">
              This Privacy Policy does not apply to services that are not owned or controlled by
              PayBolt, including third-party websites and the services of other PayBolt
              Merchants. This Privacy Policy applies to all forms of systems, operations and
              processes within the PayBolt environment that involve the processing of personal
              data.
            </p>
            <p className="mb-4">
              By using or accessing our Services, you agree to the collection, use, and
              disclosure of your personal data as described in this Privacy Policy. Your use of
              our Services is also subject to PayBolt's Terms. Should you disagree to abide
              by such Terms, or if you revoke your consent to the processing of your personal
              data, your account will be disabled and you will no longer be able to access or
              interact with the PayBolt platform.
            </p>
          </section>

          <section className="mt-8 max-w-3xl mx-auto">
            <h2 className="text-2xl font-bold mb-4">2. How we use personal data</h2>
            <p className="mb-4">We use the personal data we collect to:</p>
            <ul className="list-disc pl-6 mb-4">
              <li className="mb-2">Provide you with the required services</li>
              <li className="mb-2">Respond to your questions or requests</li>
              <li className="mb-2">
                Improve features, website content and analyse data to develop products and
                services
              </li>
              <li className="mb-2">Address inappropriate use of our website</li>
              <li className="mb-2">
                Prevent, detect and manage risk against fraud and illegal activities using
                internal and third party screening tools
              </li>
              <li className="mb-2">
                Send you marketing content, newsletters and service updates curated by
                PayBolt, however, we will provide you with an option to unsubscribe if you do
                not want to hear from us
              </li>
              <li className="mb-2">
                Verify your identity and the information you provide in line with PayBolt’s
                statutory obligations using internal and third party tools
              </li>
              <li className="mb-2">Maintain up-to-date records of Merchants</li>
              <li className="mb-2">
                Resolve disputes that may arise, including investigations by law enforcement
                or regulatory bodies
              </li>
              <li className="mb-2">
                Any other purpose that we disclose to you in the course of providing
                PayBolt's services to you
              </li>
            </ul>
          </section>

          <section className="mt-8 max-w-3xl mx-auto">
            <h2 className="text-2xl font-bold mb-4">3. How we share the Personal Data you provide</h2>
            <p className="mb-4">
              PayBolt does not sell, trade or rent personal data to anyone. Further, we will not
              share or disclose your personal data with a third party without your consent
              except as necessary to provide the Services or as described in this Privacy
              Policy.
            </p>
            <h3 className="text-xl font-semibold mb-2">Service Providers</h3>
            <p className="mb-4">
              We share personal data with vendors or agents working on our behalf for the
              purposes described in this statement. For example, companies we've hired to
              provide customer service support, to assist in protecting and securing our systems
              and services, or to perform sanctions screening and identity verification services
              may need access to personal data to provide those functions. The processing by
              such third parties shall be governed by a written contract with PayBolt to ensure
              adequate protection and security measures are put in place for the protection of
              personal data in accordance with the terms of this Privacy Policy.
            </p>
            <h3 className="text-xl font-semibold mb-2">Legal and law enforcement</h3>
            <p className="mb-4">
              We may access, disclose, and preserve personal data in accordance with applicable
              law and when we believe that doing so is necessary to comply with applicable law
              or respond to valid legal process, including from law enforcement or other
              government agencies.
            </p>
            <h3 className="text-xl font-semibold mb-2">Security, safety, and protecting rights</h3>
            <p className="mb-4">
              We will disclose personal data if we believe it is necessary to:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li className="mb-2">
                protect our merchants and others, for example to prevent fraud, or to help
                prevent the loss of life or serious injury of anyone
              </li>
              <li className="mb-2">
                operate and maintain the security of our services, including to prevent or
                stop an attack on our computer systems or networks; or
              </li>
              <li className="mb-2">
                protect the rights or property or ourselves or others, including enforcing our
                agreements, terms, and policies.
              </li>
            </ul>
            <p className="mb-4">
              Third party analytics and advertising companies also collect personal data through
              our website and apps including, marketing and communications data, demographic
              data, content and files, geolocation data, usage data, and inferences associated
              with identifiers and device information (such as cookie IDs, device IDs, and IP
              address) as described in the Cookies section of this statement.
            </p>
            <p className="mb-4">
              Please note that some of our services include integrations, references, or links
              to services provided by third parties whose privacy practices differ from ours. If
              you provide personal data to any of those third parties, or allow us to share
              personal data with them, that data is governed by their privacy statements.
            </p>
            <p className="mb-4">
              Finally, we may share de-identified information in accordance with applicable law.
            </p>
          </section>

          <section className="mt-8 max-w-3xl mx-auto">
            <h2 className="text-2xl font-bold mb-4">4. Cookies</h2>
            <p className="mb-4">
              We and our partners use cookies and similar technologies on our website to help
              collect information and operate the site.
            </p>
            <p className="mb-4">We use cookies to:</p>
            <ul className="list-disc pl-6 mb-4">
              <li className="mb-2">
                remember visitors to our website; make your user experience easier; customise
                our services, content and advertising;
              </li>
              <li className="mb-2">
                help you ensure that your account security is not compromised, mitigate risk
                and prevent fraud;
              </li>
              <li className="mb-2">
                and to promote trust and safety
              </li>
            </ul>
            <p className="mb-4">
              Cookies are small text files placed by a website and stored by your browser on
              your browser.
            </p>
            <p className="mb-4">
              Our cookies hold a unique random reference to you so that once you visit the site
              we can recognise who you are and provide certain services.
            </p>
            <p className="mb-4">
              Most web browsers are set to accept cookies by default. If you prefer, you can go
              to your browser settings to learn how to delete or reject cookies. If you choose
              to delete or reject cookies, this may impact your experience using our website.
            </p>
          </section>

          <section className="mt-8 max-w-3xl mx-auto">
            <h2 className="text-2xl font-bold mb-4">5. How we protect your information</h2>
            <p className="mb-4">
              PayBolt has established adequate technical and organisational controls to protect
              the integrity and confidentiality of your Personal Information, both in digital
              and physical format, whilst preventing Personal Information from being
              accidentally or deliberately compromised.
            </p>
            <p className="mb-4">
              PayBolt is committed to managing your Personal Information in line with best
              practices. We protect your Personal Information using physical, technical, and
              administrative security measures to reduce the risks of loss, misuse, unauthorised
              access, disclosure and alteration, we also use industry recommended security
              protocols to safeguard your Personal Information. Other security safeguards
              include but are not limited to data encryption, firewalls, and physical access
              controls to our building and files and only granting access to Personal
              Information to only employees who require it to fulfil their job responsibilities.
            </p>
            <p className="mb-4">
              PayBolt also maintains a data breach procedure in order to deal with incidents
              concerning Personal Information or practices leading to the accidental or unlawful
              destruction, loss, alteration, unauthorised disclosure of, or access to, Personal
              Information transmitted, stored or otherwise processed. You may contact our us
              upon becoming aware of any breach of Personal Information or if your access
              credentials have been compromised, to enable us to take the necessary steps
              towards ensuring the security of your Personal Information or account. We will
              report any breaches that will compromise your rights and freedoms to the Relevant
              Authority within a short time of discovery.
            </p>
          </section>

          <section className="mt-8 max-w-3xl mx-auto">
            <h2 className="text-2xl font-bold mb-4">6. Storage Limitation</h2>
            <p className="mb-4">We will retain your information for the following periods:</p>
            <ul className="list-disc pl-6 mb-4">
              <li className="mb-2">
                As long as reasonably necessary for the purpose of providing our services to
                you
              </li>
              <li className="mb-2">
                For the duration your account is active and we have your consent
              </li>
              <li className="mb-2">
                For the period needed to comply with our legal and statutory obligations
              </li>
              <li className="mb-2">As needed to verify your information with a</li>
              <li className="mb-2">
                PayBolt is statutorily obliged to retain the data you provide in order to
                process transactions, ensure settlements, make refunds, identify fraud and to
                comply with applicable laws and regulatory guidelines.
              </li>
            </ul>
            <p className="mb-4">
              The length of storage of personal data shall, amongst other things, be determined
              by:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li className="mb-2">
                The contract terms agreed between PayBolt and the Merchant or as long as it is
                needed for the purpose for which it was obtained; or
              </li>
              <li className="mb-2">
                Whether the transaction or relationship has statutory implication or a
                required retention period; or
              </li>
              <li className="mb-2">
                Whether there is an express request for deletion of Personal Data by the
                Merchant, provided that such request will only be treated where the Data
                Subject is not under any investigation which may require PayBolt to retain
                such Personal Data or there is no subsisting contractual arrangement with the
                Data Subject that would require the processing of the Personal Data; or
              </li>
              <li className="mb-2">
                Whether PayBolt has another lawful basis for retaining that information beyond
                the period for which it is necessary to serve the original purpose.
              </li>
            </ul>
          </section>

          <section className="mt-8 max-w-3xl mx-auto">
            <h2 className="text-2xl font-bold mb-4">7. Transfer of Personal Data</h2>
            <p className="mb-4">
              As part of our service provision, we may rely on third-party servers, databases
              co-located with hosting providers, resident in foreign jurisdictions, which
              constitutes the transfer of your personal data to computers or servers in foreign
              countries. We take steps designed to ensure that the data we collect under this
              Privacy Policy is processed and protected according to the provisions of this
              Policy and applicable law wherever the data is located.
            </p>
            <p className="mb-4">
              Should you wish to transfer personal data to a country deemed to have inadequate
              data protection laws, PayBolt will take all necessary steps to ensure that
              informed consent is obtained from you, and you are aware of the risks entailed
              with such transfer. In any instance, PayBolt will ensure Personal Information is
              transmitted in a safe and secure manner. Details of the protection given when your
              Personal Information is transferred abroad, and details of the basis of such
              transfers shall be provided to you upon request.
            </p>
          </section>

          <section className="mt-8 max-w-3xl mx-auto">
            <h2 className="text-2xl font-bold mb-4">8. Grounds for Processing of Personal Information</h2>
            <p className="mb-4">
              Processing of Personal Information by PayBolt shall be lawful if at least one of
              the following applies:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li className="mb-2">
                The Data Subject (Merchant or Merchant representative) has given consent to
                the processing of his/her Personal Information for one or more specific
                purposes;
              </li>
              <li className="mb-2">
                The processing is necessary for the performance of a contract to which the
                Data Subject is party or in order to take steps at the request of the Data
                Subject prior to entering into a contract;
              </li>
              <li className="mb-2">
                Processing is necessary for compliance with a legal obligation to which
                PayBolt is subject;
              </li>
              <li className="mb-2">
                Processing is necessary in order to protect the vital interests of the Data
                Subject or of another natural person; and
              </li>
              <li className="mb-2">
                Processing is necessary for the performance of a task carried out in the
                public interest or in exercise of official public mandate vested in PayBolt
              </li>
            </ul>
          </section>

          <section className="mt-8 max-w-3xl mx-auto">
            <h2 className="text-2xl font-bold mb-4">9. Choices and Rights</h2>
            <p className="mb-4">
              Merchants with Personal Information held by PayBolt are entitled to the following
              rights:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li className="mb-2">
                Right to request for and access any Personal Information collected and stored
                by PayBolt;
              </li>
              <li className="mb-2">
                Right to be informed regarding their Personal Information;
              </li>
              <li className="mb-2">
                Right to object to automated decision making and processing;
              </li>
              <li className="mb-2">
                Right to request rectification and modification of Personal Information which
                PayBolt keeps;
              </li>
              <li className="mb-2">
                Right to request the deletion of their Personal Information;
              </li>
              <li className="mb-2">
                Right to request the movement of data from PayBolt to a third party - this is
                the right to the portability of Personal Information;
              </li>
              <li className="mb-2">Right to revoke consent;</li>
              <li className="mb-2">
                Right to object to direct marketing, and to request that PayBolt restricts the
                processing of their information.
              </li>
            </ul>
            <p className="mb-4">
              Your request will be reviewed and answered by PayBolt's Data Protection
              Officer within a 30-day period. You may review your account settings and update
              your Personal Information directly or by contacting us.
            </p>
          </section>

          <section className="mt-8 max-w-3xl mx-auto">
            <h2 className="text-2xl font-bold mb-4">10. Policy Violations</h2>
            <p className="mb-4">
              You certify that the information provided to register as a Merchant is correct to
              the best of your knowledge. Furthermore, when providing the personal data of any
              other person, you confirm that you are only providing accurate and up-to-date data
              in accordance with their instructions, and are able to provide evidence of their
              consent to the data processing described in this Policy as and when required by
              PayBolt. Please note that any attempt to mislead may result in prosecution and the
              deliberate provision of inaccurate data results in a privacy violation
            </p>
          </section>

          <section className="mt-8 max-w-3xl mx-auto">
            <h2 className="text-2xl font-bold mb-4">11. Changes to this Privacy Policy</h2>
            <p className="mb-4">
              We may need to update, modify or amend our Privacy Policy as our technology
              evolves and as required by law. If we materially change the ways in which we use
              or share personal data previously collected from you through our Services, we will
              provide notice or obtain consent regarding such changes as may be required by law.
              The Privacy Policy will apply from the effective date provided on our website.
            </p>
          </section>
          </Container>
          </Section>
        </Layout>
    );
}

export default PrivacyPolicy;
