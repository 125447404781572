import { createSlice } from '@reduxjs/toolkit'
import { ConnectionType } from '../../connection'
import { DEFAULT_DEADLINE_FROM_NOW } from '../../constants/misc'

const currentTimestamp = () => new Date().getTime()

const initialState = {
  bitcoinWallet: {
    accounts: [], 
    account: undefined, 
    accountPublicKey: undefined, 
    ordinal: undefined,
    ordinalPublicKey: undefined
  },
  solanaWallet: {
    accounts: [], 
    account: undefined, 
    accountPublicKey: undefined, 
  },
  cognitoUsername: undefined, // aws cognito object
  galleryRefreshCount: 0,
  selectedWallet: undefined,
  selectedNetwork: undefined,
  userDarkMode: true,
  partyMode: true,
  // deadline set by user in minutes, used in all txns
  userDeadline: DEFAULT_DEADLINE_FROM_NOW,
  matchesDarkMode: false,
  timestamp: currentTimestamp(),
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    increaseGalleryRefreshCount(state, action) {
      state.galleryRefreshCount = state.galleryRefreshCount + 1;
    },
    resetGalleryRefreshCount(state, action) {
      state.galleryRefreshCount = 0;
    },
    updateSelectedNetwork(state, action) {
      state.selectedNetwork = action.payload.network
    },
    updateSelectedWallet(state, { payload: { wallet } }) {
      state.selectedWallet = wallet
    },
    updateBitcoinWallet(state, action) {
      state.bitcoinWallet = {
        accounts: action.payload.accounts ? action.payload.accounts : [],
        account: action.payload.account ? action.payload.account : undefined,
        accountPublicKey: action.payload.accountPublicKey ? action.payload.accountPublicKey : undefined,
        ordinal: action.payload.ordinal ? action.payload.ordinal : undefined,
        ordinalPublicKey: action.payload.ordinalPublicKey ? action.payload.ordinalPublicKey : undefined
      }
    },
    disconnectBitcoinWallet(state, action) {
      state.bitcoinWallet = {
        accounts: [],
        account: undefined,
        accountPublicKey: undefined,
        ordinal: undefined,
        ordinalPublicKey: undefined
      }
    },
    updateSolanaWallet(state, action) {
      state.solanaWallet = {
        accounts: action.payload.accounts ? action.payload.accounts : [],
        account: action.payload.account ? action.payload.account : undefined,
        accountPublicKey: action.payload.accountPublicKey ? action.payload.accountPublicKey : undefined,
      }
    },
    disconnectSolanaWallet(state, action) {
      state.solanaWallet = {
        accounts: [],
        account: undefined,
        accountPublicKey: undefined,
      }
    },
    updateUserDarkMode(state, action) {
      state.userDarkMode = action.payload.userDarkMode
      state.timestamp = currentTimestamp()
    },
    updatePartyMode(state, action) {
      state.partyMode = action.payload.partyMode
      state.timestamp = currentTimestamp()
    },
    updateCognitoUsername(state, action) {
      state.cognitoUsername = action.payload.username
      state.timestamp = currentTimestamp()
    },
    updateMatchesDarkMode(state, action) {
      state.matchesDarkMode = action.payload.matchesDarkMode
      state.timestamp = currentTimestamp()
    },
    updateUserDeadline(state, action) {
      state.userDeadline = action.payload.userDeadline
      state.timestamp = currentTimestamp()
    },
  },
})

export const {
  increaseGalleryRefreshCount,
  updateSelectedWallet,
  updateSelectedNetwork,
  updateUserDarkMode,
  updatePartyMode,
  updateMatchesDarkMode,
  updateCognitoUsername,
  updateBitcoinWallet,
  disconnectBitcoinWallet,
  updateSolanaWallet,
  disconnectSolanaWallet,
} = userSlice.actions
export default userSlice.reducer
