import React from "react";
import {
  Award,
  Shield,
  Zap,
  Globe,
  Lock,
  BarChart2,
  MessageCircle,
  Sliders,
  AlertTriangle,
  Code,
  HelpCircle,
  DollarSign,
  Clock,
  Send,
  ShoppingCart,
  User,
  Layers,
  ArrowRight,
  Home,
  TrendingUp,
  Archive,
  Droplet,
  ArrowRightCircle,
} from "react-feather";

import { Accordion } from "../../components";
import { useIsDarkMode } from "../../state/user/hooks";

import Layout from "../../layout/website";
import Section from "../../layout/global/Section";
import Container from "../../layout/global/Container";

import PieChart from "../../assets/images/content-piechart.png";
import ContentImage02 from "../../assets/images/content-002.jpg";

const PayAIPage = () => {
  const darkMode = useIsDarkMode();

  const roadmapTimeline = [
    {
      quarter: "Q3 2024",
      deliveries: [
        {
          title: "Proposal Genesis",
          description:
            "Initiating the proposal genesis phase to outline and approve key project milestones and objectives.",
          completed: false,
        },
        {
          title: "CMC Listing",
          description:
            "Getting listed on CoinMarketCap to provide transparency and attract more investors.",
          completed: false,
        },
        {
          title: "CoinGecko Listing",
          description:
            "Listing on CoinGecko to increase visibility and market tracking.",
          completed: false,
        },
        {
          title: "CEX Listing",
          description:
            "Listing $PAYAI on major centralized exchanges to enhance accessibility and trading volume.",
          completed: false,
        },
        {
          title: "Resource Acquisition and Allocation for Core Team and Grants",
          description:
            "Securing resources and allocating grants to the core team to ensure efficient project development.",
          completed: false,
        },
        {
          title: "Launch of iOS and Android PayBolt AI App",
          description:
            "Releasing our user-friendly mobile app for both iOS and Android platforms.",
          completed: false,
        },
        {
          title: "Escrow Service",
          description:
            "Launching secure escrow services to ensure safe transactions with AI-driven disbursement.",
          completed: false,
        },
        {
          title: "Staking and Rewards Program",
          description:
            "Implementing a staking and rewards program to incentivize holding $PAYAI tokens.",
          completed: false,
        },
        {
          title: "Payment AI Analyzer",
          description:
            "Introducing our advanced AI analyzer to detect and prevent fraudulent transactions.",
          completed: false,
        },
        {
          title: "Partnerships and Integrations",
          description:
            "Building strategic partnerships and integrations to expand our ecosystem.",
          completed: false,
        },
      ],
    },
    {
      quarter: "Q4 2024",
      deliveries: [
        {
          title: "AI Compute Client",
          description:
            "Developing and launching the AI compute client to enable efficient processing and analysis of transactions.",
          completed: false,
        },
        {
          title: "Pledging and Credibility",
          description:
            "Launching the pledging and credibility module to allow users to pledge their tokens and enhance their credibility within the network.",
          completed: false,
        },
        {
          title: "Layer 1 Network Expansion",
          description:
            "Expanding the Layer 1 network to support increased transaction volume and enhance network performance.",
          completed: false,
        },
        {
          title: "Partnerships and Integrations",
          description:
            "Continuing to build strategic partnerships and integrations to strengthen the PayBolt AI ecosystem.",
          completed: false,
        },
        {
          title: "Cross-Chain Capability",
          description:
            "Introducing cross-chain capability to facilitate seamless transactions across different blockchain networks.",
          completed: false,
        },
      ],
    },
    {
      quarter: "Q1 2025",
      deliveries: [
        {
          title: "Availability Rewards",
          description:
            "Implementing availability rewards to incentivize network participants for maintaining high availability and reliability.",
          completed: false,
        },
        {
          title: "Multi-Currency Support",
          description:
            "Expanding our platform to support multiple cryptocurrencies for global transactions.",
          completed: false,
        },
        {
          title: "Continued Partnerships and Integrations",
          description:
            "Ongoing development of strategic partnerships and integrations to further expand our ecosystem.",
          completed: false,
        },
      ],
    },
  ];

  const faqs = [
    {
      question: "What is $PAYAI?",
      answer: (
        <>
          $PAYAI is the native token of the PayBolt AI ecosystem, providing various benefits and utilities for holders.
        </>
      ),
    },
    {
      question: "Has the PAYAI smart contract been audited?",
      answer: (
        <>
          Yes, it has been audited by Coinsult. You can view the details at <a href="https://coinsult.net/projects/paybolt-ai/" target="_blank">https://coinsult.net/projects/paybolt-ai/</a> and download the audit report from <a href="https://file.paybolt.com/PayBolt_AI_Audit.pdf" target="_blank">https://file.paybolt.com/PayBolt_AI_Audit.pdf</a>.
        </>
      ),
    },
    {
      question: "How can I buy $PAYAI?",
      answer: (
        <>
          You can buy $PAYAI through our official website or on supported cryptocurrency exchanges. Ensure to use only the links provided on our official channels to avoid scams.
        </>
      ),
    },
    {
      question: "What are the benefits of holding $PAYAI?",
      answer: (
        <>
          Holding $PAYAI provides access to exclusive features, staking rewards, participation in governance, and priority access to new features and services within the PayBolt AI ecosystem.
        </>
      ),
    },
    {
      question: "How secure is $PAYAI?",
      answer: (
        <>
          $PAYAI is secured by advanced AI-driven security measures, robust protocols, multi-signature wallets, and regular security audits to ensure the highest level of protection for holders.
        </>
      ),
    },
    {
      question: "How can I stake $PAYAI?",
      answer: (
        <>
        Staking $PAYAI is simple. Use our official staking platform available on the PayBolt AI website or mobile app. Choose your preferred staking tier and start earning rewards immediately.
        </>
      ),
    },
    {
      question: "What rewards can I earn by staking $PAYAI?",
      answer: (
        <>
        By staking $PAYAI, you can earn attractive annual rewards based on the tier you choose. Additional bonuses are available for participating in governance, referring new users, and contributing to the community.
        </>
      ),
    },
    {
      question: "How does PayBolt AI protect against scams and fraud?",
      answer: (
        <>
        PayBolt AI uses advanced AI-driven fraud detection systems, secure escrow services, and comprehensive security protocols to protect users against scams and fraudulent activities.
        </>
      ),
    },
    {
      question: "Can I participate in the governance of PayBolt AI?",
      answer: (
        <>
        Yes, as a $PAYAI holder, you can participate in the governance of PayBolt AI by voting on key proposals, contributing to community discussions, and helping shape the future direction of the ecosystem.
        </>
      ),
    },
    {
      question: "What is the future roadmap for $PAYAI?",
      answer: (
        <>
        The roadmap for $PAYAI includes listings on major exchanges, the launch of new features such as AI-driven escrow services and payment analyzers, expanding cross-chain capabilities, and developing strategic partnerships to grow the ecosystem.
        </>
      ),
    },
    {
      question: "How can I stay updated on the latest developments of $PAYAI?",
      answer: (
        <>
        Stay updated on the latest developments of $PAYAI by following our official social media channels, subscribing to our newsletter, and joining our community forums for real-time updates and announcements.
        </>
      ),
    },
  ];

  return (
    <Layout title="Introducing the $PAYAI Token | Powering AI-Secured Crypto Payments">
      <Section className="py-16 md:py-24 bg-gradient-to-br from-white dark:from-slate-950 to-blue-100 dark:to-blue-950 overflow-hidden">
        <Container>
          <div className="relative bg-gradient-to-b from-slate-100 to-white dark:from-slate-900 dark:to-slate-950 border border-b-0 border-gray-200 dark:border-gray-800 rounded-t-xl p-6 sm:p-10">
            <div className="max-w-3xl mx-auto text-center">
              <h1 className="text-[2.5rem] font-bold text-slate-800 dark:text-white">
                <span className="block text-2xl sm:text-3xl md:text-4xl lg:text-[2.75rem] xl:text-5xl !leading-snug text-slate-800 dark:text-white mb-6">
                  Introducing the <span className="text-blue-600">PAYAI</span>{" "}
                  Token
                  <br />
                  on the <span className="text-[#0251FF]">Base</span> Blockchain
                </span>
              </h1>
              <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl text-slate-700 dark:text-gray-300 mb-8">
                Discover the unparalleled benefits, utility, and future
                potential of holding $PAYAI. Built on the robust and scalable
                Base blockchain, $PAYAI offers enhanced security, faster
                transactions, and a myriad of opportunities for growth and
                innovation.
              </p>
              <p className="text-sm text-slate-700 dark:text-gray-300 mb-8">
                CA:&nbsp;
                <a style={{ wordBreak: 'break-all', overflowWrap: 'break-word' }} href="https://basescan.org/token/0x3b745514ae7a9b0e8dba516e90b4deafbd6d3512" target="_blank">0x3b745514ae7A9b0E8dBA516e90b4DeAFBd6d3512</a>
                <button 
                  className="ml-2 px-3 py-1 bg-gradient-to-r from-green-400 to-blue-500 text-white text-xs font-semibold rounded-full shadow-lg transform transition-transform duration-200 hover:scale-105 hover:shadow-xl"
                  onClick={() => window.open("https://coinsult.net/projects/paybolt-ai/", "_blank")}
                >
                  Audited
                </button>
              </p>
              <div className="relative flex items-center">
                <div style={{margin: "0 auto"}} className="bg-gradient-to-r from-blue-500 to-indigo-500 group relative shadow-2xl shadow-slate-900 rounded-full p-1 text-sm/6 font-semibold text-white inline-block">
                  <div className="relative z-10 rounded-full bg-white py-1 px-5 ring-1 ring-white/10 group-hover:bg-blue-600 group-hover:text-white transition duration-300">
                    <a target="_blank" className="text-blue-600 group-hover:text-white font-bold" href="https://app.uniswap.org/swap?outputCurrency=0x3b745514ae7A9b0E8dBA516e90b4DeAFBd6d3512&chain=base">
                      Purchase PAYAI on Base
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Section>

      {/* Why Hold $PAYAI */}
      <Section
        id="intro"
        className="pt-16 md:pt-20 lg:pt-24 xl:pt-28 pb-16 md:pb-20 lg:pb-24 xl:pb-28 bg-white dark:bg-slate-900 overflow-hidden"
      >
        <Container>
          <h2 className="text-center text-4xl font-bold leading-tight dark:text-white md:text-5xl transform">
            Why Hold $PAYAI
          </h2>
          <div className="pt-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 dark:text-gray-300">
            {/* Benefit 1 */}
            <div className="bg-gray-100 p-6 rounded-lg shadow-md dark:bg-gray-800">
              <div className="flex items-center mb-4">
                <div className="w-12 h-12 flex items-center justify-center bg-blue-600 text-white rounded-full">
                  <Shield size={20} />
                </div>
                <h3 className="ml-4 text-xl font-semibold text-gray-800 dark:text-white">
                  Enhanced Security
                </h3>
              </div>
              <p>
                Enjoy the peace of mind that comes with AI-driven security
                features designed to protect your assets. Our advanced
                algorithms constantly monitor and secure transactions, reducing
                the risk of fraud and unauthorized access.
              </p>
            </div>
            {/* Benefit 2 */}
            <div className="bg-gray-100 p-6 rounded-lg shadow-md dark:bg-gray-800">
              <div className="flex items-center mb-4">
                <div className="w-12 h-12 flex items-center justify-center bg-green-600 text-white rounded-full">
                  <BarChart2 size={20} />
                </div>
                <h3 className="ml-4 text-xl font-semibold text-gray-800 dark:text-white">
                  Staking Rewards
                </h3>
              </div>
              <p>
                Earn attractive rewards by staking your $PAYAI tokens. Our
                staking program offers competitive returns, incentivizing
                holders to actively participate in the network and benefit from
                long-term growth.
              </p>
            </div>
            {/* Benefit 3 */}
            <div className="bg-gray-100 p-6 rounded-lg shadow-md dark:bg-gray-800">
              <div className="flex items-center mb-4">
                <div className="w-12 h-12 flex items-center justify-center bg-purple-600 text-white rounded-full">
                  <Globe size={20} />
                </div>
                <h3 className="ml-4 text-xl font-semibold text-gray-800 dark:text-white">
                  Global Reach
                </h3>
              </div>
              <p>
                Join a global community of users and businesses leveraging
                $PAYAI for seamless, cross-border transactions. Our platform
                supports multiple currencies, making it easy to transact
                internationally without the hassle of currency conversion fees.
              </p>
            </div>
            {/* Benefit 4 */}
            <div className="bg-gray-100 p-6 rounded-lg shadow-md dark:bg-gray-800">
              <div className="flex items-center mb-4">
                <div className="w-12 h-12 flex items-center justify-center bg-yellow-600 text-white rounded-full">
                  <Sliders size={20} />
                </div>
                <h3 className="ml-4 text-xl font-semibold text-gray-800 dark:text-white">
                  Governance Participation
                </h3>
              </div>
              <p>
                Have a say in the future direction of the PayBolt AI ecosystem.
                $PAYAI holders can participate in governance decisions, voting
                on key proposals and helping shape the evolution of our
                platform.
              </p>
            </div>
            {/* Benefit 5 */}
            <div className="bg-gray-100 p-6 rounded-lg shadow-md dark:bg-gray-800">
              <div className="flex items-center mb-4">
                <div className="w-12 h-12 flex items-center justify-center bg-red-600 text-white rounded-full">
                  <DollarSign size={20} />
                </div>
                <h3 className="ml-4 text-xl font-semibold text-gray-800 dark:text-white">
                  Lower Transaction Fees
                </h3>
              </div>
              <p>
                Benefit from reduced transaction fees compared to traditional
                payment methods. $PAYAI transactions are cost-efficient,
                allowing you to save more on every transaction and reinvest
                those savings into your business or personal ventures.
              </p>
            </div>
            {/* Benefit 6 */}
            <div className="bg-gray-100 p-6 rounded-lg shadow-md dark:bg-gray-800">
              <div className="flex items-center mb-4">
                <div className="w-12 h-12 flex items-center justify-center bg-indigo-600 text-white rounded-full">
                  <Zap size={20} />
                </div>
                <h3 className="ml-4 text-xl font-semibold text-gray-800 dark:text-white">
                  Instant Transactions
                </h3>
              </div>
              <p>
                Experience lightning-fast transaction speeds with $PAYAI. Our
                platform ensures that your payments and transfers are processed
                in real-time, providing a seamless and efficient transaction
                experience.
              </p>
            </div>
          </div>
        </Container>
      </Section>

      {/* Tokenomics */}
      <Section
        id="tokenomics"
        className="pt-16 md:pt-20 lg:pt-24 xl:pt-28 pb-16 md:pb-20 lg:pb-24 xl:pb-28 bg-slate-50 dark:bg-slate-950 overflow-hidden"
      >
        <Container>
          <h2 className="text-center text-4xl font-bold leading-tight dark:text-white md:text-5xl transform">
            Tokenomics
          </h2>

          <p className="mt-6 max-w-4xl mx-auto text-gray-600 dark:text-gray-300 text-center">
            The PayBolt AI token allocation strategy is meticulously crafted to
            foster growth, ensure liquidity, and maintain long-term
            sustainability.
          </p>
          <div className="flex flex-wrap justify-center mt-6">
            <div className="w-full lg:w-1/2 px-4">
              <div className="relative">
                <img
                  src={PieChart}
                  alt=""
                  className="w-full rounded-2xl shadow-lg"
                />

                <div className="absolute inset-0 flex items-center justify-center"></div>
              </div>
            </div>
            <div className="w-full lg:w-1/2 px-4 relative">
              <div className="bg-white p-6 rounded-lg shadow-md dark:bg-gray-900 dark:text-gray-300">
                <p className="mb-4 text-lg font-semibold">
                  Total Supply: 10,000,000,000 PAYAI
                </p>

                <div className="space-y-6">
                  {/* AirDrop */}
                  <div className="flex items-start space-x-4">
                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-[#6CE5E8] text-white">
                      <Send size={20} />
                    </div>
                    <div className="flex-1">
                      <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
                        AirDrop: 100,000,000 (1%)
                      </h3>
                      <p className="text-gray-600 dark:text-gray-300">
                        Tokens allocated for airdrop campaigns to encourage
                        community engagement and reward early supporters.
                      </p>
                    </div>
                  </div>
                  {/* CEX */}
                  <div className="flex items-start space-x-4">
                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-[#41B8D5] text-white">
                      <TrendingUp size={20} />
                    </div>
                    <div className="flex-1">
                      <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
                        CEX Reserved: 1,000,000,000 (10%)
                      </h3>
                      <p className="text-gray-600 dark:text-gray-300">
                        Set aside for listing on centralized exchanges,
                        enhancing accessibility and boosting trading volume.
                      </p>
                    </div>
                  </div>
                  {/* Presale */}
                  <div className="flex items-start space-x-4">
                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-[#2D8BBA] text-white">
                      <DollarSign size={20} />
                    </div>
                    <div className="flex-1">
                      <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
                        Presale: 1,000,000,000 (10%)
                      </h3>
                      <p className="text-gray-600 dark:text-gray-300">
                        Allocated for presale events to raise initial funding
                        and increase token distribution among early adopters.
                      </p>
                    </div>
                  </div>
                  {/* Tokens for Liquidity */}
                  <div className="flex items-start space-x-4">
                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-[#2F5F98] text-white">
                      <Droplet size={20} />
                    </div>
                    <div className="flex-1">
                      <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
                        Tokens for Liquidity: 1,000,000,000 (10%)
                      </h3>
                      <p className="text-gray-600 dark:text-gray-300">
                        Ensuring smooth and efficient trading on various
                        platforms, providing the necessary liquidity to support
                        our growing ecosystem.
                      </p>
                    </div>
                  </div>
                  {/* Ecosystem */}
                  <div className="flex items-start space-x-4">
                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-[#31356E] text-white">
                      <Globe size={20} />
                    </div>
                    <div className="flex-1">
                      <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
                        Ecosystem: 1,400,000,000 (14%)
                      </h3>
                      <p className="text-gray-600 dark:text-gray-300">
                        Held for future strategic uses such as partnerships,
                        user acquisition, bonuses, and airdrops, ensuring we are
                        always ready to capitalize on new opportunities.
                      </p>
                    </div>
                  </div>
                  {/* Foundation */}
                  <div className="flex items-start space-x-4">
                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-[#000000] text-white">
                      <Home size={20} />
                    </div>
                    <div className="flex-1">
                      <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
                        Foundation: 1,500,000,000 (15%)
                      </h3>
                      <p className="text-gray-600 dark:text-gray-300">
                        Reserved for the continuous development, maintenance,
                        and operational expenses of the PayBolt AI ecosystem,
                        ensuring its long-term sustainability.
                      </p>
                    </div>
                  </div>
                  {/* Migration from PAY */}
                  <div className="flex items-start space-x-4">
                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-[#a6a6a6] text-white">
                      <ArrowRightCircle size={20} />
                    </div>
                    <div className="flex-1">
                      <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
                        Migration from PAY: 4,000,000,000 (40%)
                      </h3>
                      <p className="text-gray-600 dark:text-gray-300">
                        Seamlessly transitioning users from the PAY token to
                        PAYAI, ensuring they enjoy enhanced benefits and
                        security.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Section>

      {/* Roadmap */}
      <Section
        id="roadmap"
        className="pt-16 md:pt-20 lg:pt-24 xl:pt-28 pb-16 md:pb-20 lg:pb-24 xl:pb-28 bg-white dark:bg-slate-900 overflow-hidden"
      >
        <Container>
          <div className="mb-8 text-center">
            <h2 className="text-center text-4xl font-bold leading-tight dark:text-white md:text-5xl transform">
              Roadmap
            </h2>

            <p className="mt-4 text-lg text-gray-600 dark:text-gray-300">
              Our journey towards revolutionizing the crypto payment ecosystem.
            </p>
          </div>
          <div className="space-y-8">
            {roadmapTimeline.map((timeline, index) => (
              <div
                key={index}
                className="p-6 bg-gray-100 dark:bg-gray-800 rounded-lg shadow-md"
              >
                <h3 className="text-2xl font-semibold text-gray-800 dark:text-white mb-4">
                  {timeline.quarter}
                </h3>
                <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {timeline.deliveries.map((delivery, idx) => (
                    <li
                      key={idx}
                      className={`pl-4 border-l-4 ${
                        delivery.completed
                          ? "border-green-600 dark:border-green-400"
                          : "border-red-600 dark:border-red-400"
                      }`}
                    >
                      <h4 className="text-lg font-semibold text-gray-800 dark:text-white">
                        {delivery.title}
                      </h4>
                      <p className="text-gray-600 dark:text-gray-300">
                        {delivery.description}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </Container>
      </Section>

      {/* Benefits of $PAYAI */}
      <Section
        id="benefits"
        className="pt-16 md:pt-20 lg:pt-24 xl:pt-28 pb-16 md:pb-20 lg:pb-24 xl:pb-28 bg-slate-50 dark:bg-slate-950 overflow-hidden"
      >
        <Container className="max-w-screen-xl mx-auto px-4">
          <h2 className="text-4xl font-bold leading-tight dark:text-white md:text-5xl transform">
            Benefits of $PAYAI
          </h2>
          <p className="dark:text-gray-300 mb-6 pt-8">
            Discover the unique advantages and features that make $PAYAI a
            valuable asset for holders.
          </p>
          <div className="space-y-8">
            <div className="flex flex-col lg:flex-row lg:space-x-8">
              <div className="flex-shrink-0 w-full lg:w-1/2">
                <h3 className="text-2xl font-semibold dark:text-white mb-4">
                  Staking to Earn
                </h3>
                <p className="dark:text-gray-300">
                  Holders of $PAYAI can participate in our staking program to
                  earn attractive rewards. By staking your tokens, you not only
                  secure the network but also earn passive income, incentivizing
                  long-term holding.
                </p>
              </div>
              <div className="flex-shrink-0 w-full lg:w-1/2">
                <h3 className="text-2xl font-semibold dark:text-white mb-4">
                  Liquidity Provision
                </h3>
                <p className="dark:text-gray-300">
                  Provide liquidity on various decentralized exchanges (DEXs) to
                  earn transaction fees. As a liquidity provider, you contribute
                  to the stability and accessibility of $PAYAI while earning a
                  share of the trading fees.
                </p>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row lg:space-x-8">
              <div className="flex-shrink-0 w-full lg:w-1/2">
                <h3 className="text-2xl font-semibold dark:text-white mb-4">
                  Voting Rights
                </h3>
                <p className="dark:text-gray-300">
                  $PAYAI holders have the power to vote on key proposals and
                  decisions affecting the ecosystem. This democratic approach
                  ensures that the community has a say in the development and
                  direction of the PayBolt AI platform.
                </p>
              </div>
              <div className="flex-shrink-0 w-full lg:w-1/2">
                <h3 className="text-2xl font-semibold dark:text-white mb-4">
                  Exclusive Access to Features
                </h3>
                <p className="dark:text-gray-300">
                  Enjoy exclusive access to advanced features and tools on the
                  PayBolt AI platform. $PAYAI holders can unlock premium
                  services, gain early access to new products, and benefit from
                  tailored support.
                </p>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row lg:space-x-8">
              <div className="flex-shrink-0 w-full lg:w-1/2">
                <h3 className="text-2xl font-semibold dark:text-white mb-4">
                  Ecosystem Incentives
                </h3>
                <p className="dark:text-gray-300">
                  Participate in various incentive programs designed to reward
                  active community members. These incentives include bonuses for
                  referring new users, participating in platform activities, and
                  contributing to the ecosystem's growth.
                </p>
              </div>
              <div className="flex-shrink-0 w-full lg:w-1/2">
                <h3 className="text-2xl font-semibold dark:text-white mb-4">
                  Enhanced Security
                </h3>
                <p className="dark:text-gray-300">
                  Benefit from enhanced security features designed to protect
                  your $PAYAI holdings. Our advanced AI-driven security
                  measures, including multi-signature wallets and regular
                  contract audits, ensure your investments are safe.
                </p>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row lg:space-x-8">
              <div className="flex-shrink-0 w-full lg:w-1/2">
                <h3 className="text-2xl font-semibold dark:text-white mb-4">
                  Growth Potential
                </h3>
                <p className="dark:text-gray-300">
                  As the PayBolt AI ecosystem grows, so does the potential value
                  of $PAYAI. By holding $PAYAI, you stand to benefit from the
                  increasing adoption and utility of our platform, driving
                  demand and potential price appreciation.
                </p>
              </div>
              <div className="flex-shrink-0 w-full lg:w-1/2">
                <h3 className="text-2xl font-semibold dark:text-white mb-4">
                  Cross-Chain Compatibility
                </h3>
                <p className="dark:text-gray-300">
                  $PAYAI is designed to be compatible with multiple blockchain
                  networks, providing flexibility and ease of use across
                  different platforms. This cross-chain compatibility enhances
                  the token's utility and accessibility.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </Section>

      {/* Security and Trust */}
      <Section
        id="security-trust"
        className="pt-16 md:pt-20 lg:pt-24 xl:pt-28 pb-16 md:pb-20 lg:pb-24 xl:pb-28 bg-white dark:bg-slate-900 overflow-hidden"
      >
        <Container className="max-w-screen-xl mx-auto px-4">
          <h2 className="text-4xl font-bold leading-tight dark:text-white md:text-5xl transform">
            Security and Trust
          </h2>
          <p className="dark:text-gray-300 mb-6 pt-8">
            At PayBolt AI, we prioritize the security and trust of our $PAYAI
            token holders. Our comprehensive security measures and protocols
            ensure the highest level of protection for your investments.
          </p>
          <div className="space-y-8">
            <div className="flex flex-col lg:flex-row lg:space-x-8">
              <div className="flex-shrink-0 w-full lg:w-1/2 xl:w-1/3">
                <h3 className="text-2xl font-semibold dark:text-white mb-4">
                  Contract Audit
                </h3>
                <p className="dark:text-gray-300">
                  Our smart contracts undergo rigorous audits by top-tier
                  security firms to ensure they are free from vulnerabilities.
                  These audits are conducted regularly to maintain the integrity
                  and security of our contracts.
                </p>
              </div>
              <div className="flex-shrink-0 w-full lg:w-1/2 xl:w-1/3">
                <h3 className="text-2xl font-semibold dark:text-white mb-4">
                  Multi-Sig Wallet
                </h3>
                <p className="dark:text-gray-300">
                  We utilize multi-signature (multi-sig) wallets for managing
                  $PAYAI tokens, ensuring that multiple approvals are required
                  for any transaction. This adds an extra layer of security,
                  preventing unauthorized access and transactions.
                </p>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row lg:space-x-8">
              <div className="flex-shrink-0 w-full lg:w-1/2 xl:w-1/3">
                <h3 className="text-2xl font-semibold dark:text-white mb-4">
                  AI-Driven Security Features
                </h3>
                <p className="dark:text-gray-300">
                  Our AI-driven security features continuously monitor
                  transactions for suspicious activity. This proactive approach
                  helps to detect and prevent potential threats, safeguarding
                  your assets at all times.
                </p>
              </div>
              <div className="flex-shrink-0 w-full lg:w-1/2 xl:w-1/3">
                <h3 className="text-2xl font-semibold dark:text-white mb-4">
                  Regular Security Audits
                </h3>
                <p className="dark:text-gray-300">
                  In addition to initial audits, we conduct regular security
                  audits and updates to our protocols. This ensures that we are
                  always ahead of potential threats and maintaining the highest
                  standards of security for our token holders.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </Section>

      {/* Community and Governance */}
      <Section
        id="community-governance"
        className="pt-16 md:pt-20 lg:pt-24 xl:pt-28 pb-16 md:pb-20 lg:pb-24 xl:pb-28 bg-slate-50 dark:bg-slate-950 overflow-hidden"
      >
        <Container className="max-w-screen-xl mx-auto px-4">
          <h2 className="text-4xl font-bold leading-tight dark:text-white md:text-5xl transform">
            Community and Governance
          </h2>
          <p className="dark:text-gray-300 mb-6 pt-8">
            Discover how you can participate in the community and
            decision-making processes through our governance model.
          </p>
          <div className="space-y-8">
            <div className="flex flex-col lg:flex-row lg:space-x-8">
              <div className="w-full lg:w-1/2">
                <h3 className="text-2xl font-semibold dark:text-white mb-4">
                  Decentralized Governance
                </h3>
                <p className="dark:text-gray-300">
                  $PAYAI holders have the power to influence the direction of
                  the PayBolt AI ecosystem through a decentralized governance
                  model. By participating in governance proposals and voting,
                  you can help shape the future of our platform, ensuring it
                  aligns with the community’s interests and priorities.
                </p>
              </div>
              <div className="w-full lg:w-1/2">
                <h3 className="text-2xl font-semibold dark:text-white mb-4">
                  Proposal Submission
                </h3>
                <p className="dark:text-gray-300">
                  As a $PAYAI holder, you can submit proposals for new features,
                  upgrades, and changes to the ecosystem. This empowers you to
                  take an active role in the development and improvement of
                  PayBolt AI, fostering a collaborative and innovative
                  community.
                </p>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row lg:space-x-8">
              <div className="w-full lg:w-1/2">
                <h3 className="text-2xl font-semibold dark:text-white mb-4">
                  Voting Power
                </h3>
                <p className="dark:text-gray-300">
                  Your voting power is determined by the number of $PAYAI tokens
                  you hold. The more tokens you have, the greater your influence
                  in governance decisions. This ensures that committed and
                  invested community members have a significant say in the
                  future of the platform.
                </p>
              </div>
              <div className="w-full lg:w-1/2">
                <h3 className="text-2xl font-semibold dark:text-white mb-4">
                  Community Initiatives
                </h3>
                <p className="dark:text-gray-300">
                  Engage in various community initiatives designed to foster
                  collaboration and growth. Participate in hackathons,
                  workshops, and meetups to connect with other $PAYAI holders
                  and contribute to the development of innovative solutions and
                  applications.
                </p>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row lg:space-x-8">
              <div className="w-full lg:w-1/2">
                <h3 className="text-2xl font-semibold dark:text-white mb-4">
                  Transparency and Accountability
                </h3>
                <p className="dark:text-gray-300">
                  Our governance model emphasizes transparency and
                  accountability. All proposals, votes, and decisions are
                  recorded on the blockchain, ensuring that the governance
                  process is open and verifiable by all community members. This
                  builds trust and ensures that decisions are made in the best
                  interest of the ecosystem.
                </p>
              </div>
              <div className="w-full lg:w-1/2">
                <h3 className="text-2xl font-semibold dark:text-white mb-4">
                  Incentives for Participation
                </h3>
                <p className="dark:text-gray-300">
                  To encourage active participation, we offer incentives for
                  community members who engage in governance activities. Earn
                  rewards for submitting proposals, voting, and participating in
                  community initiatives, making your involvement both impactful
                  and rewarding.
                </p>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row lg:space-x-8">
              <div className="w-full lg:w-1/2">
                <h3 className="text-2xl font-semibold dark:text-white mb-4">
                  Community Support
                </h3>
                <p className="dark:text-gray-300">
                  Join our active community forums and social media channels to
                  connect with other $PAYAI holders. Share ideas, discuss
                  proposals, and collaborate on projects to build a stronger,
                  more engaged community that drives the success of the PayBolt
                  AI ecosystem.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </Section>

      {/* Staking and Rewards */}
      <Section
        id="staking-rewards"
        className="pt-16 md:pt-20 lg:pt-24 xl:pt-28 pb-16 md:pb-20 lg:pb-24 xl:pb-28 bg-white dark:bg-slate-900 overflow-hidden"
      >
        <Container>
          <h2 className="text-4xl font-bold leading-tight dark:text-white md:text-5xl transform">
            Staking and Rewards
          </h2>
          <p className="dark:text-gray-300 mb-6 pt-8">
            Learn about the staking options available for $PAYAI holders and the
            rewards they can earn.
          </p>

          <div className="space-y-8">
            <div className="bg-gray-100 dark:bg-gray-800 p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-semibold dark:text-white mb-4">
                Flexible Staking Options
              </h3>
              <p className="dark:text-gray-300">
                $PAYAI offers a variety of staking options to suit different
                investment strategies and risk profiles. Choose from flexible
                staking periods to maximize your rewards.
              </p>
            </div>

            <div className="bg-gray-100 dark:bg-gray-800 p-6 rounded-lg shadow-md flex flex-wrap">
              <div className="w-full lg:w-1/2 px-4">
                <h3 className="text-2xl font-semibold dark:text-white mb-4">
                  Staking Tiers and Rewards
                </h3>
                <p className="dark:text-gray-300 mb-4">
                  Our staking program is designed to reward long-term holders
                  with attractive incentives. The more you stake, the higher
                  your rewards. Explore our staking tiers:
                </p>
              </div>
              <div className="w-full lg:w-1/2 px-4">
                <div className="space-y-4">
                  <div className="p-4 bg-yellow-200 dark:bg-yellow-600 rounded-md">
                    <h4 className="text-lg font-semibold dark:text-white">
                      Bronze Tier
                    </h4>
                    <p className="dark:text-gray-300">
                      Stake 300,000 - 2,999,999 $PAYAI to earn up to 10% annual
                      rewards.
                    </p>
                  </div>
                  <div className="p-4 bg-gray-300 dark:bg-gray-500 rounded-md">
                    <h4 className="text-lg font-semibold dark:text-white">
                      Silver Tier
                    </h4>
                    <p className="dark:text-gray-300">
                      Stake 3,000,000 - 5,999,999 $PAYAI to earn up to 12%
                      annual rewards.
                    </p>
                  </div>
                  <div className="p-4 bg-yellow-400 dark:bg-yellow-700 rounded-md">
                    <h4 className="text-lg font-semibold dark:text-white">
                      Gold Tier
                    </h4>
                    <p className="dark:text-gray-300">
                      Stake 6,000,000 - 11,999,999 $PAYAI to earn up to 16%
                      annual rewards.
                    </p>
                  </div>
                  <div className="p-4 bg-blue-500 dark:bg-blue-700 rounded-md">
                    <h4 className="text-lg font-semibold dark:text-white">
                      Platinum Tier
                    </h4>
                    <p className="dark:text-gray-300">
                      Stake 12,000,000+ $PAYAI to earn up to 20% annual rewards.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap -mx-4">
              <div className="w-full lg:w-1/2 px-4">
                <div className="bg-gray-100 dark:bg-gray-800 p-6 rounded-lg shadow-md h-full">
                  <h3 className="text-2xl font-semibold dark:text-white mb-4">
                    Bonus Rewards
                  </h3>
                  <p className="dark:text-gray-300">
                    In addition to the base rewards, $PAYAI holders can earn
                    bonus rewards through various activities such as
                    participating in governance votes, referring new users, and
                    completing specific tasks or milestones within the
                    ecosystem.
                  </p>
                </div>
              </div>
              <div className="w-full lg:w-1/2 px-4">
                <div className="bg-gray-100 dark:bg-gray-800 p-6 rounded-lg shadow-md h-full">
                  <h3 className="text-2xl font-semibold dark:text-white mb-4">
                    Security and Transparency
                  </h3>
                  <p className="dark:text-gray-300">
                    Our staking platform is built with top-tier security
                    measures and transparency in mind. All staking contracts are
                    audited and visible on the blockchain, ensuring your funds
                    are safe and the reward mechanisms are fair.
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap -mx-4">
              <div className="w-full lg:w-1/2 px-4">
                <div className="bg-gray-100 dark:bg-gray-800 p-6 rounded-lg shadow-md h-full">
                  <h3 className="text-2xl font-semibold dark:text-white mb-4">
                    Auto-Compounding
                  </h3>
                  <p className="dark:text-gray-300">
                    Our staking program offers auto-compounding features,
                    allowing you to automatically reinvest your rewards to
                    maximize your earnings over time without the need for manual
                    intervention.
                  </p>
                </div>
              </div>
              <div className="w-full lg:w-1/2 px-4">
                <div className="bg-gray-100 dark:bg-gray-800 p-6 rounded-lg shadow-md h-full">
                  <h3 className="text-2xl font-semibold dark:text-white mb-4">
                    Community Incentives
                  </h3>
                  <p className="dark:text-gray-300">
                    We believe in rewarding active community members. Earn
                    additional rewards by contributing to our community forums,
                    participating in events, and helping to grow the PayBolt AI
                    ecosystem.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Section>

      {/* FAQs */}
      <Section
        id="faqs"
        className="pt-16 md:pt-20 lg:pt-24 xl:pt-28 pb-16 md:pb-20 lg:pb-24 xl:pb-28 bg-slate-50 dark:bg-slate-950 overflow-hidden"
      >
        <Container>
          <div className="flex flex-wrap items-center justify-center pb-8 lg:pb-10">
            <div className="sm:w-2/3 md:w-3/5 lg:w-2/5 text-center mx-auto">
              <h2 className="text-center text-4xl font-bold leading-tight dark:text-white md:text-5xl transform">
                Got Questions? Let's Answer Them!
              </h2>
            </div>
          </div>
          <div className="flex flex-wrap justify-center -m-3 md:-m-4">
            <div className="w-full xl:w-3/4 p-3 md:p-4">
              <Accordion className="flex flex-col gap-3">
                {faqs.map((faq, index) => (
                  <Accordion.Item
                    key={index}
                    size="lg"
                    className="bg-white dark:bg-slate-950 border border-slate-100 dark:border-slate-950 shadow rounded-2xl"
                    header={faq.question}
                    initialEntered={index == 0}
                  >
                    <div className="max-w-3xl">
                      <p className="text-base/7">{faq.answer}</p>
                    </div>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        </Container>
      </Section>

      {/* CTA */}
      <Section className="pt-16 md:pt-20 lg:pt-24 xl:pt-28 bg-white dark:bg-slate-900 overflow-hidden">
        <Container>
          <div className="relative bg-gradient-to-b from-slate-100 to-white dark:from-slate-900 dark:to-slate-950 border border-b-0 border-gray-200 dark:border-gray-800 rounded-t-xl p-6 sm:p-10">
            <div className="max-w-3xl mx-auto text-center">
              <h1 className="text-[2.5rem] font-bold text-slate-800 dark:text-white">
                <span className="block text-2xl sm:text-3xl md:text-4xl lg:text-[2.75rem] xl:text-5xl !leading-snug text-slate-800 dark:text-white mb-6">
                  Join the AI Payment Revolution with{" "}
                  <span className="text-blue-600">PAYAI</span>!
                </span>
              </h1>
              <div className="flex justify-center flex-wrap gap-3 sm:gap-5 sm:pt-2">
                <div className="relative">
                  <a
                    className="peer inline-flex font-medium text-sm bg-blue-600 text-white hover:bg-blue-800 transition-all px-5 py-2 rounded-full"
                    href="#"
                  >
                    Launching Soon on Uniswap
                  </a>
                  <div className="absolute bottom-full left-1/2 -translate-x-1/2 -translate-y-3 w-52 p-2 mb-2 bg-white dark:bg-slate-950 rounded-md shadow-md shadow-orange-100 dark:shadow-orange-950 opacity-0 peer-hover:opacity-100 peer-hover:translate-y-0 transition-all duration-500 invisible peer-hover:visible">
                    <img
                      className="rounded-md border border-slate-100"
                      src={ContentImage02}
                      alt="Uniswap"
                    />
                    <h6 className="text-base text-slate-600 dark:text-slate-200 font-bold pt-4 pb-2 z-10 relative">
                      Launching Soon on Uniswap
                    </h6>
                  </div>
                </div>

                {false && (
                  <div className="relative">
                    <a
                      className="peer inline-flex font-medium text-sm bg-green-600 text-white hover:bg-green-800 transition-all px-5 py-2 rounded-full"
                      href="https://paybolt.ai/join"
                    >
                      Join the Community
                    </a>
                    <div className="absolute bottom-full left-1/2 -translate-x-1/2 -translate-y-3 w-52 p-2 mb-2 bg-white dark:bg-slate-950 rounded-md shadow-md shadow-green-100 dark:shadow-green-950 opacity-0 peer-hover:opacity-100 peer-hover:translate-y-0 transition-all duration-500 invisible peer-hover:visible">
                      <img
                        className="rounded-md border border-slate-100"
                        src="/images/preview/admin/dashboard.png"
                        alt="Community"
                      />
                      <h6 className="text-base text-slate-600 dark:text-slate-200 font-bold pt-4 pb-2 z-10 relative">
                        Join the AI Payment Community
                      </h6>
                    </div>
                  </div>
                )}

                {false && (
                  <div className="relative">
                    <a
                      href="https://paybolt.ai"
                      target="_blank"
                      className="peer inline-flex font-medium text-sm bg-blue-600 text-white hover:bg-blue-800 transition-all px-5 py-2 rounded-full"
                    >
                      Learn More
                    </a>
                    <div className="absolute bottom-full left-1/2 -translate-x-1/2 -translate-y-3 w-52 p-4 mb-2 bg-white dark:bg-slate-950 rounded-md shadow-md shadow-blue-100 dark:shadow-blue-950 opacity-0 peer-hover:opacity-100 peer-hover:translate-y-0 transition-all duration-300 invisible peer-hover:visible">
                      <h6 className="text-base text-slate-600 dark:text-slate-200 font-bold z-10 relative">
                        Explore the Future of Payments with{" "}
                        <span className="text-emerald-500">$PAYAI</span>
                      </h6>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </Layout>
  );
};

export default PayAIPage;
