import { useMemo } from 'react';
import { AlertTriangle, CheckCircle } from 'react-feather';
import styled, { useTheme } from 'styled-components/macro';

import { colors } from '../../theme/colors';
import { useCustomWeb3React } from '../../providers/CustomWeb3ReactProvider';

import { getChainInfoOrDefault } from '../../constants/chainInfo';
import { SupportedChainId } from '../../constants/chains';
import Loader from '../Loader';
import TransactionBody from './TransactionBody';

import PayaiLogo from "../../assets/icons/icon.png";

export const TransactionState = {
  Pending: 0,
  Success: 1,
  Failed: 2,
};

const Grid = styled.a(({ theme, isLastTransactionInList }) => `
  cursor: pointer;
  display: grid;
  grid-template-columns: 44px auto 24px;
  width: 100%;
  text-decoration: none;
  border-bottom: ${isLastTransactionInList ? 'none' : `1px solid ${theme.backgroundOutline}`};
  padding: 12px;

  &:hover {
    background-color: ${theme.backgroundModule};
    transition: 250ms background-color ease;
  }
`);

const TextContainer = styled.span`
  font-size: 14px;
  margin-top: auto;
  margin-bottom: auto;
  color: ${({ theme }) => theme.textTertiary};
`;

const IconStyleWrap = styled.span`
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  height: 16px;
`;

const TransactionSummary = ({
  transactionDetails,
  isLastTransactionInList = false,
}) => {
  const { chainId = 1 } = useCustomWeb3React();

  const theme = useTheme()

  const tx = transactionDetails;
  const { explorer } = getChainInfoOrDefault(chainId ? chainId : SupportedChainId.MAINNET);
  const { info, receipt, hash } = tx;

  const transactionState = useMemo(() => {
    const pending = !receipt;
    const success = !pending && tx && (receipt?.status === 1 || typeof receipt?.status === 'undefined');
    const transactionState = pending
      ? TransactionState.Pending
      : success
      ? TransactionState.Success
      : TransactionState.Failed;

    return transactionState;
  }, [receipt, tx]);

  const link = `${explorer}tx/${hash}`;

  return chainId ? (
    <Grid href={link} rel="noreferrer" target="_blank" isLastTransactionInList={isLastTransactionInList}>
      <div><img src={PayaiLogo} /></div>
      <TextContainer as="span">
        <TransactionBody info={info} transactionState={transactionState} />
      </TextContainer>
      {transactionState === TransactionState.Pending ? (
        <IconStyleWrap>
          <Loader />
        </IconStyleWrap>
      ) : transactionState === TransactionState.Success ? (
        <IconStyleWrap>
          <CheckCircle color={colors.green200} size="16px" />
        </IconStyleWrap>
      ) : (
        <IconStyleWrap>
          <AlertTriangle color={colors.gold200} size="16px" />
        </IconStyleWrap>
      )}
    </Grid>
  ) : null;
};

export default TransactionSummary;
