import React from "react";
import { Link } from "react-router-dom";
import { Sun, Moon, Menu as MenuIcon } from "react-feather";

import Logo from "../../../assets/images/logo.png";
import WhiteLogo from "../../../assets/images/logo-white.png";

import ChainSelector from "../../../components/ChainSelector";
import Web3Status from "../../../components/Web3Status";
import { useDarkModeManager } from "../../../state/user/hooks";

function Header({sidebarVisibility, setSidebarVisibility}) {
    const [ darkMode, updateIsDarkMode ] = useDarkModeManager()

    return (
        <div className="isolate fixed top-0 start-0 2xl:start-64 w-full 2xl:w-[calc(100%-theme(space.64))] py-4 border-b border-slate-200 dark:border-slate-800 bg-white dark:bg-slate-950 z-[1010]">
            <div className="flex items-center px-6">
                <div className="flex items-center gap-x-2 2xl:hidden -ms-1 mr-4">
                    <button
                        onClick={()=>{
                            setSidebarVisibility(!sidebarVisibility)
                        }}
                        className="inline-flex items-center justify-center h-8 w-8 rounded-full overflow-hidden transition-all text-slate-400 hover:bg-slate-200 hover:dark:bg-slate-800 hover:text-slate-600 hover:dark:text-slate-200 ui-open:bg-slate-200 ui-open:dark:bg-slate-800 ui-open:text-slate-600 ui-open:dark:text-slate-200"
                    >
                        <MenuIcon className="h-5" />
                    </button>
                    <Link to="/app">
                            {darkMode ? (
                                <img src={WhiteLogo} />
                            ) : (
                                <img src={Logo} />
                            )}
                    </Link>
                </div>
                <div className="hidden xl:flex flex-grow items-center ms-8 me-8 2xl:ms-0">
                    
                </div>
                <ul className="flex items-center gap-x-2 ms-auto">
                    <li className="inline-flex relative hidden 2xl:block">
                        <button
                            onClick={()=>{
                                updateIsDarkMode(!darkMode)
                            }}
                            className={`inline-flex items-center justify-center h-8 w-8 rounded-full overflow-hidden transition-all text-slate-400 hover:text-slate-600 hover:bg-slate-200 dark:text-slate-300 dark:bg-slate-800`}>
                            {darkMode ? <Moon className="h-4" /> : <Sun className="h-5" />}
                        </button>
                    </li>
                    <li className="inline-flex relative">
                        <ChainSelector className="chainSelectorButton" />
                      </li>
                    <li className="inline-flex relative">
                        <Web3Status className="connectButton" />
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Header;
