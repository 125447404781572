import React, { useState, useEffect, useRef, Suspense, lazy } from "react";
import { Link } from "react-router-dom";
import { AlertTriangle } from "react-feather";

import Layout from "../../../layout/dashboard";
import Section from "../../../layout/global/Section";
import Container from "../../../layout/global/Container";
import { Breadcrumbs, Button, Card } from "../../../components";
import { formatBigNumber } from "../../../utils/currency";

import { useToggleWalletModal } from "../../../state/application/hooks";
import { useCustomWeb3React } from "../../../providers/CustomWeb3ReactProvider";

const ReCAPTCHA = lazy(() => import('react-google-recaptcha'));

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY
if (typeof RECAPTCHA_SITE_KEY === 'undefined') {
  throw new Error(`REACT_APP_RECAPTCHA_SITE_KEY must be a defined environment variable`)
}

// RotatingText Component
const RotatingText = ({ messages, interval = 2000 }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, interval);

    return () => clearInterval(intervalId);
  }, [messages, interval]);

  return <div className="mt-4 text-center">{messages[index]}</div>;
};

// LoadingSpinner Component
const LoadingSpinner = () => (
  <div className="flex flex-col items-center justify-center h-[200px]">
    <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
    <RotatingText
      messages={[
        "Scanning PAY token...",
        "Scanning BNBPay token...",
        "Scanning Staking Profile...",
      ]}
      interval={2000} // Adjust the interval as needed
    />
  </div>
);

function Home() {
  const { account, chainId } = useCustomWeb3React();
  const toggleWalletModal = useToggleWalletModal();
  const recaptchaRef = useRef();

  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [bnbpayStatus, setBnbpayStatus] = useState("-");
  const [payStatus, setPayStatus] = useState("-");
  const [migrationData, setMigrationData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    const fetchMigrationData = async () => {
      setMigrationData(null);
      setLoading(true);
      try {
        const response = await fetch(
          "https://api.paybolt.com/migration/eligibility",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({ address: account }),
          },
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setMigrationData(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (account) {
      fetchMigrationData();
    } else {
      setMigrationData(null);
    }
  }, [account]);

  useEffect(() => {
    let intervalId;

    const fetchMigrationStatus = async () => {
      try {
        const response = await fetch(
          "https://api.paybolt.com/migration/status",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({ address: account }),
          },
        );

        const data = await response.json();
        return data.status; // Return the status
      } catch (error) {
        return null; // Return null in case of error
      }
    };

    if (isSubmitted) {
      intervalId = setInterval(async () => {
        const migrationStatus = await fetchMigrationStatus();
        // Assuming fetchMigrationStatus returns a status or something to check
        if (migrationStatus === 'completed') {
          setMigrationData(prevData => ({ ...prevData, status: migrationStatus })); // Update the status
          clearInterval(intervalId); // Stop polling if the migration is completed or there's an error
        }
      }, 10000);
    }

    // Cleanup function to clear the interval
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isSubmitted, account]); // Dependencies to rerun the effect if these change

  const submitNow = async () => {
    if (!recaptchaToken) {
      alert('Please complete the reCAPTCHA');
      return;
    }

    if (!account) {
      alert('Please connect your wallet');
    } else {
      setSubmitLoading(true);

      const formData = {
        address: account,
        recaptchaToken,
      };

      try {
        const response = await fetch('https://api.paybolt.com/migration/confirm', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        const result = await response.json();
        if (response.ok) {
          // Handle success
          alert('Submission successful!');

          setMigrationData(prevData => ({ ...prevData, status: "processing" })); // Update the status
          setIsSubmitted(true)
        } else {
          // Handle errors
          alert(result.error || 'An error occurred');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('An error occurred');
      } finally {
        setSubmitLoading(false);
      }
    }
  };

  return (
    <Layout title="Seamless Migration to $PAYAI">
      <Section className="py-10 px-3">
        <Container>
          <div className="mb-2 flex justify-between items-center -mx-3">
            <div className="px-3">
              <h1 className="text-2xl font-bold text-slate-700 dark:text-white mb-2">
                Seamless Migration to $PAYAI
              </h1>
              <Breadcrumbs
                items={[
                  { text: "Home", link: "/app" },
                  { text: "Migration", link: "" },
                ]}
              />
            </div>
            <div className="px-3"></div>
          </div>

          <div className="flex flex-wrap -m-3 mt-2">
            <div className="w-full lg:w-1/2 p-3">
              <div className="flex items-center px-4 py-3 bg-yellow-50 rounded-md">
                <div className="bg-yellow-400 p-2 rounded-full text-white">
                  <AlertTriangle className="h-4 w-4" />
                </div>
                <div className="ps-3">
                  <h6 className="text-sm font-bold text-slate-900">
                    Security Confirmation
                  </h6>
                  <p className="text-sm text-slate-900">
                    Please ensure you are on <b>paybolt.com/migration</b> by
                    typing the address into your browser and verifying the
                    validity of the SSL certificate.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap -m-3 ">
            <div className="w-full lg:w-1/2 p-3">
              <div className="flex items-center px-4 py-3 bg-yellow-50 rounded-md">
                <div className="bg-yellow-400 p-2 rounded-full text-white">
                  <AlertTriangle className="h-4 w-4" />
                </div>
                <div className="ps-3">
                  <h6 className="text-sm font-bold text-slate-900">
                    User Guide
                  </h6>
                  <p className="text-sm text-slate-900">
                    We strongly recommend reviewing the FAQ and Upgrade Guide
                    before proceeding. You can view the guide at{" "}
                    <a
                      href="https://know.paybolt.com/general-paybolt-ai/pay-to-payai"
                      target="_blank"
                    >
                      know.paybolt.com
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>

          <h3 className="mt-4 max-w-3xl">
            Migrate your BNBPay (BPAY) / PayBolt (PAY) tokens to $PAYAI. <br />
            Connect any of your Base, BNB, Polygon, or Ethereum wallets to see
            eligible tokens for migration.
          </h3>
          

          <h2 className="mt-6 text-xl font-bold text-slate-700 dark:text-white mb-2">
            Step 1. Connect Wallet
          </h2>
          <div className="flex flex-wrap -m-3">
            <div className="w-full lg:w-1/2 p-3">
              <div className="bg-slate-800 rounded-md border border-slate-800 p-5 h-full">
                {account ? (
                  <div className="flex flex-col isolate relative h-full">
                    <div className=" absolute end-0 top-0"></div>
                    <div className="mb-auto">
                      <h6 className="font-bold text-xl w-max bg-gradient-to-r from-blue-300 to-pink-500 text-transparent bg-clip-text">
                        Welcome
                      </h6>
                      <div className="font-bold text-sm text-white mt-2 break-all">
                        {account}
                      </div>
                    </div>
                    <div className="flex gap-x-6 mt-4">
                      <div className="">
                        <div className="text-xs text-slate-300 mt-1"></div>
                        <div className="text-base font-bold text-slate-100 mt-1"></div>
                      </div>
                      <div className="">
                        <div className="text-xs text-slate-300 mt-1"></div>
                        <div className="text-base font-bold text-slate-100 mt-1"></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <button
                    className={`border-none rounded-[8px] cursor-pointer text-base px-2 py-2 transition duration-fast ease-in bg-blue-600 text-white hover:bg-blue-800`}
                    onClick={toggleWalletModal}
                  >
                    Connect Wallet
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-wrap -m-3">
            <div className="w-full lg:w-1/2 p-3">
              <Card className="h-full">
                <div className="flex flex-col isolate relative">
                  <div className="p-5 flex justify-between items-center gap-x-4">
                    <h6 className="text-md font-bold text-slate-700 dark:text-white">
                      Eligible Tokens
                    </h6>
                  </div>
                  <div className="overflow-x-auto scrollbar-thin scrollbar-track-slate-200 scrollbar-thumb-slate-600">
                    {loading ? (
                      <LoadingSpinner />
                    ) : (
                      <table className="table-auto w-full text-sm border-t border-slate-200 dark:border-slate-800 border-collapse">
                        <thead className="text-slate-600 dark:text-slate-100">
                          <tr>
                            <th className="text-start px-5 py-2">Tokens</th>
                            <th className="text-right px-5 py-2">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="px-5 py-3 border-t border-slate-200 dark:border-slate-800">
                              <div className="flex items-center">
                                <div className="ms-3">
                                  <span className="block text-slate-600 dark:text-slate-100 font-bold text-xs">
                                    BNBPay
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="px-5 py-3 border-t border-slate-200 dark:border-slate-800">
                              <span className="block text-slate-600 dark:text-slate-100 font-bold whitespace-nowrap text-right">
                                {migrationData
                                  ? formatBigNumber(
                                      migrationData.balances.bnbpay,
                                      18,
                                      0,
                                      true,
                                    )
                                  : 0}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="px-5 py-3 border-t border-slate-200 dark:border-slate-800">
                              <div className="flex items-center">
                                <div className="ms-3">
                                  <span className="block text-slate-600 dark:text-slate-100 font-bold text-xs">
                                    PAY (BNB Chain)
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="px-5 py-3 border-t border-slate-200 dark:border-slate-800">
                              <span className="block text-slate-600 dark:text-slate-100 font-bold whitespace-nowrap text-right">
                                {migrationData
                                  ? formatBigNumber(
                                      migrationData.balances.pay_bnb,
                                      18,
                                      0,
                                      true,
                                    )
                                  : 0}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="px-5 py-3 border-t border-slate-200 dark:border-slate-800">
                              <div className="flex items-center">
                                <div className="ms-3">
                                  <span className="block text-slate-600 dark:text-slate-100 font-bold text-xs">
                                    PAY - Staked (BNB Chain)
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="px-5 py-3 border-t border-slate-200 dark:border-slate-800">
                              <span className="block text-slate-600 dark:text-slate-100 font-bold whitespace-nowrap text-right">
                                {migrationData
                                  ? formatBigNumber(
                                      migrationData.balances.pay_bnb_staked,
                                      18,
                                      0,
                                      true,
                                    )
                                  : 0}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="px-5 py-3 border-t border-slate-200 dark:border-slate-800">
                              <div className="flex items-center">
                                <div className="ms-3">
                                  <span className="block text-slate-600 dark:text-slate-100 font-bold text-xs">
                                    PAY (Ethereum)
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="px-5 py-3 border-t border-slate-200 dark:border-slate-800">
                              <span className="block text-slate-600 dark:text-slate-100 font-bold whitespace-nowrap text-right">
                                {migrationData
                                  ? formatBigNumber(
                                      migrationData.balances.pay_eth,
                                      18,
                                      0,
                                      true,
                                    )
                                  : 0}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="px-5 py-3 border-t border-slate-200 dark:border-slate-800">
                              <div className="flex items-center">
                                <div className="ms-3">
                                  <span className="block text-slate-600 dark:text-slate-100 font-bold text-xs">
                                    PAY - Staked (Ethereum)
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="px-5 py-3 border-t border-slate-200 dark:border-slate-800">
                              <span className="block text-slate-600 dark:text-slate-100 font-bold whitespace-nowrap text-right">
                                {migrationData
                                  ? formatBigNumber(
                                      migrationData.balances.pay_eth_staked,
                                      18,
                                      0,
                                      true,
                                    )
                                  : 0}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="px-5 py-3 border-t border-slate-200 dark:border-slate-800">
                              <div className="flex items-center">
                                <div className="ms-3">
                                  <span className="block text-slate-600 dark:text-slate-100 font-bold text-xs">
                                    PAY (Polygon)
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="px-5 py-3 border-t border-slate-200 dark:border-slate-800">
                              <span className="block text-slate-600 dark:text-slate-100 font-bold whitespace-nowrap text-right">
                                {migrationData
                                  ? formatBigNumber(
                                      migrationData.balances.pay_polygon,
                                      18,
                                      0,
                                      true,
                                    )
                                  : 0}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="px-5 py-3 border-t border-slate-200 dark:border-slate-800">
                              <div className="flex items-center">
                                <div className="ms-3">
                                  <span className="block text-slate-600 dark:text-slate-100 font-bold text-xs">
                                    PAY - Staked (Polygon)
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="px-5 py-3 border-t border-slate-200 dark:border-slate-800">
                              <span className="block text-slate-600 dark:text-slate-100 font-bold whitespace-nowrap text-right">
                                {migrationData
                                  ? formatBigNumber(
                                      migrationData.balances.pay_polygon_staked,
                                      18,
                                      0,
                                      true,
                                    )
                                  : 0}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot className="text-slate-600 dark:text-slate-100">
                          <tr>
                            <td className="border-t text-start px-5 py-2 font-bold">
                              Total
                            </td>
                            <td className="border-t px-5 py-2 font-bold text-right">
                              {migrationData
                                ? formatBigNumber(
                                    migrationData.totals.payai,
                                    18,
                                    0,
                                    true,
                                  )
                                : 0}
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    )}
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <h2 className="text-xl font-bold text-slate-700 dark:text-white mb-2 mt-6">
            Step 2. Confirm and Migrate
          </h2>
          <div className="flex flex-wrap -m-3">
            <div className="w-full lg:w-1/2 p-3">
              <h6 className="text-xs font-bold text-slate-700 dark:text-white mb-3">
                Please confirm:
              </h6>
              <div className="flex flex-col flex-wrap justify-start items-start bg-white dark:bg-slate-600 border border-slate-200 dark:border-slate-800 px-5 py-4 rounded-md gap-3">
                <div className="">
                  <p className="text-sm text-slate-500 dark:text-slate-400">
                    You will be receiving
                  </p>
                  <h4 className="text-lg font-bold text-slate-600 dark:text-slate-200">
                    {migrationData
                      ? formatBigNumber(migrationData.totals.payai, 18, 0, true)
                      : 0}
                    <span className="text-xs font-bold text-slate-400 ms-2"></span>
                  </h4>
                  <h3 className="text-xl font-bold text-blue-600 dark:text-blue-400 mb-1">
                    PAYAI
                  </h3>
                </div>

                <div className="flex flex-wrap gap-3">
                  {migrationData && migrationData.isMigrationOpen == "1" ? 
                    <div>
                      {
                        migrationData.status == "completed" ?
                          <Button className="bg-slate-200 text-slate-600 hover:cursor-default">
                            Migration Completed
                          </Button>
                        : migrationData.status == "processing" ?
                          <Button className="bg-slate-200 text-slate-600 hover:cursor-default">
                            Migration Ongoing
                          </Button>
                        :
                          <div>
                            <Suspense fallback={<div>Loading reCAPTCHA...</div>}>
                              <div className="mb-2">
                                <ReCAPTCHA
                                  ref={recaptchaRef}
                                  sitekey={RECAPTCHA_SITE_KEY}
                                  //size="invisible"
                                  onChange={setRecaptchaToken}
                                />
                              </div>
                            </Suspense>
                            <button
                              className={`border-none rounded-[8px] cursor-pointer text-base px-4 py-2 transition duration-fast ease-in bg-blue-600 text-white hover:bg-blue-800 ${
                                submitLoading ? "opacity-50 cursor-not-allowed" : ""
                              }`}
                              onClick={() => submitNow()}
                              disabled={submitLoading}
                            >
                              {submitLoading ? (
                                <div className="flex items-center">
                                  <svg
                                    className="inline-block animate-spin h-4 w-4 mr-2 border-t-2 border-b-2 border-white rounded-full"
                                    viewBox="0 0 24 24"
                                  ></svg>
                                  Submitting...
                                </div>
                              ) : (
                                "Confirm & Migrate"
                              )}
                            </button>
                          </div>
                      }
                    </div>
                    :
                    <Button className="bg-slate-200 text-slate-600 hover:cursor-default">
                      Migration Opening Soon
                    </Button>
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4">
            Still have questions? Read more:
            <Link
              className="mt-2 w-[200px] bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
              to="/migration/faq"
            >
              <span>FAQ</span>
            </Link>
          </div>

          <div className=" mb-24"></div>
        </Container>
      </Section>
    </Layout>
  );
}

export default Home;
