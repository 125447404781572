import { BigNumber } from '@ethersproject/bignumber';

/**
 * Returns the gas value plus a margin for unexpected or variable gas costs
 * @param {BigNumber} value - the gas value to pad
 * @returns {BigNumber} - the padded gas value
 */
export function calculateGasMargin(value) {
  return value.mul(120).div(100);
}
