import { useCallback, useContext } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { useCustomWeb3React } from '../../providers/CustomWeb3ReactProvider';

import { useAppDispatch } from '../../state/hooks';
import { clearAllTransactions } from '../../state/transactions/reducer'
import { updateSelectedWallet } from '../../state/user/reducer';
import { removeConnectedWallet } from '../../state/wallets/reducer';

import { flexColumnNoWrap, flexRowNoWrap } from '../../theme/styles';
import { isMobile } from '../../utils/userAgent';

import { getIsCoinbaseWallet, getIsMetaMaskWallet } from '../../connection/utils';
import { getConnection, getConnectionName } from '../../connection';

import { ReactComponent as Close } from '../../assets/svg/x.svg';
import { shortenAddress } from '../../utils';
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink';
import TempAvatar from '../../assets/icons/icon.png';
import { Buttons, Icons } from '../../components';
import { CopyHelper } from '../../components/CopyHelper';
import Transaction from './Transaction'


const HeaderRow = styled.div`
  ${flexRowNoWrap};
  padding: 1rem 1rem;
  font-weight: 500;
  color: ${(props) => (props.color === 'blue' ? ({ theme }) => theme.accentAction : 'inherit')};
  @media (max-width: 768px) {
    padding: 1rem;
  };
`

const UpperSection = styled.div`
  position: relative;

  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`

const InfoCard = styled.div`
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.deprecated_bg3};
  border-radius: 20px;
  position: relative;
  display: grid;
  grid-row-gap: 12px;
  margin-bottom: 20px;
`

const AccountGroupingRow = styled.div`
  ${flexColumnNoWrap};
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  color: ${({ theme }) => theme.textPrimary};

  div {
    ${flexColumnNoWrap};
    align-items: center;
  }
`

const AccountSection = styled.div`
  padding: 0rem 1rem;
  @media (max-width: 768px) {
    padding: 0rem 1rem 1.5rem 1rem;
  };
`

const YourAccount = styled.div`
  h5 {
    margin: 0 0 1rem 0;
    font-weight: 400;
  }

  h4 {
    margin: 0;
    font-weight: 500;
  }
`

const LowerSection = styled.div`
  ${flexColumnNoWrap};
  padding: 1.5rem;
  flex-grow: 1;
  overflow: auto;
  background-color: ${({ theme }) => theme.backgroundInteractive};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  h5 {
    margin: 0;
    font-weight: 400;
    color: ${({ theme }) => theme.textTertiary};
  }
`

const AccountControl = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 0;
  width: 100%;

  font-weight: 500;
  font-size: 1.25rem;

  a:hover {
    text-decoration: underline;
  }

  p {
    min-width: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const AddressLink = styled.a`
  color: ${({ theme }) => theme.textTertiary};
  margin-left: 1rem;
  font-size: 0.825rem;
  display: flex;
  gap: 6px;
  text-decoration: none !important;
  :hover {
    color: ${({ theme }) => theme.textSecondary};
  }
`

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  &:hover {
    cursor: pointer;
    opacity: ${({ theme }) => theme.opacity.hover};
  }
`

const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.deprecated_text4};
  }
`

const WalletName = styled.div`
  width: initial;
  font-size: 0.825rem;
  font-weight: 500;
  color: ${({ theme }) => theme.textTertiary};
`

const TransactionListWrapper = styled.div`
  ${flexColumnNoWrap};
`

const WalletAction = styled.button`
  width: fit-content;
  font-weight: 400;
  margin-left: 8px;
  font-size: 0.825rem;
  padding: 4px 6px;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

const AutoRow = styled.button`
  width: ${({ width }) => width ?? '100%'};
  display: flex;
  padding: 0;
  align-items: ${({ align }) => align ?? 'center'};
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};

  flex-wrap: wrap;
  margin: ${({ gap }) => gap && `-${gap}`};
  justify-content: ${({ justify }) => justify && justify};

  & > * {
    margin: ${({ gap }) => gap} !important;
  }
`

const LinkStyledButton = styled.button`
  border: none;
  text-decoration: none;
  background: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  color: ${({ theme, disabled }) => (disabled ? theme.textSecondary : theme.accentAction)};
  font-weight: 500;
  :hover {
    text-decoration: ${({ disabled }) => (disabled ? null : 'underline')};
  }
  :focus {
    outline: none;
    text-decoration: ${({ disabled }) => (disabled ? null : 'underline')};
  }
  :active {
    text-decoration: none;
  }
`;

function formatConnectorName(connectionType, hasMetaMaskExtension) {
  return (
    <WalletName>
      Connected with {getConnectionName(connectionType, hasMetaMaskExtension)}
    </WalletName>
  );
}

function renderTransactions(transactions) {
  return (
    <TransactionListWrapper>
      {transactions.map((hash, i) => {
        return <Transaction key={i} hash={hash} />
      })}
    </TransactionListWrapper>
  )
}

export default function AccountDetails({
  toggleWalletModal,
  pendingTransactions,
  confirmedTransactions,
  ENSName,
  openOptions,
}) {
  const { chainId, account, connector } = useCustomWeb3React()
  const connectionType = getConnection(connector).type

  const theme = useTheme()
  const dispatch = useAppDispatch()

  const hasMetaMaskExtension = getIsMetaMaskWallet()
  const hasCoinbaseExtension = getIsCoinbaseWallet()
  const isInjectedMobileBrowser = (hasMetaMaskExtension || hasCoinbaseExtension) && isMobile

  function formatConnectorName() {
    return (
      <WalletName>
        Connected with {getConnectionName(connectionType, hasMetaMaskExtension)}
      </WalletName>
    )
  }

  const clearAllTransactionsCallback = useCallback(() => {
    if (chainId) dispatch(clearAllTransactions({ chainId }))
  }, [dispatch, chainId])

  return (
    <>
      <UpperSection>
        <CloseIcon onClick={toggleWalletModal}>
          <CloseColor />
        </CloseIcon>
        <HeaderRow>
          Account
        </HeaderRow>
        <AccountSection>
          <YourAccount>
            <InfoCard>
              <AccountGroupingRow>
                {formatConnectorName()}
                <div>
                  {!isInjectedMobileBrowser && (
                    <>
                      <WalletAction
                        style={{ fontSize: '.825rem', fontWeight: 400, marginRight: '8px' }}
                        onClick={async () => {
                          const walletType = getConnectionName(getConnection(connector).type)
                          if (connector.deactivate) {
                            connector.deactivate()
                          } else {
                            connector.resetState()
                          }

                          dispatch(updateSelectedWallet({ wallet: undefined }))
                          dispatch(removeConnectedWallet({ account, walletType }))
                          openOptions()
                        }}
                      >
                        Disconnect
                      </WalletAction>
                      <WalletAction
                        style={{ fontSize: '.825rem', fontWeight: 400 }}
                        onClick={() => {
                          openOptions()
                        }}
                      >
                        Change
                      </WalletAction>
                    </>
                  )}
                </div>
              </AccountGroupingRow>
              <AccountGroupingRow data-testid="web3-account-identifier-row">
                <AccountControl>
                  <div>
                    <img src={TempAvatar} alt="avatar" style={{width: 24, height: 24}} />
                    <p>{ENSName ? ENSName : account && shortenAddress(account)}</p>
                  </div>
                </AccountControl>
              </AccountGroupingRow>
              <AccountGroupingRow>
                <AccountControl>
                  <div>
                    {account && (
                      <CopyHelper toCopy={account} gap={6} iconSize={16} fontSize={14}>
                        Copy Address
                      </CopyHelper>
                    )}
                    {chainId && account && (
                      <AddressLink rel="noreferrer" target="_blank" href={getExplorerLink(chainId, ENSName ?? account, ExplorerDataType.ADDRESS)}>
                        <Icons.Link size={16} />
                        View on Explorer
                      </AddressLink>
                    )}
                  </div>
                </AccountControl>
              </AccountGroupingRow>
            </InfoCard>
          </YourAccount>
        </AccountSection>
      </UpperSection>
      {!!pendingTransactions.length || !!confirmedTransactions.length ? (
        <LowerSection>
          <AutoRow mb="1rem" style={{ justifyContent: 'space-between' }}>
            <span>
              Recent Transactions
            </span>
            <LinkStyledButton onClick={clearAllTransactionsCallback}>
              (clear all)
            </LinkStyledButton>
          </AutoRow>
          {renderTransactions(pendingTransactions)}
          {renderTransactions(confirmedTransactions)}
        </LowerSection>
      ) : (
        <LowerSection>
          <span style={{color: theme.textPrimary}}>
            Your transactions will appear here...
          </span>
        </LowerSection>
      )}
    </>
  )
}
