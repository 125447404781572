import React, { useState, useEffect } from 'react';

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const acceptedCookies = localStorage.getItem('acceptedCookies');
    if (!acceptedCookies) {
      setIsVisible(true);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem('acceptedCookies', 'true');
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div className="fixed bottom-4 right-4 flex items-center justify-center z-50 max-w-[300px]">
        <div className="bg-white dark:bg-slate-800 p-4 rounded-lg shadow-md text-left border-2 border-blue-600">
          <p className="text-sm dark:text-gray-200 mb-4">
            We use cookies to improve your experience. By continuing, you agree to our <a href="/privacy" className="text-blue-500 underline">Cookie Policy</a>.
          </p>
          <button
            onClick={acceptCookies}
            className="bg-blue-500 text-white px-3 py-2 text-sm rounded hover:bg-blue-700"
          >
            ACCEPT
          </button>
        </div>
      </div>
    )
  );
};

export default CookieConsent;
