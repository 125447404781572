import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";

import Layout from "../../../layout/dashboard";
import Section from "../../../layout/global/Section";
import Container from "../../../layout/global/Container";
import { Breadcrumbs, Button, Card } from "../../../components";
import { formatUsdPrice, formatBigNumber } from "../../../utils/currency";

import { SupportedChainId } from "../../../constants/chains";
import { useCustomWeb3React } from "../../../providers/CustomWeb3ReactProvider";
import { useToggleWalletModal } from "../../../state/application/hooks";

import { usePayaiContract } from "../../../hooks/useContract";

function Home() {
  const { account, chainId } = useCustomWeb3React();
  const toggleWalletModal = useToggleWalletModal();
  const payaiContract = usePayaiContract();

  const [tokenAmount, setTokenAmount] = useState(0);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      //setLoading(true);
      setError("");
      try {
        const [
          _userTokenAmount,
        ] = await Promise.all([
          payaiContract.balanceOf(account),
        ]);

        setTokenAmount(_userTokenAmount.toString());
      } catch (error) {
        console.log(error);
        setError(error);
      } finally {
        //setLoading(false);
      }
    };

    const subscribeToEvents = () => {
      if (payaiContract) {
        //presaleContract.on("ContributionMade", (event) => {
        //  fetchData();
        //});
      }
    };

    if (payaiContract && account && chainId == SupportedChainId.BASE) {
      //console.log(presaleContract)
      fetchData();
      subscribeToEvents();
    }

    // Clean up event listeners on component unmount
    return () => {
      if (payaiContract) {
        //presaleContract.removeAllListeners("ContributionMade");
      }
    };
  }, [payaiContract, account, chainId]);

  return (
    <Layout title="PayBolt AI dApp Dashboard">
      <Section className="py-10 px-3">
        <Container>
          <div className="mb-7 flex justify-between items-center -mx-3">
            <div className="px-3">
              <h1 className="text-xl font-bold text-slate-700 dark:text-white mb-2">
                PayBolt AI dApp Dashboard
              </h1>
              <Breadcrumbs items={[{ text: "Home", link: "/app" }]} />
            </div>
            <div className="px-3"></div>
          </div>
          <div className="flex flex-wrap -m-3">
            <div className="w-full xs:w-1/2 lg:w-1/4 p-3">
              <div className="bg-slate-800 rounded-md border border-slate-800 p-5 h-full">
                {account ? (
                  <div className="flex flex-col isolate relative h-full">
                    <div className=" absolute end-0 top-0"></div>
                    <div className="mb-auto">
                      <h6 className="font-bold text-xl w-max bg-gradient-to-r from-blue-300 to-pink-500 text-transparent bg-clip-text">
                        Welcome
                      </h6>
                      <div className="font-bold text-sm text-white mt-2 break-all">
                        {account}
                      </div>
                    </div>
                    <div className="flex gap-x-6 mt-4">
                      <div className="">
                        <div className="text-xs text-slate-300 mt-1"></div>
                        <div className="text-base font-bold text-slate-100 mt-1"></div>
                      </div>
                      <div className="">
                        <div className="text-xs text-slate-300 mt-1"></div>
                        <div className="text-base font-bold text-slate-100 mt-1"></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col isolate relative h-full">
                  <button
                    className={`border-none rounded-[8px] cursor-pointer text-base px-2 py-2 transition duration-fast ease-in bg-blue-600 text-white hover:bg-blue-800`}
                    onClick={toggleWalletModal}
                  >
                    Connect Wallet
                  </button>
                  </div>
                )}
              </div>
            </div>
            <div className="w-full xs:w-1/2 lg:w-1/4 p-3">
              <div className="bg-white dark:bg-slate-950 rounded-md border border-slate-200 dark:border-slate-800 p-5 h-full">
                <div className="flex flex-col isolate relative">
                  <div className=" absolute end-0 top-0"></div>
                  <div className="h-16 opacity-30 -mb-8 relative -z-10">
                    PAYAI Balance
                  </div>
                  <div className="mt-2 ms-1">
                    <p className="flex items-baseline gap-x-2 -ms-0.5">
                      <span className="text-4xl font-bold tracking-tight text-blue-500">
                        {tokenAmount
                                  ? formatBigNumber(tokenAmount, 18, 0, true)
                                  : 0}
                      </span>
                      <span className="text-sm font-semibold leading-6 tracking-wide text-slate-500 dark:text-slate-400"></span>
                    </p>
                    <h6 className="text-md font-bold text-slate-700 dark:text-white mb-1">
                      PAYAI
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </Layout>
  );
}

export default Home;
