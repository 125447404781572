import { useCustomWeb3React } from '../../providers/CustomWeb3ReactProvider';
import styled from 'styled-components/macro';

import { Icons } from "../../components";
import Loader from '../../components/Loader';
import { getChainInfo } from '../../constants/chainInfo';
import { SupportedChainId } from '../../constants/chains';

const LOGO_SIZE = 20;

const Container = styled.button`
  display: grid;
  background: none;
  grid-template-columns: min-content 1fr min-content;
  align-items: center;
  text-align: left;
  line-height: 24px;
  border: none;
  justify-content: space-between;
  padding: 10px 8px;
  cursor: pointer;
  border-radius: 12px;
  width: 240px;
  transition: background-color 250ms ease 0s;

  @media only screen and (max-width: 1023px) {
    width: 100%;
  }

  &:hover {
    background-color: #ddd;
    color: #1880ff;
  }
`;

const Label = styled.div`
  grid-column: 2;
  grid-row: 1;
  font-size: 16px;
`;

const Status = styled.div`
  grid-column: 3;
  grid-row: 1;
  display: flex;
  align-items: center;
  width: ${LOGO_SIZE}px;
`;

const ApproveText = styled.div`
  color: #aaa;
  font-size: 12px;
  grid-column: 2;
  grid-row: 2;
`;

const Logo = styled.img`
  height: ${LOGO_SIZE}px;
  width: ${LOGO_SIZE}px;
  margin-right: 12px;
  object-fit: contain;
  max-width: ${LOGO_SIZE}px;
`;

export default function ChainSelectorRow({ targetChain, onSelectChain, isPending }) {
  const { chainId } = useCustomWeb3React();
  const active = chainId === targetChain;
  const { label, logoUrl } = getChainInfo(targetChain);

  return (
    <Container onClick={() => onSelectChain(targetChain)} data-testid={`chain-selector-option-${label.toLowerCase()}`}>
      <Logo src={logoUrl} alt={label} />
      <Label>{label}</Label>
      {isPending && <ApproveText>Approve in wallet</ApproveText>}
      <Status>
        {active && <Icons.CheckMark width={LOGO_SIZE} height={LOGO_SIZE} />}
        {isPending && <Loader width={LOGO_SIZE} height={LOGO_SIZE} />}
      </Status>
    </Container>
  );
}
