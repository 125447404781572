import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import {
  Award,
  Shield,
  Zap,
  Globe,
  Lock,
  BarChart2,
  MessageCircle,
  Sliders,
  AlertTriangle,
  Code,
  HelpCircle,
  DollarSign,
  Clock,
  Send,
  ShoppingCart,
  User,
  Layers,
} from "react-feather";

import { useIsDarkMode } from "../../state/user/hooks";

import Layout from "../../layout/website";
import Section from "../../layout/global/Section";
import Container from "../../layout/global/Container";
import { Accordion } from "../../components";

import Logo from "../../assets/images/logo.png";
import WhiteLogo from "../../assets/images/logo-white.png";
import ContentImage01 from "../../assets/images/content-001.jpg";
import ContentImage02 from "../../assets/images/content-002.jpg";
import ContentImage03 from "../../assets/images/content-003.jpg";

import { ReactComponent as SvgBase } from "../../assets/images/partners/base.svg";
import { ReactComponent as SvgOpenai } from "../../assets/images/partners/openai.svg";
import { ReactComponent as SvgCoingecko } from "../../assets/images/partners/coingecko.svg";
import { ReactComponent as SvgCoinmarketcap } from "../../assets/images/partners/coinmarketcap.svg";
import { ReactComponent as SvgMedium } from "../../assets/images/partners/medium.svg";
import { ReactComponent as SvgHf } from "../../assets/images/partners/hf.svg";
import { ReactComponent as SvgPytorch } from "../../assets/images/partners/pytorch.svg";

function TextGenPage() {
  const darkMode = useIsDarkMode();

  const [index, setIndex] = useState(0);

  const [selectedUseCase, setSelectedUseCase] = useState("All");

  const partners = [
    {
      icon: (
        <SvgBase className="w-32 filter fill-current text-black dark:text-white" />
      ),
      img: "/images/partners/base.svg",
      name: "Base",
    },
    {
      icon: (
        <SvgCoingecko className="w-32 filter fill-current text-black dark:text-white" />
      ),
      img: "/images/partners/coingecko.svg",
      name: "CoinGecko",
    },
    {
      icon: (
        <SvgCoinmarketcap className="w-32 filter fill-current text-black dark:text-white" />
      ),
      img: "/images/partners/coinmarketcap.svg",
      name: "CoinMarketCap",
    },
    {
      icon: (
        <SvgMedium className="w-32 filter fill-current text-black dark:text-white" />
      ),
      img: "/images/partners/medium.svg",
      name: "Medium",
    },
    {
      icon: (
        <SvgOpenai className="w-32 filter fill-current text-black dark:text-white" />
      ),
      img: "/images/partners/openai.svg",
      name: "OpenAI",
    },
    {
      icon: (
        <SvgHf className="w-32 filter fill-current text-black dark:text-white" />
      ),
      img: "/images/partners/hf.svg",
      name: "Hugging Face",
    },
    {
      icon: (
        <SvgPytorch className="w-32 filter fill-current text-black dark:text-white" />
      ),
      img: "/images/partners/hf.svg",
      name: "PyTorch",
    },
  ];

  const useCaseButtons = [
    {
      title: "All",
      descriptions: [],
    },
    {
      title: "Individuals",
      descriptions: [
        {
          icon: <User />,
          title: "Personal Transactions",
          text: "Easily send and receive payments from friends and family, with support for various cryptocurrencies. The PayBolt AI crypto wallet on iOS and Android makes personal transactions simple and secure.",
        },
        {
          icon: <Lock />,
          title: "Secure Escrow",
          text: "Use escrow for high-value transactions to ensure funds are released only when both parties are satisfied. Initiate and manage escrow services directly from your mobile wallet.",
        },
        {
          icon: <Globe />,
          title: "Multi-Lingual Support",
          text: "Interact with our platform in your preferred language, making it accessible for users worldwide. The PayBolt AI mobile wallet supports multiple languages for user convenience.",
        },
      ],
    },
    {
      title: "Freelancers",
      descriptions: [
        {
          icon: <Send />,
          title: "Seamless Payments",
          text: "Freelancers can receive payments in any cryptocurrency, ensuring quick and hassle-free transactions from clients worldwide. Use the PayBolt AI crypto wallet available on iOS and Android for easy access to your funds.",
        },
        {
          icon: <Shield />,
          title: "Escrow Protection",
          text: "Utilize our escrow service to guarantee payment upon project completion, reducing the risk of non-payment. Manage your escrow transactions directly from your mobile wallet.",
        },
        {
          icon: <BarChart2 />,
          title: "Transaction Analytics",
          text: "Track earnings, expenses, and payment history through the PayBolt AI dashboard, accessible via the web or your mobile app.",
        },
      ],
    },
    {
      title: "Small Businesses",
      descriptions: [
        {
          icon: <ShoppingCart />,
          title: "Crypto Acceptance",
          text: "Small businesses can accept cryptocurrency payments, attracting tech-savvy customers and offering flexible payment options. The PayBolt AI mobile wallet on iOS and Android makes it easy to manage these transactions on the go.",
        },
        {
          icon: <DollarSign />,
          title: "Reduced Fees",
          text: "Benefit from lower transaction fees compared to traditional payment methods, increasing profit margins. Monitor these savings using the mobile app.",
        },
        {
          icon: <Shield />,
          title: "AI Fraud Detection",
          text: "Protect your business from fraudulent transactions with our advanced AI security measures, integrated into the PayBolt AI mobile wallet.",
        },
      ],
    },
    {
      title: "E-commerce Platforms",
      descriptions: [
        {
          icon: <Layers />,
          title: "Integrated Payment Gateway",
          text: "Integrate PayBolt AI’s payment gateway into your e-commerce site, enabling secure and instant crypto transactions. Customers can pay directly from their PayBolt AI crypto wallets on iOS and Android.",
        },
        {
          icon: <Globe />,
          title: "Multi-Currency Support",
          text: "Offer customers the ability to pay in their preferred cryptocurrency, enhancing user experience and expanding market reach. The PayBolt AI mobile wallet supports multiple currencies for seamless transactions.",
        },
        {
          icon: <Layers />,
          title: "Custom Templates",
          text: "Use our customizable templates to create seamless checkout experiences tailored to your brand, with easy integration into your mobile and web platforms.",
        },
      ],
    },
    {
      title: "Large Enterprises",
      descriptions: [
        {
          icon: <BarChart2 />,
          title: "Bulk Transactions",
          text: "Manage large volumes of transactions efficiently with our scalable infrastructure designed for enterprise needs. Use the PayBolt AI mobile wallet to track and manage these transactions.",
        },
        {
          icon: <BarChart2 />,
          title: "Advanced Analytics",
          text: "Leverage detailed transaction analytics and insights to inform business decisions and optimize financial operations. Access these insights via the PayBolt AI dashboard on your iOS or Android device.",
        },
        {
          icon: <Globe />,
          title: "Global Reach",
          text: "Conduct international transactions effortlessly, with support for multiple currencies and compliance with global regulations. Manage your global transactions from the convenience of your mobile wallet.",
        },
      ],
    },
    {
      title: "Developers",
      descriptions: [
        {
          icon: <Code />,
          title: "API Integration",
          text: "Integrate PayBolt AI’s payment solutions into your applications and platforms using our robust APIs. Test and deploy your integrations using the PayBolt AI mobile wallet.",
        },
        {
          icon: <Code />,
          title: "Custom Features",
          text: "Develop custom payment features and workflows to meet the unique needs of your projects. Use the PayBolt AI mobile wallet to demonstrate and test these features.",
        },
        {
          icon: <MessageCircle />,
          title: "Community Support",
          text: "Join our developer community to share knowledge, get support, and stay updated on the latest features. Access community resources and support via the PayBolt AI mobile app.",
        },
      ],
    },
  ];

  const features = [
    {
      title: "AI-Secured Crypto Payments",
      description:
        "Ensure the highest level of security for your crypto transactions with advanced AI-driven fraud detection and prevention mechanisms.",
      icon: <Shield className="h-8 w-8" />,
    },
    {
      title: "Instant Transactions",
      description:
        "Experience lightning-fast transaction processing, minimizing wait times and enhancing user satisfaction.",
      icon: <Zap className="h-8 w-8" />,
    },
    {
      title: "Global Reach",
      description:
        "Seamlessly conduct transactions across borders, supporting a wide range of cryptocurrencies and fiat currencies.",
      icon: <Globe className="h-8 w-8" />,
    },
    {
      title: "Secure Escrow Services",
      description:
        "Protect your transactions with our secure escrow services, ensuring funds are held safely until both parties fulfill their obligations.",
      icon: <Lock className="h-8 w-8" />,
    },
    {
      title: "Advanced Dashboard",
      description:
        "Gain access to a comprehensive dashboard that offers valuable insights, analytics, and activity tracking to help you manage your transactions efficiently.",
      icon: <BarChart2 className="h-8 w-8" />,
    },
    {
      title: "Customizable Payment Solutions",
      description:
        "Create and manage tailored payment solutions that fit your specific business needs, including custom prompts and templates.",
      icon: <Sliders className="h-8 w-8" />,
    },
    {
      title: "Fraud Prevention and Scam Detection",
      description:
        "Leverage powerful AI algorithms to analyze payment addresses and predict potential scams, safeguarding your assets and transactions.",
      icon: <AlertTriangle className="h-8 w-8" />,
    },
    {
      title: "Payment Integration API",
      description:
        "Integrate PayBolt AI seamlessly into your existing systems with our robust API, enabling smooth and efficient payment processing.",
      icon: <Code className="h-8 w-8" />,
    },
  ];

  const problems = [
    {
      title: "Fraud and Scams",
      description:
        "General users and project teams frequently face scams from service providers, leading to financial losses and distrust.",
      icon: <AlertTriangle />,
    },
    {
      title: "Trust Issues",
      description:
        "Users often hesitate to engage in transactions due to a lack of trust and fear of being scammed.",
      icon: <Shield />,
    },
    {
      title: "High Transaction Fees",
      description:
        "Many platforms charge high fees for processing transactions, making it costly for users to send and receive payments.",
      icon: <DollarSign />,
    },
    {
      title: "Slow Transaction Speeds",
      description:
        "Transaction times can be slow, causing delays in payment processing and frustrating users.",
      icon: <Clock />,
    },
  ];

  const missions = [
    {
      title: "AI-Secured Transactions",
      description:
        "Leveraging advanced AI-driven fraud detection and prevention mechanisms to enhance transaction security.",
      icon: <Shield />,
    },
    {
      title: "Escrow Services",
      description:
        "Introducing secure AI-driven escrow services to protect transactions. PayBolt AI ensures funds are held safely until both parties fulfill their obligations, using advanced AI algorithms to decide on the disbursement of the payment, significantly reducing the risk of scams.",
      icon: <AlertTriangle />,
    },
    {
      title: "Reduced Transaction Fees",
      description:
        "Implementing efficient processing methods to minimize costs.",
      icon: <DollarSign />,
    },
    {
      title: "Instant Processing",
      description:
        "Ensuring lightning-fast transaction processing to reduce wait times and enhance user satisfaction.",
      icon: <Clock />,
    },
  ];

  const rotatingTexts = [
    "Crypto Payments",
    "Instant Transfers",
    "Escrow Protection",
    "Global Reach",
    "Seamless Transactions",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % rotatingTexts.length);
    }, 2000); // Change text every 6 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <Layout title="AI-Secured Crypto Payments">
      <Section className="pt-16 md:pt-32 pb-32 xl:py-48 bg-gradient-to-br from-white dark:from-slate-950 to-blue-100 dark:to-blue-950 overflow-hidden">
        <Container>
          <div className="max-w-3xl mx-auto text-center">
            <div className="text-lg inline-flex font-medium text-white bg-gradient-to-r from-blue-600 to-blue-600 px-4 py-0.5 rounded-full mb-3">
              PAYAI
            </div>
            <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-[2.5rem] font-bold text-slate-800 dark:text-white">
              <span className="block text-4xl md:text-5xl lg:text-6xl xl:text-6xl text-blue-600 mb-3 lg:mb-2">
                AI-Secured
              </span>
              <span className="block text-4xl md:text-5xl lg:text-6xl xl:text-6xl mb-3 lg:mb-6 min-h-28">
                <span className="animate-[fadeInOut_2s_ease-in-out_infinite]">
                  {rotatingTexts[index]}
                </span>
                <svg
                  className="ml-3 inline transition-all duration-[2850ms]"
                  width="47"
                  height="62"
                  viewBox="0 0 47 62"
                  fill="#2563eb"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M27.95 0L0 38.213H18.633V61.141L46.583 22.928H27.95V0Z"></path>
                </svg>
              </span>
            </h1>
            <p className="text-[20px] text-slate-600 dark:text-slate-300">
              PayBolt AI is your intelligent payment assistant, leveraging AI
              and deep insights to streamline and secure your crypto payments.
            </p>

            <h6 className="mt-8 text-lg inline-block font-bold rounded-full bg-gradient-to-r from-purple-500 to-blue-500 text-transparent bg-clip-text">
              Join the Future of Crypto Transactions Today
            </h6>
          </div>

          {/*<ul className="flex justify-center gap-7 mt-4 -mb-36">
            <li className="relative z-10">
              <Link className="p-2 bg-white flex flex-col rounded-xl text-center -rotate-12 -me-7 mt-16">
                <img
                  className="w-56 rounded-t-xl border border-slate-100"
                  src="/images/preview/user/dashboard.png"
                  alt=""
                />
              </Link>
            </li>
            <li>
              <Link className="p-2 bg-white flex flex-col rounded-xl text-center mt-8">
                <img
                  className="w-56 rounded-t-xl border border-slate-100"
                  src="/images/preview/admin/dashboard.png"
                  alt=""
                />
              </Link>
            </li>
            <li className="relative z-10">
              <Link className="p-2 bg-white flex flex-col rounded-xl text-center rotate-12 -ms-7 mt-16">
                <img
                  className="w-56 rounded-t-xl border border-slate-100"
                  src="/images/preview/user/profile.png"
                  alt=""
                />
              </Link>
            </li>
          </ul>*/}
        </Container>
        <div className="absolute bottom-3 sm:bottom-4 w-full flex justify-center z-10 pb-10 xl:pb-20">
          <div className="bg-gradient-to-r from-blue-500 to-indigo-500 group relative shadow-2xl shadow-slate-900 rounded-full p-1 text-sm/6 font-semibold text-white inline-block">
            <div className="relative z-10 rounded-full bg-white py-1 px-5 ring-1 ring-white/10 group-hover:bg-blue-600 group-hover:text-white transition duration-300">
              <a target="_blank" className="text-blue-600 group-hover:text-white font-bold" href="https://app.uniswap.org/swap?outputCurrency=0x3b745514ae7A9b0E8dBA516e90b4DeAFBd6d3512&chain=base">
                Purchase PAYAI on Base
              </a>
            </div>
          </div>
        </div>
      </Section>

      <Section className="pt-6 md:pt-10 pb-6 md:pb-10 lg:pb-14 xl:pb-18 bg-slate-50 dark:bg-slate-950 overflow-hidden">
        <Container>
          <ul className="flex flex-wrap justify-center items-center -m-2">
            {partners.map((item, index) => {
              return (
                <li key={index} className="p-2">
                  <div className="p-4 sm:p-5 flex justify-center items-center w-full h-24">
                    {item.icon}
                  </div>
                </li>
              );
            })}
          </ul>
        </Container>
      </Section>

      <Section className="pt-16 md:pt-20 lg:pt-24 xl:pt-28 pb-16 md:pb-20 lg:pb-24 xl:pb-28 bg-white dark:bg-slate-900 overflow-hidden">
        <Container>
          <header className="mx-auto text-center w-2/5 mb-14 max-xl:w-8/12 max-lg:w-10/12 max-md:w-full">
            <h2 className="text-4xl font-bold mb-2">The Problem</h2>
            <p className="mt-6 text-lg text-gray-400 transform">
              A growing concern in the crypto world
            </p>
          </header>
          <div className="pb-16 grid grid-cols-4 gap-8 max-lg:grid-cols-2 max-md:grid-cols-1">
            {problems.map((problem, index) => (
              <div key={index} className="group flex gap-5">
                <div className="grid place-items-center shrink-0 w-16 h-16 rounded-lg bg-gray-200 text-gray-800 transition-all group-hover:scale-125 group-hover:shadow-xl group-hover:bg-black group-hover:text-white">
                  {problem.icon}
                </div>
                <div className="relative shrink">
                  <h4 className="mb-3 text-xl font-bold">{problem.title}</h4>
                  <p className="text-sm text-slate-500 dark:text-slate-400">
                    {problem.description}
                  </p>
                </div>
              </div>
            ))}
          </div>

          <div className="max-w-4xl mx-auto border-t border-slate-200 dark:border-slate-800 my-1 w-"></div>

          <div className="pt-16 flex flex-wrap justify-center">
            <div className="w-full lg:w-1/2 px-4">
              <div className="mb-8">
                <h2 className="text-4xl font-bold leading-tight dark:text-white md:text-5xl transform">
                  Our Mission
                </h2>

                <p className="mt-6 text-lg dark:text-slate-300 transform">
                  At PayBolt AI, we are committed to providing unparalleled
                  AI-secured payment solutions that prioritize security,
                  efficiency, and user-friendliness. Here's how we stand out
                  from the competition:
                </p>
              </div>
              <div className="space-y-6">
                {missions.map((mission, index) => (
                  <div key={index} className="flex items-start space-x-4">
                    <div className="grid place-items-center w-11 h-11 rounded-lg bg-gradient-to-bl from-gray-200 to-gray-400 text-gray-800 transition-all group-hover:scale-125 group-hover:shadow-lg group-hover:bg-black group-hover:text-white">
                      {index + 1}
                    </div>
                    <div className="flex-1">
                      <h3 className="text-lg font-semibold dark:text-white">
                        {mission.title}
                      </h3>
                      <p className="dark:text-gray-400">{mission.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="w-full lg:w-1/2 px-4 relative pt-16">
              <div className="relative">
                <img
                  src={ContentImage03}
                  alt=""
                  className="w-full rounded-2xl shadow-lg"
                />

                <div className="absolute inset-0 flex items-center justify-center"></div>
              </div>
            </div>
          </div>
        </Container>
      </Section>

      <Section className="pt-16 md:pt-20 lg:pt-24 xl:pt-28 pb-16 md:pb-20 lg:pb-24 xl:pb-28 bg-slate-50 dark:bg-slate-950 overflow-hidden">
        <Container>
          <div className="mx-auto w-full lg:w-10/12 xl:w-8/12">

            <h2 className="text-center text-4xl font-bold leading-tight dark:text-white md:text-5xl transform">
              Shaping the Future of Crypto Payments 🏔️
            </h2>

            <p className="leading-relaxed max-w-4xl mx-auto text-center mt-6 mb-10 text-lg dark:text-slate-300">
              Whether you're a business owner, investor, or everyday user,
              PayBolt AI offers secure and efficient solutions for all your
              cryptocurrency payment needs.
            </p>

            <img
              className="block mx-auto rounded-2xl mb-10"
              src={ContentImage01}
              alt=""
              width="1450"
              height="761"
            />

            <p className="mt-4">
              At PayBolt AI, we are dedicated to revolutionizing the world of
              cryptocurrency payments with advanced AI technology. Our solutions
              are designed to enhance security, reduce costs, and streamline
              transactions for users across the globe.
            </p>
            <p className="mt-4">
              Our mission is to empower individuals and businesses to leverage
              the power of AI for secure and efficient crypto transactions.
            </p>
            <p className="mt-4">
              We believe that AI can significantly enhance the security and
              efficiency of financial transactions, making cryptocurrency a
              viable option for everyday use.
            </p>
            <p className="mt-4">
              By developing state-of-the-art AI-secured payment solutions, we
              aim to democratize access to secure and efficient cryptocurrency
              transactions, enabling users from all backgrounds to benefit from
              the advantages of digital currencies.
            </p>
          </div>
        </Container>
      </Section>

      <Section className="pt-16 md:pt-20 lg:pt-24 xl:pt-28 pb-16 md:pb-20 lg:pb-24 xl:pb-28 bg-white dark:bg-slate-900 overflow-hidden">
        <Container>
          <div className="flex flex-wrap items-center justify-center pb-8 lg:pb-12">
            <div className="w-full sm:w-4/5 md:w-3/5 lg:w-2/5 text-center mx-auto">
              
            <h2 className="text-center text-4xl font-bold leading-tight dark:text-white md:text-5xl transform">
              Key Features of PayBolt AI
            </h2>
            </div>
          </div>
          <div className="grid gap-6 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
            {features.map((item, index) => (
              <div
                key={index}
                className="bg-white dark:bg-slate-900 p-6 border border-slate-100 dark:border-slate-800 shadow rounded-2xl hover:ring-2 hover:ring-blue-600 transition-all"
              >
                <div className="flex flex-col gap-x-3 mb-2">
                  <div className="h-8 mb-2 text-blue-600">{item.icon}</div>
                  <h6 className="text-lg font-bold text-slate-700 dark:text-white">
                    {item.title}
                  </h6>
                </div>
                <p className="text-sm text-slate-500 dark:text-slate-400">
                  {item.description}
                </p>
              </div>
            ))}
          </div>
        </Container>
      </Section>
      <Section className="pt-16 md:pt-20 lg:pt-24 xl:pt-28 pb-16 md:pb-20 lg:pb-24 xl:pb-28 bg-slate-50 dark:bg-slate-950 overflow-hidden">
        <Container>
          <div className="flex flex-wrap items-center justify-center pb-8">
            <div className="w-full sm:w-4/5 md:w-4/5 lg:w-3/5 xl:w-1/2 2xl:w-2/5 text-center mx-auto">
             
            <h2 className="text-center text-4xl font-bold leading-tight dark:text-white md:text-5xl transform">
               Use Cases
            </h2>
            </div>
          </div>
          <div className="flex flex-wrap justify-center">
            <div className="flex flex-col items-center mt-10">
              <div className="mx-auto mb-10 inline-flex flex-wrap items-center gap-2 rounded-lg border p-[0.35rem] text-[12px] font-semibold leading-none max-md:justify-center">
                {useCaseButtons.map((button, index) => (
                  <button
                    key={index}
                    className={classNames(
                      "transition-all px-4 py-2 rounded-lg hover:text-heading-foreground text-lg",
                      {
                        "text-heading-foreground bg-blue-600 shadow-lg text-white":
                          selectedUseCase === button.title,
                      },
                    )}
                    onClick={() => setSelectedUseCase(button.title)}
                  >
                    {button.title}
                  </button>
                ))}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-6 max-lg:grid-cols-2 max-md:grid-cols-1">
            {useCaseButtons
              .filter(
                (useCase) =>
                  selectedUseCase === "All" ||
                  useCase.title === selectedUseCase,
              )
              .map((useCase, index) =>
                useCase.descriptions.map((desc, i) => (
                  <div
                    key={index + "_" + i}
                    className="bg-white dark:bg-slate-900 p-6 border border-slate-100 dark:border-slate-800 shadow rounded-2xl hover:ring-2 hover:ring-blue-600 transition-all"
                  >
                    {selectedUseCase === "All" && (
                      <h3 className="text-2xl font-bold mb-4">
                        {useCase.title}
                      </h3>
                    )}
                    <div className="group mb-6">
                      <div className="flex items-center mb-4">
                        <div className="grid place-items-center w-11 h-11 rounded-lg bg-gradient-to-bl from-gray-200 to-gray-400 text-gray-800 transition-all group-hover:scale-125 group-hover:shadow-lg group-hover:bg-black group-hover:text-white">
                          {desc.icon}
                        </div>
                        <h4 className="ml-4 text-lg font-semibold">
                          {desc.title}
                        </h4>
                      </div>
                      <p className="text-sm text-slate-500 dark:text-slate-400">
                        {desc.text}
                      </p>
                    </div>
                  </div>
                )),
              )}
          </div>
        </Container>
      </Section>
      <Section className="pt-16 md:pt-20 lg:pt-24 xl:pt-28 pb-2 bg-white dark:bg-slate-900 overflow-hidden">
        <Container>
          <div className="flex flex-wrap items-center justify-center pb-8 lg:pb-10">
            <div className="sm:w-2/3 md:w-3/5 lg:w-2/5 text-center text- mx-auto">
              
            <h2 className="text-center text-4xl font-bold leading-tight dark:text-white md:text-5xl transform">
               Got Questions? Let's Answer Them!
            </h2>
            </div>
          </div>
          <div className="flex flex-wrap justify-center -m-3 md:-m-4">
            <div className="w-full xl:w-3/4 p-3 md:p-4">
              <Accordion className="flex flex-col gap-3">
                <Accordion.Item
                  size="lg"
                  className="bg-white dark:bg-slate-950 border border-slate-100 dark:border-slate-950 shadow rounded-2xl"
                  header={
                    "What is PayBolt AI and how does it enhance my payment experience?"
                  }
                  initialEntered
                >
                  <div className="max-w-3xl">
                    <p className="text-base/7">
                      PayBolt AI is an advanced digital payment platform that
                      leverages artificial intelligence to provide secure,
                      efficient, and seamless crypto transactions. By using
                      PayBolt AI, you benefit from faster processing times,
                      reduced transaction fees, and enhanced security features
                      such as AI-driven fraud detection and secure escrow
                      services.
                    </p>
                  </div>
                </Accordion.Item>
                <Accordion.Item
                  size="lg"
                  className="bg-white dark:bg-slate-950 border border-slate-100 dark:border-slate-950 shadow rounded-2xl"
                  header={
                    "How does PayBolt AI ensure the security of my transactions?"
                  }
                >
                  <div className="max-w-3xl">
                    <p className="text-base/7">
                      PayBolt AI employs state-of-the-art security measures,
                      including AI-powered analysis to predict and prevent
                      potential scams. Our secure escrow contracts hold funds
                      safely until both parties fulfill their obligations,
                      ensuring that your transactions are protected from fraud
                      and non-delivery.
                    </p>
                  </div>
                </Accordion.Item>
                <Accordion.Item
                  size="lg"
                  className="bg-white dark:bg-slate-950 border border-slate-100 dark:border-slate-950 shadow rounded-2xl"
                  header={
                    "Can I use PayBolt AI for payments in any cryptocurrency?"
                  }
                >
                  <div className="max-w-3xl">
                    <p className="text-base/7">
                      Absolutely! PayBolt AI supports payments with any token on
                      any blockchain, providing you with unparalleled
                      flexibility. Our platform is designed to handle
                      cross-chain transactions effortlessly, allowing you to pay
                      and receive payments in the cryptocurrency of your choice.
                    </p>
                  </div>
                </Accordion.Item>
                <Accordion.Item
                  size="lg"
                  className="bg-white dark:bg-slate-950 border border-slate-100 dark:border-slate-950 shadow rounded-2xl"
                  header={
                    "What are the benefits of using PayBolt AI for my business?"
                  }
                >
                  <div className="max-w-3xl">
                    <p className="text-base/7">
                      For business owners, PayBolt AI offers a comprehensive
                      suite of tools to manage payments efficiently. Our
                      business management backend, seamless integration APIs,
                      and AI-driven analytics help you streamline your payment
                      processes, reduce costs, and improve customer trust and
                      satisfaction.
                    </p>
                  </div>
                </Accordion.Item>
                <Accordion.Item
                  size="lg"
                  className="bg-white dark:bg-slate-950 border border-slate-100 dark:border-slate-950 shadow rounded-2xl"
                  header={"How do I get started with PayBolt AI?"}
                >
                  <div className="max-w-3xl">
                    <p className="text-base/7">
                      Getting started with PayBolt AI is simple and
                      straightforward. You can begin by downloading our crypto
                      wallet app for iOS or Android, or by using our
                      decentralized application (DApp). Create your account and
                      start making secure, AI-powered crypto payments today.
                      Visit our website for detailed instructions and support,
                      and join the future of digital payments with PayBolt AI.
                    </p>
                  </div>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </Container>
      </Section>
      <Section className="pt-16 md:pt-20 lg:pt-24 xl:pt-28 bg-white dark:bg-slate-900 overflow-hidden">
        <Container>
          <div className="relative bg-gradient-to-b from-white to-slate-50 dark:from-slate-900 dark:to-slate-950 border border-b-0 border-gray-200 dark:border-gray-800 rounded-t-xl p-6 sm:p-10 !pb-0">
            <div className="flex flex-wrap items-center -m-4">
              <div className="p-4 w-full lg:w-6/12">
                <div className="lg:pb-10">
                  <h1 className="text-2xl sm:text-4xl/snug font-bold text-slate-700 dark:text-white mb-4">
                    Experience{" "}
                    <span className="bg-clip-text text-blue-600">
                      AI-Secured
                    </span>{" "}
                    Crypto Payments with PayBolt AI
                  </h1>
                  <p className="text-slate-500 dark:text-slate-300 text-base/relaxed sm:text-lg/relaxed mb-6">
                    Enhance security, streamline transactions, and maximize
                    efficiency. Let AI protect and optimize your crypto
                    payments.
                  </p>
                  <Link to="/PAYAI" className="inline-flex justify-center font-bold text-base bg-blue-600 text-white hover:bg-blue-600 transition-all px-7 py-3 rounded-lg">
                    Read More About PAYAI
                  </Link>
                </div>
              </div>

              <div className="p-4 w-full lg:w-6/12 self-end">
                <img
                  className="rounded-t-xl shadow-lg shadow-blue-100 dark:shadow-blue-950 border border-b-0 border-gray-200 dark:border-gray-800"
                  src={ContentImage02}
                  alt=""
                />
              </div>
            </div>
          </div>
        </Container>
      </Section>
      <Section className="py-16 md:py-20 bg-slate-50 dark:bg-slate-950 overflow-hidden border-t border-gray-200 dark:border-gray-800 mt-auto">
        <Container>
          <div className="flex flex-wrap -m-3 md:-m-4">
            <div className="w-full lg:w-4/12 xl:w-3/12 p-3 md:p-4">
              <div className="pb-3">
                <Link to="/">
                  {darkMode ? <img src={WhiteLogo} /> : <img src={Logo} />}
                </Link>
                <div className="mt-2 lg:mt-5">
                  <p className="text-slate-500 dark:text-slate-300 text-base/7">
                    AI-Secured Crypto Payments
                  </p>
                </div>
              </div>
            </div>
            <div className="w-6/12 sm:w-3/12 lg:w-2/12 p-3 md:p-4 lg:ms-auto">
              {/*
              <h6 className="font-bold text-base text-slate-700 dark:text-white mb-3">
                Tools
              </h6>
              <ul>
                <li>
                  <Link className="inline-flex text-sm/7 text-slate-500 dark:text-slate-400 hover:text-blue-600 hover:dark:text-blue-600 transition-all">
                    PaymentAI
                  </Link>
                </li>
                <li>
                  <Link className="inline-flex text-sm/7 text-slate-500 dark:text-slate-400 hover:text-blue-600 hover:dark:text-blue-600 transition-all">
                    EscrowAI
                  </Link>
                </li>
                <li>
                  <Link className="inline-flex text-sm/7 text-slate-500 dark:text-slate-400 hover:text-blue-600 hover:dark:text-blue-600 transition-all">
                    MerchantAI
                  </Link>
                </li>
              </ul>
              */}
            </div>
            <div className="w-6/12 sm:w-3/12 lg:w-2/12 p-3 md:p-4">
              <h6 className="font-bold text-base text-slate-700 dark:text-white mb-3">
                PayBolt AI
              </h6>
              <ul>
                <li>
                  <Link to="/PAYAI" className="inline-flex text-sm/7 text-slate-500 dark:text-slate-400 hover:text-blue-600 hover:dark:text-blue-600 transition-all">
                    $PAYAI
                  </Link>
                </li>
                <li>
                  <Link 
                    to="https://file.paybolt.com/PayBoltAI_Whitepaper_1_0.pdf" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="inline-flex text-sm/7 text-slate-500 dark:text-slate-400 hover:text-blue-600 hover:dark:text-blue-600 transition-all">
                    Whitepaper
                  </Link>
                </li>
                <li>
                  <Link 
                    to="https://know.paybolt.com"
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="inline-flex text-sm/7 text-slate-500 dark:text-slate-400 hover:text-blue-600 hover:dark:text-blue-600 transition-all"
                  >
                    Knowledge Base
                  </Link>
                </li>
                <li>
                  <Link
                    to="/terms"
                    className="inline-flex text-sm/7 text-slate-500 dark:text-slate-400 hover:text-blue-600 hover:dark:text-blue-600 transition-all"
                  >
                    Terms Of Use
                  </Link>
                </li>
                <li>
                  <Link
                    to="/privacy"
                    className="inline-flex text-sm/7 text-slate-500 dark:text-slate-400 hover:text-blue-600 hover:dark:text-blue-600 transition-all"
                  >
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
            <div className="w-full sm:w-6/12 lg:w-3/12 p-3 md:p-4">
              <h6 className="font-bold text-base text-slate-700 dark:text-white mb-3">
                Find us on
              </h6>
              <ul>
                <li>
                  <a
                    href="https://x.com/PayBoltAI"
                    target="_blank"
                    className="inline-flex text-sm/7 text-slate-500 dark:text-slate-400 hover:text-blue-600 hover:dark:text-blue-600 transition-all"
                  >
                    <span>
                      <svg
                        className="inline fill-current text-black dark:text-white"
                        width="14"
                        height="14"
                        viewBox="0 0 300 271"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="m236 0h46l-101 115 118 156h-92.6l-72.5-94.8-83 94.8h-46l107-123-113-148h94.9l65.5 86.6zm-16.1 244h25.5l-165-218h-27.4z"></path>
                      </svg>
                      &nbsp; X / Twitter
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://t.me/PayBoltAI"
                    target="_blank"
                    className="inline-flex text-sm/7 text-slate-500 dark:text-slate-400 hover:text-blue-600 hover:dark:text-blue-600 transition-all"
                  >
                    <span>
                      <svg
                        className="inline"
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="14"
                        viewBox="0 0 16 15"
                      >
                        <path
                          className="fill-current text-black dark:text-white"
                          d="M15.9542857,0.914285714 L13.44,13.4857143 C13.44,13.4857143 13.0742857,14.3542857 12.1142857,13.9428571 L6.4,9.55428571 L4.29714286,8.54857143 L0.822857143,7.31428571 C0.822857143,7.31428571 0.274285714,7.13142857 0.228571429,6.72 C0.182857143,6.30857143 0.822857143,6.08 0.822857143,6.08 L14.8114286,0.594285714 C14.8114286,0.594285714 15.9542857,0.0914285714 15.9542857,0.914285714 Z"
                        ></path>
                        <path
                          className="paper fill-current text-gray-400 dark:text-gray-600"
                          d="M5.98857143,13.3485714 C5.98857143,13.3485714 5.80571429,13.3485714 5.62285714,12.6628571 C5.39428571,12.0228571 4.34285714,8.50285714 4.34285714,8.50285714 L12.8,3.15428571 C12.8,3.15428571 13.3028571,2.88 13.2571429,3.15428571 C13.2571429,3.15428571 13.3485714,3.2 13.0742857,3.42857143 C12.8,3.65714286 6.44571429,9.50857143 6.44571429,9.50857143"
                        ></path>
                        <path
                          className="fill-current text-black dark:text-white"
                          d="M8.64,11.2457143 L6.35428571,13.3028571 C6.35428571,13.3028571 6.17142857,13.44 5.98857143,13.3485714 L6.44571429,9.50857143"
                        ></path>
                      </svg>
                      &nbsp; Telegram
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://paybolt.medium.com/"
                    target="_blank"
                    className="inline-flex text-sm/7 text-slate-500 dark:text-slate-400 hover:text-blue-600 hover:dark:text-blue-600 transition-all"
                  >
                    <span>
                      <svg
                        className="inline"
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="12"
                        viewBox="0 1 16 13"
                      >
                        <path
                          className="fill-current text-black dark:text-white"
                          d="M156.897292,4.65196943 C156.917171,4.45095715 156.842272,4.25227888 156.695612,4.11699 L155.201681,2.27513227 L155.201681,2 L159.840336,2 L163.42577,10.047619 L166.577965,2 L171,2 L171,2.27513227 L169.722689,3.52851264 C169.612571,3.61441846 169.557947,3.75561078 169.580766,3.89535568 L169.580766,13.1046444 C169.557947,13.2443893 169.612571,13.3855815 169.722689,13.4714874 L170.970121,14.7248678 L170.970121,15 L164.695612,15 L164.695612,14.7248678 L165.987862,13.4409171 C166.114846,13.3109935 166.114846,13.2727807 166.114846,13.0740741 L166.114846,5.63021753 L162.521942,14.9694297 L162.036415,14.9694297 L157.853408,5.63021753 L157.853408,11.8894768 C157.818532,12.1526336 157.903929,12.417575 158.084967,12.6078778 L159.76564,14.6942975 L159.76564,14.9694297 L155,14.9694297 L155,14.6942975 L156.680672,12.6078778 C156.860392,12.4172595 156.94081,12.1505606 156.897292,11.8894768 L156.897292,4.65196943 Z"
                          transform="translate(-155 -2)"
                        ></path>
                      </svg>
                      &nbsp; Medium
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </Section>
    </Layout>
  );
}

export default TextGenPage;
