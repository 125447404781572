import Web3 from 'web3';

import { SupportedChainId } from '../constants/chains';
import { RPC_URLS } from '../constants/networks';

const web3 = new Web3(RPC_URLS[SupportedChainId.MAINNET][0]);

// Object with the smart contracts for the pairs
const pairs = {
  "ETH / USD": "0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419",
};

// aggregatorV3Interface ABI
const aggregatorV3InterfaceABI = [
  {
    inputs: [],
    name: "decimals",
    outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "description",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint80", name: "_roundId", type: "uint80" }],
    name: "getRoundData",
    outputs: [
      { internalType: "uint80", name: "roundId", type: "uint80" },
      { internalType: "int256", name: "answer", type: "int256" },
      { internalType: "uint256", name: "startedAt", type: "uint256" },
      { internalType: "uint256", name: "updatedAt", type: "uint256" },
      { internalType: "uint80", name: "answeredInRound", type: "uint80" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "latestRoundData",
    outputs: [
      { internalType: "uint80", name: "roundId", type: "uint80" },
      { internalType: "int256", name: "answer", type: "int256" },
      { internalType: "uint256", name: "startedAt", type: "uint256" },
      { internalType: "uint256", name: "updatedAt", type: "uint256" },
      { internalType: "uint80", name: "answeredInRound", type: "uint80" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "version",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
];

export const fetchEthPrice = async () => {
  try {
    // Smart contract instance for ETH/USD pair
    const priceFeed = new web3.eth.Contract(aggregatorV3InterfaceABI, pairs["ETH / USD"]);

    // Use eth_call to get the latest round data
    const roundData = await priceFeed.methods.latestRoundData().call();
    // Chainlink returns price data with 8 decimal places for accuracy.
    // Divide by 1e8 to convert it to a human-readable format.
    const price = Number(roundData.answer) / 1e8;

    //console.log("ETH/USD Price:", price.toFixed(2));
    return price.toFixed(2);
  } catch (error) {
    //console.error("Error fetching ETH price:", error);
    return null;
  }
}